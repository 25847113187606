import {
  ITEM_API_FAIL,
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  ADD_ITEM,
  ADD_ITEM_FAIL,
  ADD_ITEM_SUCCESS,
  GET_ITEM,
  GET_ITEM_FAIL,
  GET_ITEM_SUCCESS,
  PUT_ITEM,
  PUT_ITEM_FAIL,
  PUT_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  PUT_ITEMS_STATUS,
  PUT_ITEMS_STATUS_FAIL,
  PUT_ITEMS_STATUS_SUCCESS,
  GET_NFTS_METADATA_REQUEST,
  GET_NFTS_METADATA_SUCCESS,
  GET_NFTS_METADATA_FAIL,
  GET_NFT_METADATA_REQUEST,
  GET_NFT_METADATA_SUCCESS,
  GET_NFT_METADATA_FAIL,
  REFRESH_NFTS_METADATA_REQUEST,
  REFRESH_NFTS_METADATA_SUCCESS,
  REFRESH_NFTS_METADATA_FAIL,
  UPDATE_NFT_METADATA_REQUEST,
  UPDATE_NFT_METADATA_SUCCESS,
  UPDATE_NFT_METADATA_FAIL,
  UPDATE_NFT_IMAGE_REQUEST,
  UPDATE_NFT_IMAGE_SUCCESS,
  UPDATE_NFT_IMAGE_FAIL,
  NFTS_RRC_TO_MAINBUCKET_REQUEST,
  NFTS_RRC_TO_MAINBUCKET_SUCCESS,
  NFTS_RRC_TO_MAINBUCKET_FAIL,
  POST_MINT_ACTIVITY_SUCCESS,
  POST_MINT_ACTIVITY_FAIL,
  POST_MINT_ACTIVITY,
  UPLOAD_METADATA_UPDATE_REQUEST,
  UPLOAD_METADATA_UPDATE_FAIL,
  UPLOAD_METADATA_UPDATE_SUCCESS,
  REVEAL_NFTS_REQUEST,
  REVEAL_NFTS_FAIL,
  REVEAL_NFTS_SUCCESS,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: ITEM_API_FAIL,
  payload: error,
});

/* Get items */
export const getItems = (data) => ({
  type: GET_ITEMS,
  payload: data,
});

export const getItemsSuccess = (items) => ({
  type: GET_ITEMS_SUCCESS,
  payload: items,
});

export const getItemsFail = (error) => ({
  type: GET_ITEMS_FAIL,
  payload: error,
});
/* Get Items END */

/* Add Item */
export const addItem = (item, history) => ({
  type: ADD_ITEM,
  payload: { item, history },
});

export const addItemFail = (error) => ({
  type: ADD_ITEM_FAIL,
  payload: error,
});

export const addItemSuccess = () => ({
  type: ADD_ITEM_SUCCESS,
});
/* Add Item END */

/* Get Item */
export const getItem = (id) => ({
  type: GET_ITEM,
  payload: id,
});

export const getItemFail = (error) => ({
  type: GET_ITEM_FAIL,
  payload: error,
});

export const getItemSuccess = (item) => ({
  type: GET_ITEM_SUCCESS,
  payload: item,
});
/* Get Item END */

/* Update Item */
export const putItem = (data, history) => ({
  type: PUT_ITEM,
  payload: { data, history },
});

export const putItemFail = (error) => ({
  type: PUT_ITEM_FAIL,
  payload: error,
});

export const putItemSuccess = () => ({
  type: PUT_ITEM_SUCCESS,
});
/* Update Item END */

/* Delete Item */
export const deleteItem = (data, callback) => ({
  type: DELETE_ITEM,
  payload: { data, callback },
});

export const deleteItemFail = (error) => ({
  type: DELETE_ITEM_FAIL,
  payload: error,
});

export const deleteItemSuccess = () => ({
  type: DELETE_ITEM_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Items Status */
export const putItemsStatus = (data, callback) => ({
  type: PUT_ITEMS_STATUS,
  payload: { data, callback },
});

export const putItemsStatusFail = (error) => ({
  type: PUT_ITEMS_STATUS_FAIL,
  payload: error,
});

export const putItemsStatusSuccess = () => ({
  type: PUT_ITEMS_STATUS_SUCCESS,
});
/* Update Multi Items Status END */

/* Get nfts metatdata */
export const getNftsMetadataRequest = (data, callback) => ({
  type: GET_NFTS_METADATA_REQUEST,
  payload: { data, callback },
});

export const getNftsMetadataSuccess = (data) => ({
  type: GET_NFTS_METADATA_SUCCESS,
  payload: data,
});

export const getNftsMetadataFail = (error) => ({
  type: GET_NFTS_METADATA_FAIL,
  payload: error,
});

export const getNftMetadataRequest = (data, callback) => ({
  type: GET_NFT_METADATA_REQUEST,
  payload: { data, callback },
});

export const getNftMetadataSuccess = (data) => ({
  type: GET_NFT_METADATA_SUCCESS,
  payload: data,
});

export const getNftMetadataFail = (error) => ({
  type: GET_NFT_METADATA_FAIL,
  payload: error,
});

export const refreshNftsMetadataRequest = (data, callback) => ({
  type: REFRESH_NFTS_METADATA_REQUEST,
  payload: { data, callback },
});

export const refreshNftsMetadataSuccess = (data) => ({
  type: REFRESH_NFTS_METADATA_SUCCESS,
  payload: data,
});

export const refreshNftsMetadataFail = (error) => ({
  type: REFRESH_NFTS_METADATA_FAIL,
  payload: error,
});

export const updateNftMetadataRequest = (data, callback) => ({
  type: UPDATE_NFT_METADATA_REQUEST,
  payload: { data, callback },
});

export const updateNftMetadataSuccess = (data) => ({
  type: UPDATE_NFT_METADATA_SUCCESS,
  payload: data,
});

export const updateNftMetadataFail = (error) => ({
  type: UPDATE_NFT_METADATA_FAIL,
  payload: error,
});

export const updateNftImageRequest = (data, callback) => ({
  type: UPDATE_NFT_IMAGE_REQUEST,
  payload: { data, callback },
});

export const updateNftImageSuccess = (data) => ({
  type: UPDATE_NFT_IMAGE_SUCCESS,
  payload: data,
});

export const updateNftImageFail = (error) => ({
  type: UPDATE_NFT_IMAGE_FAIL,
  payload: error,
});

//nft rrc to main bucket

export const postNftRrcToMainBucketRequest = (data, callback) => ({
  type: NFTS_RRC_TO_MAINBUCKET_REQUEST,
  payload: { data, callback },
});

export const postNftRrcToMainBucketSuccess = (data) => ({
  type: NFTS_RRC_TO_MAINBUCKET_SUCCESS,
  payload: data,
});

export const postNftRrcToMainBucketFail = (data) => ({
  type: NFTS_RRC_TO_MAINBUCKET_FAIL,
  payload: data,
});

/* Post Mint Activity */
export const postMintActivity = (data, callback) => ({
  type: POST_MINT_ACTIVITY,
  payload: { data, callback },
});

export const postMintActivityFail = (error) => ({
  type: POST_MINT_ACTIVITY_FAIL,
  payload: error,
});

export const postMintActivitySuccess = (data) => ({
  type: POST_MINT_ACTIVITY_SUCCESS,
  payload: data,
});

/* UPLOAD METADATA UPDATE  */
export const uploadMetadataUpdateRequest = (data, callback) => ({
  type: UPLOAD_METADATA_UPDATE_REQUEST,
  payload: { data, callback },
});

export const uploadMetadataUpdateFail = (error) => ({
  type: UPLOAD_METADATA_UPDATE_FAIL,
  payload: error,
});

export const uploadMetadataUpdateSuccess = (data) => ({
  type: UPLOAD_METADATA_UPDATE_SUCCESS,
  payload: data,
});

export const revealNftsRequest = (data, callback) => ({
  type: REVEAL_NFTS_REQUEST,
  payload: { data, callback },
});

export const revealNftsSuccess = (data) => ({
  type: REVEAL_NFTS_SUCCESS,
  payload: data,
});

export const revealNftsFail = (error) => ({
  type: REVEAL_NFTS_FAIL,
  payload: error,
});
