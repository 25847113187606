import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import toastr from "toastr";
// Customer Redux States
import {
  GET_CUSTOMERS,
  GET_CUSTOMER,
  WHITELIST_ACCEPT_REJECT,
  WHITELIST_ACCEPT_REJECT_FAIL,
  GET_CONTACT_US_LIST_REQUEST,
} from "./actionTypes";
import {
  getCustomersFail,
  getCustomersSuccess,
  getCustomerFail,
  getCustomerSuccess,
  whitelistAcceptRejectFail,
  whitelistAcceptRejectSuccess,
  getContactUsListFail,
  getContactUsListSuccess,
} from "./actions";

import {
  getCustomers,
  getCustomer,
  whitelistAcceptReject,
  getContactUsListApi,
} from "helpers/backend_helper";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
};

function* fetchCustomers({ payload }) {
  try {
    const response = yield call(getCustomers, payload);

    if (response.status == "failure") {
      return yield put(getCustomersFail(response.message));
    }

    yield put(getCustomersSuccess(response));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

function* fetchCustomer({ payload }) {
  try {
    const response = yield call(getCustomer, payload);

    if (response.status == "failure") {
      return yield put(getCustomerFail(response.message));
    }

    yield put(getCustomerSuccess(response.data));
  } catch (error) {
    yield put(getCustomerFail(error));
  }
}

function* whitelistAcceptRejectSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(whitelistAcceptReject, payload);

    if (response.status == "failure") {
      callback && callback(false, response);

      return yield put(whitelistAcceptRejectFail(response.message));
    }

    callback && callback(true, response);
    yield put(whitelistAcceptRejectSuccess(response.data));
  } catch (error) {
    yield put(whitelistAcceptRejectFail(error));
  }
}


//get contact us 
function* getContactUsListReq({ payload : {data,callback} }) {
  try {
    const response = yield call(getContactUsListApi, data)
    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(getContactUsListFail(response.message))
    }
    yield put(getContactUsListSuccess(response))
    callback && callback(response);
    return response;
  } catch (error) {
    yield put(getContactUsListFail(error))
    toastr.error("Something went wrong!")
  }
}

function* customersSaga() {
  yield takeLatest(GET_CUSTOMERS, fetchCustomers);
  yield takeLatest(GET_CUSTOMER, fetchCustomer);
  yield takeLatest(WHITELIST_ACCEPT_REJECT, whitelistAcceptRejectSaga);
  yield takeLatest(GET_CONTACT_US_LIST_REQUEST, getContactUsListReq)
}

export default customersSaga;
