import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";

import logo_placeholder from "assets/images/logo-placeholder.png";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import { Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import Instructions from "./Instructions";
import { uploadFile as onUploadFile } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
function useOutside(callback, ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
const HomePageSettings = (props) => {
  const { fields, handleChange } = props;

  const [showBannerColorPicker2, setBannerColorPicker2] = useState(false);
  const [showColorPicker2, setColorPicker2] = useState(false);
  const dispatch = useDispatch();
  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

  useEffect(() => {
    if (
      isObject(fields?.isBackgroundImage) &&
      !!fields?.isBackgroundImage?.link
    ) {
      setlogoselectedFiles([{ preview: fields?.isBackgroundImage?.link }]);
    }
  }, [JSON.stringify(fields?.isBackgroundImage)]);

  const wrapperRef2 = useRef(null);
  const banner_wrapperRef2 = useRef(null);

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;

    handleChange(name)({ target: { value: _id } });
  };
  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);

    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }

    dispatch(onUploadFile({ image: files[0] }, uploadFileSuccess(name)));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    if (name === "isBackgroundImage") {
      setlogoselectedFiles(files);
    }
  };

  useOutside(() => {
    setColorPicker2(false);
  }, wrapperRef2);

  useOutside(() => {
    setBannerColorPicker2(false);
  }, banner_wrapperRef2);

  return (
    <React.Fragment>
      <Row>
        <Col lg={3}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={9}>
          <Row>
            <Col sm={6} md={4} lg={6} xl={4}>
              <div ref={wrapperRef2} className="form-group m-b-0">
                <Label className="text-truncate">{"Primary color"}</Label>

                <div
                  className="input-group colorpicker-default mb-1"
                  title="Using format option"
                >
                  <Input
                    type="text"
                    className="colorpicker-rgba form-control"
                    value={fields?.subprimaryColor}
                    onClick={() => {
                      setColorPicker2(!showColorPicker2);
                    }}
                    onChange={handleChange("subprimaryColor")}
                  />

                  <span className="input-group-append">
                    <span
                      className="input-group-text colorpicker-input-addon"
                      onClick={() => {
                        setColorPicker2(!showColorPicker2);
                      }}
                    >
                      <i
                        style={{
                          height: "16px",
                          width: "16px",
                          background: fields?.subprimaryColor,
                        }}
                      />
                    </span>
                  </span>
                </div>

                {showColorPicker2 ? (
                  <ColorPicker
                    saturationHeight={100}
                    saturationWidth={120}
                    value={fields?.subprimaryColor}
                    onDrag={(color) => {
                      handleChange("subprimaryColor")({
                        target: { value: color },
                      });
                    }}
                  />
                ) : null}
              </div>
            </Col>
            <Col sm={6} md={4} lg={6} xl={4}>
              <div ref={banner_wrapperRef2} className="form-group m-b-0">
                <Label className="text-truncate">{"Secondary color"}</Label>

                <div
                  className="input-group colorpicker-default mb-1"
                  title="Using format option"
                >
                  <Input
                    type="text"
                    className="colorpicker-rgba form-control"
                    value={fields?.subsecondaryColor}
                    onClick={() => {
                      setBannerColorPicker2(!showBannerColorPicker2);
                    }}
                    onChange={handleChange("subsecondaryColor")}
                  />

                  <span className="input-group-append">
                    <span
                      className="input-group-text colorpicker-input-addon"
                      onClick={() => {
                        setBannerColorPicker2(!showBannerColorPicker2);
                      }}
                    >
                      <i
                        style={{
                          height: "16px",
                          width: "16px",
                          background: fields?.subsecondaryColor,
                        }}
                      />
                    </span>
                  </span>
                </div>

                {showBannerColorPicker2 ? (
                  <ColorPicker
                    saturationHeight={100}
                    saturationWidth={120}
                    value={fields?.subsecondaryColor}
                    onDrag={(color) => {
                      handleChange("subsecondaryColor")({
                        target: { value: color },
                      });
                    }}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

HomePageSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePageSettings)
);
