import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";

import { Row, Col, FormGroup,Form, Label, Input,Card,CardBody,Container, CardHeader,Button, Spinner, Badge } from "reactstrap";
import Web3InteractionEth, { convertFromWei, convertHexToNumber } from "util/web3InteractionEth";

// Actions
import { getCollection,getSettings,postNftRrcToMainBucketRequest,postMintActivity } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletHooks } from "util/walletHooks";
import { getNetworkUrl } from "helpers/contants";
import { getNftMetadataAlchemy } from "util/web3Intraction";

const OriginVipAirdrop = ({}) => {
    const wallet = walletHooks();
    const dispatch = useDispatch();
   const {loadingCollection,loadingSettings,settings,activityLoader} = useSelector(({Settings, collections,items})=>({
      loadingSettings : Settings.loading,
      settings: Settings.settings,
      loadingCollection : collections.loading,
      activityLoader : items.loading
   }));

   const [fields,setFields] = useState({
    walletAddress : "",
    vipak47_token_id : ""
   });
  const [dataList,setDataList] = useState([]);

  const [loaders,setLoaders] = useState({
    originAirdrop : false
  });
  const [rroCollection,setRroCollection] = useState(null);
  const [rrwgCollection,setRRWGCollection] = useState(null);


  useEffect(() => {
     dispatch(getSettings());
  }, []);

  useEffect(()=>{
      if(JSON.stringify(settings !== '{}')){
        dispatch(getCollection(settings.activeOriginsClaimCollectionId,(data)=>{
            console.log("origins collection",data)
            setRroCollection(data);
        }));
        dispatch(getCollection(settings.actionWGClaimCollectionId,(data)=>{
          setRRWGCollection(data);
        }));
      }
  },[JSON.stringify(settings)]);


  const onHandleChange = (e) =>{
      const {value,name} = e.target;
     setFields((prev)=>({...prev,[name]:value?.trim()}));
  }

  const onAddWallet = async() =>{
    if(!fields.walletAddress){
     return toastr.error("Wallet address is required!")
    }
    if(!fields.vipak47_token_id){
        return toastr.error("AK47 token id is required!")
       }
       const alreadyAdd = dataList.find((item)=> parseInt(item?.vipak47_token_id) === parseInt(fields.vipak47_token_id));
       console.log("alreadyAdd",alreadyAdd)
       if(alreadyAdd){
        return toastr.error("Already add vip ak47 token");
       }
       const networkUrl = getNetworkUrl("ethereum", settings);
  console.log("networkUrl",networkUrl);
  if(!rrwgCollection?.contractAddress){
   return toastr.error("Weapon contract missing please reload and try again","",{timeOut : 3000});
  }
    const tokenIdMetadata = await getNftMetadataAlchemy({
      contractAddress : rrwgCollection?.contractAddress,
      tokenId : fields.vipak47_token_id,
      networkUrl,
      blockchainNetworkMode : settings.blockchainNetworkMode,
    });
console.log("tokenIdMetadata",tokenIdMetadata);
  if(tokenIdMetadata["rawMetadata"]["nft_type"] !== "claim-vipak47-mint"){  
        return toastr.error("Token id is not ak47","",{timeOut:3000}); 
  }
  if(tokenIdMetadata["owner"]?.toLowerCase() !== fields?.walletAddress?.toLowerCase()){
      return toastr.error(`Enter walletAddress is not owner of tokenId ${fields.vipak47_token_id}`,"",{timeOut:3000});
  }
    let copy = [...dataList];
    copy.push({
      walletAddress : fields.walletAddress?.toLowerCase(),
      vipak47_token_id : fields.vipak47_token_id
    })
      setDataList(copy);
      setFields({
        walletAddress :"",
        vipak47_token_id : ""
      });
  }

  const onRemoveWallet = (index) =>{
     setDataList((wallets)=>wallets.filter((_,idx)=> index !== idx));
  }

  const onClickAirdrop = async() =>{
    try{
      console.log("dataList",dataList)
        if(dataList?.length < 1){
            return  toastr.error("Please add token and wallet address is required!");
        }
        if(!rroCollection?._id){
          return toastr.error("Origin collection not load or found!");
        }
    
        const web3InstanceEth = new Web3InteractionEth(
            rroCollection.blockchain,
            window.ethereum,
            settings
          );
          await web3InstanceEth.switchChain();

          const contract = await web3InstanceEth.getContract(
            rroCollection.contractAbi,
            rroCollection.contractAddress
          );

         if (!contract) {
            // setloading(false);
            return toastr.error("Service not provided!");
          }
        
          const recepients = dataList.map(item=> item.walletAddress);
          const vipTokens = dataList.map(item => item.vipak47_token_id);

          console.log("contract",contract)

    
          const result = await contract.callStatic["vipMultiAirdropNFT"](recepients,vipTokens);

          console.log("Successfully chek : ",result);
    
          setLoaders((prev)=>({...prev,originAirdrop:true}))
          
        
          const transaction = await contract.vipMultiAirdropNFT(recepients,vipTokens);
            const tx = await transaction.wait();
            console.log("tx", tx);
           const  txHash = tx.transactionHash;
          
           setLoaders((prev)=>({...prev,originAirdrop:false}))            
         
         //save item and history
        if(txHash){
          toastr.success("Nft's minted successfully!")

          const payload = await Promise.all(tx.logs.map(async (log,index) => {
            const rawAddress = log.topics[2]; // Assuming there's only one address in the logs
            const mintedTokenId = convertHexToNumber(log.topics[3]);
            console.log("mintedTokenId",mintedTokenId)
            const formattedAddress = '0x' + rawAddress.slice(26);
            console.log("wallet address for transaction to", formattedAddress)
            // let _tokens = await contract
            // .walletOfOwner(formattedAddress);
            //  _tokens =  _tokens?.map(token => token?.toNumber());
            //  const numberTimeWallets = recepients.filter((item)=> item?.toLowerCase() === formattedAddress)?.length;

            //  console.log("v", _tokens);
         //save item and history
             const vipToken = vipTokens[index];
            const recentMintedTokens = [mintedTokenId];
            // const recentMintedTokens = _tokens.slice(-numberTimeWallets);
              return {
                type: rroCollection.blockchain,
                collection_id: rroCollection._id,
                transactionHash: txHash,
                price: rroCollection?.cost || 0,
                quantity: 1,
                walletAddress: formattedAddress,
                mint_type: "claim-origin",
                token_ids: recentMintedTokens,
                isAirDropped : true,
                originClaimedForTokenIds : {
                  "claim-weapon" : [vipToken],
                 }
              }
          }));
          console.log("payload for save activity",payload);
          // const uniqueRecords = payload.reduce((acc, current) => {
          //   const existingRecord = acc.find(item => item.walletAddress.toLowerCase() === current.walletAddress.toLowerCase());
          
          //   if (!existingRecord) {
          //     acc.push(current);
          //   }
          
          //   return acc;
          // }, []);
          // console.log("unique records",uniqueRecords);
          dispatch(
            postMintActivity({
              type : rroCollection.blockchain,
              activities_type :"multiple",
              activities :  payload
            },(res)=>{
                 console.log("response activity",res)
                 toastr.success("Data successfully saved in our db");
                 
                 setDataList([]);
            }));

        const allTokenIds = payload.reduce((accumulator, currentObject) => {
          // Concatenate the current object's token_ids to the accumulator array
          accumulator.push(...currentObject.token_ids);
          return accumulator;
        }, []);
        // Remove duplicates using Set and convert back to array
        const uniqueTokenIds = [...new Set(allTokenIds)];
        // //reveal nfts
        dispatch(postNftRrcToMainBucketRequest({tokens : uniqueTokenIds, type : "claim-origin"},(response)=>{
          console.log("response after tokens move",response);
        }));
        }
    }catch(error){
      console.log("Error",error);
      setLoaders((prev)=>({...prev,originAirdrop:false}))
      toastr.error(error?.message || "Something went wrong!");
    }
    
  }

  return (
 
            <Card>
                <CardHeader className="bg-primary text-white">
                    Robotic Rabbit Origin Vipak47 Users Airdrop 
                </CardHeader>
              <CardBody className="spinner-content">
                   <Form>
                          <Row>
                          <Col md={4}>
                                <FormGroup>
                                    <Label>Vip Token Id</Label>

                                    <Input
                                        type="number"
                                        placeholder="Please enter ak47 token id"
                                        name="vipak47_token_id"
                                        value={fields.vipak47_token_id}
                                        onChange={onHandleChange}
                                    />
                                </FormGroup>
                            </Col>
                           <Col md={4}>
                                <FormGroup>
                                    <Label>Wallet Address</Label>

                                    <Input
                                        type="string"
                                        placeholder="Please enter wallet address"
                                        name="walletAddress"
                                        value={fields.walletAddress}
                                        onChange={onHandleChange}
                                    />
                                </FormGroup>
                            </Col>
                           
                            <Col md={4}>
                                <FormGroup>
                                 <Button className="mt-4" onClick={onAddWallet} type="button" color="primary">
                                    Add
                                 </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col md={4}>
                               <p>Wallet Address</p>
                            </Col>
                            <Col md={1}>
                               <p>TokenId</p>
                            </Col>
                            <Col md={4}>
                               <p>Action</p>
                            </Col>
                            </Row>
                            {dataList.map((item,index)=>(
                            <Row>
                            <Col md={4}>
                                <Badge className="p-1" color="primary">{item.walletAddress}</Badge>
                            </Col>
                            <Col md={1}>
                                <Badge className="p-1" color="primary">{item.vipak47_token_id}</Badge>
                            </Col>
                            <Col md={4}>
                            <FormGroup>
                                 <Button onClick={()=>onRemoveWallet(index)} type="button" color="primary">
                                    Remove
                                 </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                            ))}
                            <Row>
                            <Col md={12}>
                            <FormGroup>
                                <Button disabled={loaders.originAirdrop || activityLoader} onClick={()=>onClickAirdrop()} type="button" color="primary">
                                {(loaders.originAirdrop  || activityLoader) && <Spinner size={"sm"} color="light"/> } Airdrop
                                </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                   </Form>
              </CardBody>
            </Card>
  );
};

export default withRouter(
    OriginVipAirdrop
);
