import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { isEmpty } from "lodash";
import { post } from "../../../helpers/api_helper";
import { STATUS_CHANGE_BY_ADMIN } from "../../../helpers/url_helper";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getCustomer, regainUserPlayChancesRequest } from "store/actions";

const ViewCustomer = (props) => {
  const {
    match: { params },
    onGetCustomer,
    customer,
    error,
    loading,
  } = props;

  const [Status, setStatus] = useState(customer.adminStatus);
  const {loadingRegainLife} = useSelector(({Settings}) => Settings);
  const dispatch = useDispatch();

  const  onClickRegainUsersLife = () =>{
    dispatch(regainUserPlayChancesRequest({userId : customer?._id}));
  }

  useEffect(() => {
    if (params && params.id) {
      onGetCustomer(params.id);
    }
  }, [params]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    let statusChange = "";
    if (value && value === "requested") {
      statusChange = "active";
    } else if (value && value === "active") {
      statusChange = "block";
    } else if (value && value === "inactive") {
      statusChange = "active";
    }
    post(STATUS_CHANGE_BY_ADMIN, { status: statusChange, userId: params.id })
      .then((res) => {
        setStatus(statusChange);
      })
      .catch((err) => {});
  };


  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}

      {error && <Redirect to="/users" />}
      {!error && !isEmpty(customer) && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Vendors" breadcrumbItem="Vendor Details" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <div className="user-details">
                            <h4 className="userdetails-name">Name: </h4>
                            <h5 className="userdetails-value">
                              {customer.name}
                            </h5>
                          </div>

                          <div className="user-details">
                            <h4 className="userdetails-name">Email: </h4>
                            <h5 className="userdetails-value">
                              {customer.email}
                            </h5>
                          </div>

                          <div className="user-details">
                            <h4 className="userdetails-name">User Name: </h4>
                            <h5 className="userdetails-value">
                              {customer.username}
                            </h5>
                          </div>

                          <div className="user-details">
                            <h4 className="userdetails-name">
                              Wallet Address:
                            </h4>
                            <h5 className="userdetails-value">
                              {customer.walletAddress}
                            </h5>
                          </div>

                        
                          <div className="user-details">
                            <div className="text-muted float-left mr-3 mb-3">
                              <p>Average Rating - {customer.avgRating}</p>
                              {/* <StarRatings
                              rating={customer.avgRating}
                              starRatedColor="#F1B44C"
                              starEmptyColor="#2D363F"
                              numberOfStars={5}
                              name="rating"
                              starDimension="14px"
                              starSpacing="3px"
                            /> */}
                            </div>
                          </div>

                          <div className="user-details">
                            <h6 className="text-success text-uppercase">
                              {customer.reviewCount} Total Reviews
                            </h6>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  
                    {customer.reviewCount > 0 && (
                      <div className="mt-5">
                        <h5 className="mb-4">Reviews :</h5>
                        {customer.reviews.map((comments) => (
                          <p className="text-muted">{comments}</p>
                        ))}
                      </div>
                    )}
                    {customer.adminStatus &&
                      customer.adminStatus != "inactive" && (
                        <div className="status-switch square-switch">
                          <input
                            className="switcher"
                            type="checkbox"
                            id="blockchain-network-mode"
                            switch="none"
                            checked={Status === "active"}
                            onChange={() => {
                              handleChange("AdminStatusChanges")({
                                target: {
                                  value:
                                    Status === "active" ? "active" : "inactive",
                                },
                              });
                            }}
                          />
                          <label
                            htmlFor="blockchain-network-mode"
                            data-on-label="active"
                            data-off-label="inactive"
                          />
                        </div>
                      )}
                       
                       <div className="user-details">
                            <h5>
                              <Button color="primary" onClick={()=>onClickRegainUsersLife()}> 
                                {loadingRegainLife && <Spinner size={"sm"} color="primary" className="mr-2 me-2"/>}
                                    Regain Users Life
                              </Button>
                            </h5>
                          </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

ViewCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  customer: PropTypes.any,
  onGetCustomer: PropTypes.func,
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCustomer: (id) => dispatch(getCustomer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
