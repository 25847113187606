import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionType";
import * as ACTION from "./actions";
import * as API from "helpers/backend_helper";
import toastr from "toastr";

function* onPostTeam({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postTeam, data);
    if (response == "failure") {
      toastr.error(response.message)
      return yield put(ACTION.postTeamFail(response.message));
    }
    callback && callback(response);

    yield put(ACTION.postTeamSuccess(response));
    toastr.success("Team addedd successfully!")
  } catch (error) {
    console.log(error, "AddTeamError");
    toastr.error("Something went wrong!")
    yield put(ACTION.postTeamFail(error));
  }
}

function* fetchTeamsList({ payload }) {
  try {
    const response = yield call(API.getTeams, payload);
    if (response.status == "failure") {
      return yield put(ACTION.getTeamsFail(response.message));
    }
    yield put(ACTION.getTeamsSuccess(response));
  } catch (error) {
    yield put(ACTION.getTeamsFail(error));
  }
}

function* getTeam({ payload }) {
  try {
    const response = yield call(API.getTeam, payload);
    if (response.status == "failure") {
      return yield put(ACTION.getTeamFail(response.message));
    }
    yield put(ACTION.getTeamSuccess(response.data));
  } catch (error) {
    yield put(ACTION.getTeamFail(error));
  }
}

function* putTeam({ payload: { data, callback } }) {
  try {
    const response = yield call(API.putTeam, data);
    if (response == "failure") {
      toastr.error(response.message)

      return yield put(ACTION.putTeamFail(response.message));
    }
    callback && callback(response);

    yield put(ACTION.putTeamSuccess(response));
    toastr.success("Team updated successfully!")

  } catch (error) {
    console.log(error, "UpdateTeamError");
    toastr.error("Something went wrong!")

    yield put(ACTION.putTeamFail(error));
  }
}

function* deleteTeam({ payload: { data, callback } }) {
  try {
    const response = yield call(API.deleteTeam, data);
    if (response == "failure") {
      toastr.error(response.message)

      return yield put(ACTION.deleteTeamFail(response.message));
    }
    
    callback && callback(response);

    yield put(ACTION.deleteTeamSuccess(response));
    toastr.success("Team deleted successfully!")

  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(ACTION.deleteTeamFail(error));
    console.log(error, "TeamDelete");
  }
}

function* Saga() {
  yield takeEvery(TYPES.POST_TEAM, onPostTeam);
  yield takeLatest(TYPES.GET_TEAMS, fetchTeamsList);
  yield takeLatest(TYPES.GET_TEAM, getTeam);
  yield takeEvery(TYPES.PUT_TEAM, putTeam);
  yield takeEvery(TYPES.DELETE_TEAM, deleteTeam);
}

export default Saga;
