import React, { useEffect } from "react";

import { Card, CardBody, CardTitle } from "reactstrap";
import { Col, Container, Media, Row } from "reactstrap";

//Import Breadcrumb
// Components
import Breadcrumbs from "components/Common/Breadcrumb";
import CardWelcome from "./card-welcome";

import { useDispatch, useSelector } from "react-redux";

// Store
import { getDashboard } from "store/actions";

const Dashboard = () => {
  const reports = [
    {
      title: "Total Uers",
      iconClass: "bx-copy-alt",
      description: 0,
    },
    {
      title: "Minted NFT's",
      iconClass: "bx-archive-in",
      description: 0,
    },
    {
      title: "Amount Earned",
      iconClass: "bx-archive-in",
      description: 0,
    },
  ];

  const { dashboard } = useSelector((state) => state?.Settings);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={"dashboards"} />
          {/* style={{ paddingLeft: 10, paddingBottom: 1 }} */}
          <Row >
            <Col xl="6" className="px-4">
              <CardWelcome />
            </Col>
          </Row>

          {/* <Row>
            <Col xl={6} xs={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">A</CardTitle>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} xs={12}>
              <Row>
                <Col md={12} xs={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>B</CardTitle>
                      JHFGJHJHG
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
