import { call, put, takeEvery } from "redux-saga/effects";

// Category Redux States
import * as CONST from "./actionTypes";
import * as actType from "./actions";

import {
  getGalerys,
  postGallery,
  getGallery,
  putGallery,
  deleteGallery,
} from "helpers/backend_helper";

function* fetchCategories({ payload }) {
  try {
    const response = yield call(getGalerys, payload);
    if (response.status == "failure") {
      return yield put(actType?.getgallerysFail(response.message));
    }

    yield put(actType?.getgallerysSuccess(response));
  } catch (error) {
    yield put(actType?.getgallerysFail(error));
  }
}

function* onAddNewCategory({ payload: { item, history } }) {
  try {
    const response = yield call(postGallery, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(actType?.addgalleryFail(response.message));
    }
    history && history.push("/gallery");
    yield put(actType?.addgallerySuccess());
  } catch (error) {
    yield put(actType?.addgalleryFail("Internal Error!"));
  }
}

function* fetchCategory({ payload }) {
  console.log(payload, 5645454564);
  try {
    const response = yield call(getGallery, payload);

    if (response.status == "failure") {
      return yield put(actType?.getgalleryFail(response.message));
    }
    yield put(actType?.getgallerySuccess(response.data));
  } catch (error) {
    yield put(actType?.getgalleryFail(error));
  }
}

function* onPutCategory({ payload: { data, history } }) {
  try {
    const response = yield call(putGallery, data);
    if (response.status == "failure") {
      return yield put(actType?.putgalleryFail(response.message));
    }
    history && history.push("/gallery");

    yield put(actType?.putgallerySuccess());
  } catch (error) {
    yield put(actType?.putgalleryFail("Internal Error!"));
  }
}

function* onDeleteCategory({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteGallery, data);

    if (response.status == "failure") {
      return yield put(actType?.deletegallery(response.message));
    }
    yield put(actType?.deletegallerySuccess());
    callback && callback();
  } catch (error) {
    yield put(actType?.deletegallery("Internal Error!"));
  }
}

function* gallerySaga() {
  yield takeEvery(CONST.GET_GALLERYS, fetchCategories);
  yield takeEvery(CONST.ADD_GALLERY, onAddNewCategory);
  yield takeEvery(CONST.GET_GALLERY, fetchCategory);
  yield takeEvery(CONST.PUT_GALLERY, onPutCategory);
  yield takeEvery(CONST.DELETE_GALLERY, onDeleteCategory);
}

export default gallerySaga;
