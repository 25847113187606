export const CATEGORY_API_FAIL = "CATEGORY_API_FAIL"

// CATEGORYs
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"

// Add CATEGORY
export const ADD_CATEGORY = "ADD_CATEGORY"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"

// Get CATEGORY
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"

// update CATEGORY
export const PUT_CATEGORY = "PUT_CATEGORY"
export const PUT_CATEGORY_FAIL = "PUT_CATEGORY_FAIL"
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS"

// Delete CATEGORY
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"

// Update Multi CATEGORY Status
export const PUT_CATEGORIES_STATUS = "PUT_CATEGORIES_STATUS"
export const PUT_CATEGORIES_STATUS_FAIL = "PUT_CATEGORIES_STATUS_FAIL"
export const PUT_CATEGORIES_STATUS_SUCCESS =
  "PUT_CATEGORIES_STATUS_SUCCESS"
