import { call, put, takeEvery } from "redux-saga/effects"

// CONTENT Redux States
import {
  GET_CONTENTS,
  ADD_CONTENT,
  GET_CONTENT,
  PUT_CONTENT,
  DELETE_CONTENT,
  PUT_CONTENTS_STATUS,
} from "./actionTypes"
import {
  getCONTENTsFail,
  getCONTENTsSuccess,
  addCONTENTFail,
  addCONTENTSuccess,
  getCONTENTFail,
  getCONTENTSuccess,
  putCONTENTFail,
  putCONTENTSuccess,
  deleteCONTENTFail,
  deleteCONTENTSuccess,
  putCONTENTsStatusFail,
  putCONTENTsStatusSuccess,
} from "./actions"

import {
  getCONTENTs,
  postCONTENT,
  getCONTENT,
  putCONTENT,
  deleteCONTENT,
  putCONTENTsStatus,
} from "helpers/backend_helper"

function* fetchCONTENTs({ payload }) {
  try {
    const response = yield call(getCONTENTs, payload)

    if (response.status == "failure") {
      console.log("jsfdkkjdsf", response)
      return yield put(getCONTENTsFail(response.message))
    }
    console.log("kljfdsjjdfs", response)

    yield put(getCONTENTsSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getCONTENTsFail(error))
  }
}

function* onAddNewCONTENT({ payload: { CONTENT, history } }) {
  try {
    const response = yield call(postCONTENT, CONTENT)

    if (response.status == "failure") {
      window.scrollTo(0, 0)

      return yield put(addCONTENTFail(response.message))
    }

    history && history.replace("/CONTENTs")

    yield put(addCONTENTSuccess())
  } catch (error) {
    yield put(addCONTENTFail("Internal Error!"))
  }
}

function* fetchCONTENT({ payload }) {
  try {
    const response = yield call(getCONTENT, payload)

    if (response.status == "failure") {
      return yield put(getCONTENTFail(response.message))
    }

    yield put(getCONTENTSuccess(response.data))
  } catch (error) {
    yield put(getCONTENTFail(error))
  }
}

function* onPutCONTENT({ payload: { data, history } }) {
  try {
    const response = yield call(putCONTENT, data)

    if (response.status == "failure") {
      return yield put(putCONTENTFail(response.message))
    }

    history && history.replace("/CONTENTs")

    yield put(putCONTENTSuccess())
  } catch (error) {
    yield put(putCONTENTFail("Internal Error!"))
  }
}

function* onDeleteCONTENT({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCONTENT, data)

    if (response.status == "failure") {
      return yield put(deleteCONTENTFail(response.message))
    }

    yield put(deleteCONTENTSuccess())
    callback && callback()
  } catch (error) {
    yield put(deleteCONTENTFail("Internal Error!"))
  }
}

function* onPutCONTENTsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putCONTENTsStatus, data)

    if (response.status == "failure") {
      return yield put(putCONTENTsStatusFail(response.message))
    }

    yield put(putCONTENTsStatusSuccess())
    callback && callback()
  } catch (error) {
    yield put(putCONTENTsStatusFail("Internal Error!"))
  }
}

function* CONTENTsSaga() {
  yield takeEvery(GET_CONTENTS, fetchCONTENTs)
  yield takeEvery(ADD_CONTENT, onAddNewCONTENT)
  yield takeEvery(GET_CONTENT, fetchCONTENT)
  yield takeEvery(PUT_CONTENT, onPutCONTENT)
  yield takeEvery(DELETE_CONTENT, onDeleteCONTENT)
  yield takeEvery(PUT_CONTENTS_STATUS, onPutCONTENTsStatus)
}

export default CONTENTsSaga
