import { SHOW_ALERT, HIDE_ALERT, RELOAD_ON_DEMAND } from "./actionTypes"

export const showAlert = () => ({
  type: SHOW_ALERT,
})

export const hideAlert = () => ({
  type: HIDE_ALERT,
})

export const reloadOnDemand = () => ({
  type: RELOAD_ON_DEMAND,
})
