import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Select from "react-select";

import { Row, Col, FormGroup, Label, Input, Spinner, Button } from "reactstrap";
import Instructions from "./Instructions";
import { useDispatch, useSelector } from "react-redux";
import { regainUserPlayChancesRequest } from "store/actions";

const playSessions = [
  { label: "Whitelist", value: "whitelist" },
  { label: "Public", value: "public" },
];

const GameplaySettings = (props) => {
  const { fields, handleChange } = props;
  const { loadingRegainLife } = useSelector(({ Settings }) => Settings);
  const dispatch = useDispatch();

  const onClickRegainUsersLife = () => {
    dispatch(regainUserPlayChancesRequest());
  };

  console.log("seetinf game", fields);
  return (
    <React.Fragment>
      <Row>
        <Col lg={3}>
          <Instructions />
        </Col>

        <Col lg={9}>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <FormGroup>
                <Label>Play session</Label>
                <Select
                  name="play_session"
                  options={playSessions}
                  value={playSessions.find(
                    (item) => item.value === fields?.play_session
                  )}
                  onChange={(e) =>
                    handleChange("play_session")({
                      target: { value: e.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <FormGroup>
                <Label>Whitelist Mint Price</Label>
                <Input
                  name="whitelist_mint_price"
                  value={fields?.whitelist_mint_price}
                  onChange={(e) =>
                    handleChange("whitelist_mint_price")({
                      target: { value: e.target.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <FormGroup>
                <Label>Public Mint Price</Label>
                <Input
                  name="public_mint_price"
                  value={fields?.public_mint_price}
                  onChange={(e) =>
                    handleChange("public_mint_price")({
                      target: { value: e.target.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <FormGroup>
                <Label>Direct Home Mint Price</Label>
                <Input
                  name="direct_mint_price"
                  value={fields?.direct_mint_price}
                  onChange={(e) =>
                    handleChange("direct_mint_price")({
                      target: { value: e.target.value },
                    })
                  }
                />
              </FormGroup>
            </Col> */}
            <Col lg={6} md={6} sm={12} xs={12}>
              <FormGroup>
                <Label className="text-capitalize">Reveal Play to mint page</Label>

                <div className="status-switch square-switch">
                  <input
                    className="switcher"
                    type="checkbox"
                    id={`play-status`}
                    switch="none"
                    checked={fields.reveal_play_to_mint}
                    onChange={(e) =>
                      handleChange("reveal_play_to_mint")({
                        target: { value: e.target.checked },
                      })
                    }
                  />
                  <label
                    htmlFor={`play-status`}
                    data-on-label="True"
                    data-off-label="False"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <FormGroup>
                <Button
                  color="primary"
                  onClick={() => onClickRegainUsersLife()}
                >
                  {loadingRegainLife && (
                    <Spinner
                      size={"sm"}
                      color="primary"
                      className="mr-2 me-2"
                    />
                  )}
                  Regain Users Life
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GameplaySettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

export default withRouter(GameplaySettings);
