import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getCollections } from "store/actions";

const Collections = ({
  loading,
  collections,
  onGetCollections,
  parent,
  setparent,
}) => {
  const searching = useRef(null);

  const [collectionsList, setCollectionsList] = useState([]);
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 50,
    search: "",
    fields: [
      {
        fieldName: "status",
        fieldValue: "active",
      },
    ],
  });

  useEffect(() => {
    onGetCollections(filter);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    setCollectionsList((prevState) => [
      ...collections.map(({ _id, name }) => ({
        label: name,
        value: _id,
      })),
    ]);
  }, [JSON.stringify(collections)]);

  return (
    <Select
      value={parent}
      options={collectionsList}
      classNamePrefix="parent-selection"
      menuPosition="fixed"
      isLoading={loading}
      onChange={setparent}
      onInputChange={(text) => {
        if (searching.current) {
          clearTimeout(searching.current);
        }

        searching.current = setTimeout(() => {
          setFilter((prevFilter) => ({
            ...prevFilter,
            search: text,
          }));

          searching.current = null;
          clearTimeout(searching.current);
        }, 1000);
      }}
    />
  );
};

Collections.propTypes = {
  loading: PropTypes.bool,
  collections: PropTypes.array,
  onGetCollections: PropTypes.func,
};

const mapStateToProps = ({ collections }) => ({
  loading: collections.loading,
  collections: collections.collections,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCollections: (data) => dispatch(getCollections(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Collections)
);
