import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";

import {
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  CardHeader,
  Button,
  Spinner,
} from "reactstrap";
import Web3InteractionEth, { convertFromWei } from "util/web3InteractionEth";

// Actions
import {
  getCollection,
  getSettings,
  postNftRrcToMainBucketRequest,
  postMintActivity,
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletHooks } from "util/walletHooks";

const SyndicateMint = ({}) => {
  const wallet = walletHooks();
  const dispatch = useDispatch();
  const { loadingCollection, loadingSettings, settings, activityLoader } =
    useSelector(({ Settings, collections, items }) => ({
      loadingSettings: Settings.loading,
      settings: Settings.settings,
      loadingCollection: collections.loading,
      activityLoader: items.loading,
    }));

  const [fields, setFields] = useState({
    walletAddress: "",
    counter: 0,
  });

  const [loaders, setLoaders] = useState({
    syndicateMint: false,
    originAirdrop: false,
  });
  const [rrsCollection, setRrsCollection] = useState(null);

  useEffect(() => {
    if (wallet.account) {
      setFields((prev) => ({ ...prev, walletAddress: wallet.account }));
    }
  }, [wallet.account]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (JSON.stringify(settings !== "{}")) {
      dispatch(
        getCollection(settings.activeCollectionId, (data) => {
          console.log("syndicate collection", data);
          setRrsCollection(data);
        })
      );
    }
  }, [JSON.stringify(settings)]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value?.trim() }));
  };

  const onClickMint = async () => {
    try {
      const { walletAddress, counter } = fields;
      if (counter < 1) {
        return toastr.error("Please enter nfts count for mint!");
      }
      if (!rrsCollection?._id) {
        return toastr.error("collection not load or found!");
      }
      if (!walletAddress) {
        return toastr.error("Wallet addresss is missing!");
      }

      const web3InstanceEth = new Web3InteractionEth(
        rrsCollection.blockchain,
        window.ethereum,
        settings
      );
      await web3InstanceEth.switchChain();
      const contract = await web3InstanceEth.getContract(
        rrsCollection.contractAbi,
        rrsCollection.contractAddress
      );

      if (!contract) {
        // setloading(false);
        return toastr.error("Service not provided!");
      }

      console.log("contract", contract);

      const cost = await contract.cost();
      console.log("cost", cost);
      const value = convertFromWei(cost?.toString()) * counter;
      console.log("value", value);

      const valueEth = web3InstanceEth.convertPriceToEther(value);
      console.log(valueEth, "newvalue ");

      const result = await contract.callStatic["ownerMint"](counter, {
        gasLimit: 3000000,
      });
      console.log("Successfully check : ", result);

      setLoaders((prev) => ({ ...prev, syndicateMint: true }));

      const transaction = await contract.ownerMint(counter, {
        gasLimit: 3000000,
      });
      const tx = await transaction.wait();
      console.log("tx", tx);
      const txHash = tx.transactionHash;

      setLoaders((prev) => ({ ...prev, syndicateMint: false }));

      let _tokens = await contract.walletOfOwner(fields?.walletAddress);
      _tokens = _tokens?.map((token) => token?.toNumber());

      console.log("v", _tokens);
      //save item and history
      if (_tokens?.length && txHash) {
        const recentMintedTokens = _tokens.slice(-counter);
        console.log("recentMintedTokens", recentMintedTokens);
        toastr.success("Nft minted successfully!");
        setFields({ counter: 0 });
        dispatch(
          postMintActivity(
            {
              type: rrsCollection.blockchain,
              collection_id: rrsCollection._id,
              transactionHash: txHash,
              price: value,
              quantity: counter,
              walletAddress: fields?.walletAddress,
              mint_type: "og-mint",
              token_ids: recentMintedTokens,
            },
            () => {
              // setloading(false);
            }
          )
        );
        //reveal nfts
        dispatch(
          postNftRrcToMainBucketRequest(
            { tokens: recentMintedTokens },
            (response) => {
              console.log("response after tokens move", response);
            }
          )
        );
      }
    } catch (error) {
      console.log("Error", error);
      setLoaders((prev) => ({ ...prev, syndicateMint: false }));
      toastr.error(error?.message || "Something went wrong!");
    }
  };

  return (
    <Card>
      <CardHeader className="bg-primary text-white">
        Robotic Rabbit Syndicate
      </CardHeader>
      <CardBody className="spinner-content">
        <Form>
          <FormGroup>
            <Col md={6}>
              <FormGroup>
                <Label>Number of Nfts</Label>

                <Input
                  type="number"
                  placeholder="Please enter number of nfts"
                  name="counter"
                  value={fields.counter}
                  onChange={onHandleChange}
                />
              </FormGroup>
            </Col>
            {/* <Col md={6}>
              <FormGroup>
                <Label>wallet address</Label>

                <Input
                  type="text"
                  disabled={true}
                  placeholder="please enter wallet address"
                  name="walletAddress"
                  value={fields.walletAddress}
                  onChange={onHandleChange}
                />
              </FormGroup>
            </Col> */}
            <Col md={6}>
              <FormGroup>
                <Button
                  disabled={loaders.syndicateMint}
                  onClick={onClickMint}
                  type="button"
                  color="primary"
                >
                  {loaders.syndicateMint && (
                    <Spinner size={"sm"} color="light" />
                  )}{" "}
                  Mint
                </Button>
              </FormGroup>
            </Col>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default withRouter(SyndicateMint);
