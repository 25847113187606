import PropTypes from "prop-types";
import React from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../../../store/actions";

const SidebarContent = (props) => {
  const dispatch = useDispatch();
  const { layoutType, leftMenu, leftSideBarType, showRightSidebar } =
    useSelector(({ Layout }) => Layout);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function tToggle() {
    if (isMobile) {
      // dispatch(toggleLeftmenu(!leftMenu));
      if (leftSideBarType === "default") {
        dispatch(changeSidebarType("condensed", isMobile));
      } else if (leftSideBarType === "condensed") {
        dispatch(changeSidebarType("default", isMobile));
      }
    }
  }

  return (
    <React.Fragment>
      <li onClick={tToggle}>
        <Link to="/dashboard">
          <i className="bx bx-home-circle"></i>
          <span>{props.t("dashboard")}</span>
        </Link>
      </li>

      <li onClick={tToggle}>
        <Link to={"/users"}>
          <i className="bx bx-user-circle" />
          <span>{props.t("Users")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-collection" />
          <span>{props.t("Collections")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li onClick={tToggle}>
            <Link to="/collections">{props.t("List")}</Link>
          </li>
          <li onClick={tToggle}>
            <Link to="/collection/add">{props.t("Add")}</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-collection" />
          <span>{props.t("Equipment Activities")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li onClick={tToggle}>
            <Link to="/equipment/activities">{props.t("List")}</Link>
          </li>
          <li onClick={tToggle}>
            <Link to="/equipment/activities/add">{props.t("Add")}</Link>
          </li>
        </ul>
      </li>
      
      {/* 
      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-chevron-up-square" />
          <span>{props.t("Gallery")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/gallery">{props.t("List Gallery")}</Link>
          </li>
          <li>
            <Link to="/add_gallery">{props.t("Add Gallery")}</Link>
          </li>
        </ul>
      </li> */}

      <li onClick={tToggle}>
        <Link to={"/activities"}>
          <i className="bx bx-bar-chart" />
          <span>{props.t("Activities")}</span>
        </Link>
      </li>
      <li>
          <Link to={"/#"} className="has-arrow waves-effect">
            <i className="bx bx-bar-chart" />
            <span>{props.t("Contents")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/contents">{props.t("List Contents")}</Link>
            </li>
            <li>
              <Link to="/content/add">{props.t("Add Content")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={"/contact-us"}>
            <i className="bx bx-bar-chart" />
            <span>{props.t("Contact Us")}</span>
          </Link>
        </li>
      <li onClick={tToggle}>
        <Link to={"/nfts-metadata"}>
          <i className="bx bx-bar-chart" />
          <span>{props.t("Nfts Metadata")}</span>
        </Link>
      </li>
      <li onClick={tToggle}>
        <Link to={"/nfts-full-metadata"}>
          <i className="bx bx-bar-chart" />
          <span>{props.t("Nfts Main Metadata")}</span>
        </Link>
      </li>

      <li onClick={tToggle}>
        <Link to={"/team"}>
          <i className="fa fa-users"></i>
          <span>{props.t("Team")}</span>
        </Link>
      </li>

      <li onClick={tToggle}>
        <Link to={"/faqs"}>
          <i className="bx bx-chat"></i>
          <span>{props.t("Faqs")}</span>
        </Link>
      </li>

      <li>
        <Link to={"/#"} className="has-arrow waves-effect">
          <i className="bx bx-cog" />
          <span>{props.t("Settings")}</span>
        </Link>

        <ul className="sub-menu" aria-expanded="false">
          <li onClick={tToggle}>
            <Link to="/general">
              <i className="bx bx-cog" />
              <span>{props.t("General Settings")}</span>
            </Link>
          </li>
         
          <li onClick={tToggle}>
            <Link to="/blockchain">
              <i className="bx bx-bitcoin" />
              <span>{props.t("Blockchain Network")}</span>
            </Link>
          </li>

          <li onClick={tToggle}>
            <Link to="/contract">
              <i className="bx bx-cog" />
              <span>{props.t("Contract Settings")}</span>
            </Link>
          </li>

          <li onClick={tToggle}>
            <Link to="/wallet">
              <i className="bx bx-wallet-alt" />
              <span>{props.t("Wallet Settings")}</span>
            </Link>
          </li>
          <li onClick={tToggle}>
            <Link to="/nfts-mint-transfer">
              <i className="bx bx-cog" />
              <span>{props.t("Nft Settings")}</span>
            </Link>
          </li>
        </ul>
      </li>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = ({ Login }) => ({
  role: Login?.user?.role || "",
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(SidebarContent))
);
