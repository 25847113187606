import * as TYPES from "./actionType";

const INIT_STATE = {
  loading: false,
  teams: [],
  team: {},
  error: "",
};

const Reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // add Teams

    case TYPES.POST_TEAM:
      return {
        ...state,
        loading: true,
      };
    case TYPES.POST_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.POST_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    //   get Team List
    case TYPES.GET_TEAMS:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload.data || [],
        totalTeam: action.payload.totalCount || 0,
        loading: false,
      };
    case TYPES.GET_TEAMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //  get Team View
    case TYPES.GET_TEAM:
      return {
        ...state,
        loading: true,
        team: {},
      };
    case TYPES.GET_TEAM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TYPES.GET_TEAM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        team: action.payload,
      };

    //   Update Team
    case TYPES.PUT_TEAM:
      return {
        ...state,
        loading: true,
      };
    case TYPES.PUT_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.PUT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    //   delete Team
    case TYPES.DELETE_TEAM:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_TEAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPES.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default Reducer;
