import * as TYPES from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: TYPES.LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: TYPES.LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history, role) => {
  return {
    type: TYPES.LOGOUT_USER,
    payload: { history, role },
  };
};

export const currentUser = () => {
  return {
    type: TYPES.GET_CURRENT_USER,
  };
};

export const currentUserSuccess = (user) => {
  return {
    type: TYPES.GET_CURRENT_USER_SUCCESS,
    payload: user,
  };
};

export const currentUserFail = (error) => {
  return {
    type: TYPES.GET_CURRENT_USER_FAIL,
    payload: error,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: TYPES.LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: TYPES.API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: TYPES.SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};

export const getLanguages = () => ({
  type: TYPES.GET_LANGUAGES,
});

export const getLanguagesSuccess = (data) => ({
  type: TYPES.GET_LANGUAGES_SUCCESS,
  payload: data,
});

export const getLanguagesFail = (error) => ({
  type: TYPES.GET_LANGUAGES_FAIL,
  payload: error,
});

export const userWhiteList = (data, callback) => ({
  type: TYPES.USER_WHITE_LIST,
  payload: { data, callback },
});

export const userWhiteListFail = (error) => ({
  type: TYPES.USER_WHITE_LIST_FAIL,
  payload: error,
});

export const userWhiteListSuccess = () => ({
  type: TYPES.USER_WHITE_LIST_SUCCESS,
});
