/** Super Admin apis start */

// LOGIN
export const POST_LOGIN = "/admin/login";
export const STATUS_CHANGE_BY_ADMIN = "/admin/status-change";
export const UPDATE_VENDOR_BY_ADMIN = "/vendor/update";
export const CHANGE_PASSWORD = "/admin/change-password";
export const GET_LANGUAGES = "/setting/configs";
export const DELETE_STORE = "/super-admin/deletestore";

// CUSTOMERS (STORES)
export const UPDATE_STORE_STATUS = "/super-admin/store/updateStatus";
export const PUT_STORE_KEYS = "/super-admin/store/keys";
export const POST_CHECK_MAIL = "/super-admin/checkMailgunEmail";
export const POST_HIDE_THINGS = "/super-admin/store/hidethings";
export const WHITELIST_ACCEPT_REJECT = "/super-admin/store/hidethings";

// Collections
export const GET_COLLECTIONS = "/collection/admin/list";
export const GET_COLLECTION = "/collection/admin/view";
export const POST_COLLECTION = "/collection/admin/add";
export const PUT_COLLECTION = "/collection/admin/update";
export const DELETE_COLLECTION = "/collection/admin/remove";
export const POST_DEPLOY_COLLECTION =
  "/collection/admin/compile-and-deploy-contract";
export const POST_COLLECTION_ACTION = "/collection/admin/action-contract";
export const POST_LAYERS = "/nft-creation/layers";
export const GET_LAYERS = "/nft-creation/get-layers";
export const GENERATE_NFT = "/nft-creation/generate-nft";

// Transactions
export const GET_TRANSACTIONS = "/super-admin/store/transaction";
export const POST_STORE_CHARGE = "/super-admin/store/charge";
export const POST_STORE_REFUND = "/super-admin/store/refund";

// Store User Login
export const STORE_LOGIN_BY_ADMIN = "/super-admin/store/generate-token";

/** Super Admin apis end */

export const POST_FORGOT_PASSWORD = "/admin/emailCheck";
export const POST_RESET_PASSWORD = "/admin/resetpassword";
export const SOCIAL_LOGIN = "/social-login";

// Profile
export const GET_CURRENT_USER = "/store/me";
export const PUT_PROFILE = "/store/updateprofile";

// SIGNUP
export const POST_REGISTER_EMAIL = "/store/signupemail";
export const POST_STORE_NAME = "/store/check";
export const POST_REGISTER = "/vendor/register";

// File
export const POST_FILE = "/file/add";
export const POST_REMOVE_FILE = "/file/remove";

// Settings
export const GET_DASHBOARD = "/admin/dashboard";
export const API_SETTINGS = "/setting";
export const GET_SETTINGS = "/setting/view";
export const PUT_SETTINGS = "/setting/update";
export const GET_STORE_SETTINGS = "storetype";
export const PUT_STORE_SETTINGS = "addsetting";
export const GET_ACCESS_LIST = "/setting/accesslist";
export const GET_STORE_TYPES = "/setting/storetypes";
export const GET_STARTED = "/setting/getStarted";
export const POST_CONTRACT_DEPLOY = "/setting/admin/deploycontract";

// Category
export const ADD_CATEGORY = "/category/admin/add";
export const VIEW_CATEGORIES = "/category/admin/list";
export const DELETE_CATEGORY = "/category/admin/remove";
export const PUT_ITEM_STATUS = "/category/admin/updatestatus/all";
export const GET_CATEGORY = "/category/admin/view";
export const PUT_CATEGORY = "/category/admin/update";

// Gallery
export const ADD_GALLERY = "/gallery/admin/add";
export const VIEW_GALLERY = "/gallery/admin/list";
export const DELETE_GALLERY = "/gallery/admin/remove";
export const PUT_GALLERY_STATUS = "/gallery/admin/updatestatus/all";
export const GET_GALLERY = "/gallery/admin/view";
export const PUT_GALLERY = "/gallery/admin/update";

// CONTENTS
export const GET_CONTENTS = "/content/admin/list";
export const ADD_CONTENT = "/content/admin/add";
export const GET_CONTENT = "/content/admin/view";
export const PUT_CONTENT = "/content/admin/update";
export const DELETE_CONTENT = "/content/admin/remove";
export const PUT_CONTENTS_STATUS = "/content/updatestatus/all";

// Users
export const GET_USERS = "/user/admin/list";
export const VIEW_USER = "/user/admin/view";

// Items
// export const VIEW_ITEM_ACTIVITY = "/item/activity"
export const POST_ITEM = "/item/admin/add";
export const PUT_ITEM = "/item/admin/update";
export const GET_ITEMS = "/item/admin/list";
export const GET_ITEM = "/item/admin/view";
export const DELETE_ITEM = "/item/admin/view";
export const MINT_ITEM = "/item/admin/mint";

// Activity
export const GET_ACTIVITIES = "/item/admin/activity";

// FAQS
export const GET_FAQS = "/faq/admin/list";
export const ADD_FAQ = "/faq/admin/add";
export const GET_FAQ = "/faq/admin/view";
export const PUT_FAQ = "/faq/admin/update";
export const DELETE_FAQ = "/faq/admin/remove";
export const PUT_FAQS_STATUS = "/faq/updatestatus/all";

// User White
export const POST_USER_WHITE_LIST = "/user/admin/action-whitelist";

// Teams
export const POST_TEAM = "/team/add";
export const GET_TEAMS = "/team/list";
export const GET_TEAM = "/team";
export const PUT_TEAM = "/team/update";
export const DELETE_TEAM = "/team/delete";

//NFTS METADATA
export const GET_NFTS_METADATA = "/item/nft-metadata-list";
export const REFRESH_NFTS_METADATA = "/item/refresh-nfts-metadata";
export const GET_NFT_METADATA = "/item/nft-metadata";
export const UPDATE_NFT_METADATA = "/item/nft-metadata/update";

export const UPDATE_NFT_IMAGE = "/item/nft/image-update";

export const AFTER_DEPLOY_COLLECTION_UPDATE =
  "/collection/admin/after-deploy-collection-update";

export const REGAIN_USERS_PLAYCHANCES = "/user/admin/regain-play-chances";

export const ADD_ALLOW_DISCORD_VILLAIN =
  "/collection/admin/add-villain-discord-id";
export const REMOVE_ALLOW_DISCORD_VILLAIN =
  "/collection/admin/remove-villain-discord-id";

export const NFT_COPY_RRCTOMAIN = "/item/copy-tokens";
export const POST_MINT_ACTIVITY = "/item/admin/mint-activity";

export const UPLOAD_METADATA_UPDATE = "/item/upload-nft-metadata-file";

export const REVEAL_NFTS = "/item/reveal-nfts-metadata";

export const DOWNLOAD_NFT_METADATA = "/item/all-nfts-metadata";
export const GET_CONTACT_US_LIST = "/contact/list";
export const POST_ARMORS_ACTIVITY = "/item/admin/armors-mint-activity";
export const GET_EQUIP_ACTIVITIES = "/item/admin/equipment-activities-list";
export const ADD_EQUIP_ACTIVITY = "/item/admin/add-equipment-activity";
export const REMOVE_EQUIP_ACTIVITY = "/item/admin/remove-equipment-activity";