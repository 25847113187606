export const CONTENT_API_FAIL = "CONTENT_API_FAIL"

// CONTENTs
export const GET_CONTENTS = "GET_CONTENTS"
export const GET_CONTENTS_FAIL = "GET_CONTENTS_FAIL"
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS"

// Add CONTENT
export const ADD_CONTENT = "ADD_CONTENT"
export const ADD_CONTENT_FAIL = "ADD_CONTENT_FAIL"
export const ADD_CONTENT_SUCCESS = "ADD_CONTENT_SUCCESS"

// Get CONTENT
export const GET_CONTENT = "GET_CONTENT"
export const GET_CONTENT_FAIL = "GET_CONTENT_FAIL"
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS"

// update CONTENT
export const PUT_CONTENT = "PUT_CONTENT"
export const PUT_CONTENT_FAIL = "PUT_CONTENT_FAIL"
export const PUT_CONTENT_SUCCESS = "PUT_CONTENT_SUCCESS"

// Delete CONTENT
export const DELETE_CONTENT = "DELETE_CONTENT"
export const DELETE_CONTENT_FAIL = "DELETE_CONTENT_FAIL"
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS"

// Update Multi CONTENT Status
export const PUT_CONTENTS_STATUS = "PUT_CONTENTS_STATUS"
export const PUT_CONTENTS_STATUS_FAIL = "PUT_CONTENTS_STATUS_FAIL"
export const PUT_CONTENTS_STATUS_SUCCESS = "PUT_CONTENTS_STATUS_SUCCESS"
