import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import toastr from "toastr"

import {
  Row,
  Col,
  Button,
  Label,
  Container,
  Card,
  CardBody,
  Spinner,
  Alert,
} from "reactstrap"

// Actions
import { getSettings, postDeployContract } from "store/actions"

import Breadcrumbs from "components/Common/Breadcrumb"

const BLOCKCHAIN_CONTRACT = (blockchain, contractAddress) => {
  return blockchain?.map((item) => {
    let contract = contractAddress?.find(
      (address) => address.type === item.type,
    )

    if (!contract) {
      return {
        label: item.label,
        type: item.type,
        mainnet: "",
        testnet: "",
      }
    }

    contract.label = item.label
    return contract
  })
}

const ContractSettings = (props) => {
  const { onGetSettings, onPostDeployContract, loading, error } = props

  const [fields, setFields] = useState({
    contractAddress: [],
  })

  useEffect(() => {
    onGetSettings()
  }, [])

  useEffect(() => {
    if (props.settings && props.settings.blockchain) {
      setFields({
        contractAddress: BLOCKCHAIN_CONTRACT(
          props.settings.blockchain,
          props.settings.multiSend?.contractAddress,
        ),
      })
    }
  }, [JSON.stringify(props?.settings)])

  const handleChange = (name) => (event) => {
    const { value } = event.target

    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  function deploy(item, blockchainNetworkMode) {
    try {
      onPostDeployContract({
        ...item,
        blockchainNetworkMode,
      })
    } catch {
      throw new Error("Some Error Occurred!!")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Settings"
            breadcrumbItem="Contract Address Settings"
          />

          <Card>
            <CardBody className="spinner-content">
              {error && typeof error === "string" ? (
                <Alert color="danger">{error}</Alert>
              ) : null}

              {loading && (
                <div className="spinner">
                  <Spinner color="primary" className="mr-2" />
                </div>
              )}

              {fields.contractAddress.map((item, index) => (
                <React.Fragment key={`idx-${item._id}-${index}`}>
                  <Row>
                    <Col xs={12}>
                      <Label>{item.label} Address</Label>
                    </Col>

                    <Col sm={12} md={6}>
                      <Label>Mainnet</Label>
                      {/* <Button
                        color="primary"
                        className="ml-2 mt-2"
                        onClick={() => deploy(item, "mainnet")}
                      >
                        Deploy
                      </Button> */}

                      <h4>{item.mainnet || "N/A"}</h4>
                    </Col>

                    <Col sm={12} md={6}>
                      <Label>Testnet</Label>
                      {/* <Button
                        color="primary"
                        className="ml-2 mt-2"
                        onClick={() => deploy(item, "testnet")}
                      >
                        Deploy
                      </Button> */}

                      <h4>{item.testnet || "N/A"}</h4>
                    </Col>
                  </Row>

                  <hr className="my-3" />
                </React.Fragment>
              ))}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContractSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
}

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
})

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPostDeployContract: (data, callback) =>
    dispatch(postDeployContract(data, callback)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractSettings),
)
