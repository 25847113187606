import React from "react";
import { Redirect } from "react-router-dom";
// Authentication related pages
import Signup from "../pages/Authentication/signUp";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ProfileChangePassword from "../pages/Authentication/ChangePassword";
// import ProfileEdit from "../pages/Authentication/EditProfile";

import Dashboard from "pages/Dashboard";

import CollectionsList from "../pages/Collections/List";
import CollectionAdd from "../pages/Collections/AddEdit";
import ViewCollection from "../pages/Collections/ViewCollection";

import Customers_List from "../pages/Customers/CustomersList";
import ViewCustomer from "../pages/Customers/ViewCustomer";

import Gallerys from "../pages/Gallery/List";
import AddGallery from "../pages/Gallery/AddEdit";
import EditGallery from "../pages/Gallery/AddEdit";

import Faq from "../pages/Faq/List";
import FaqAddEdit from "../pages/Faq/AddEdit";

import TeamList from "../pages/Teams/List";
import AddTeam from "../pages/Teams/AddEdit";

import ActivitiesList from "../pages/Activities/List";
import TransactionsList from "../pages/Transactions/List";

import Settings from "../pages/Settings";
import Wallet from "../pages/Settings/Wallet";
import ContractSettings from "../pages/Settings/Contract";
import BlockchainNetwork from "../pages/Settings/BlockchainNetwork";
import NftsMetadataList from "../pages/NftsMetadata/List";
import EditNftMetadata from "../pages/NftsMetadata/EditMetadata";
import NftsFullMetadataList from "../pages/NftsFullMetadata/List";
import EditFullNftMetadata from "../pages/NftsFullMetadata/EditMetadata";
import NftsMintTransfer from "../pages/Settings/NftsMintTransfer";
import ViewActivities from "pages/Activities/ViewActivities";
import Content from "pages/Content/List";
import ContentAddEdit from "pages/Content/AddEdit";
import ContactUs from "pages/ContactUs/List";
import EquipActivityAdd from "pages/EquipActivity/Add";
import EquipActivityList from "pages/EquipActivity/List";

const userRoutes = () => [
  // Logout
  { path: "/logout", exact: true, component: Logout },
  // { path: "/edit-profile", exact: true, component: ProfileEdit },
  {
    path: "/change-password",
    exact: true,
    component: ProfileChangePassword,
  },
  { path: "/users", component: Customers_List },
  // { path: "/whitelist", component: Whitelist },
  { path: "/users/profile/:id", component: ViewCustomer },

  { path: "/collections", component: CollectionsList },
  { path: "/collection/add", component: CollectionAdd },
  { path: "/collections/edit/:id", component: CollectionAdd },
  { path: "/collections/:id/view", component: ViewCollection },

  { path: "/contents", component: Content },
  { path: "/content/add", component: ContentAddEdit },
  { path: "/content/:id/edit", component: ContentAddEdit },
  { path: "/contact-us", component: ContactUs },


  // { path: "/categories", component: Categories },
  // { path: "/add_category", component: AddCategory },
  // { path: "/categories/edit/:category_id", component: EditCategory },

  { path: "/faqs", component: Faq },
  { path: "/faqs/add", component: FaqAddEdit },
  { path: "/faqs/:id/edit", component: FaqAddEdit },

  { path: "/team", component: TeamList },
  { path: "/team/add", component: AddTeam },
  { path: "/team/:id/edit", component: AddTeam },

  { path: "/gallery", component: Gallerys },
  { path: "/add_gallery", component: AddGallery },
  { path: "/gallerys/edit/:category_id", component: EditGallery },

  { path: "/activities", component: ActivitiesList },
  { path: "/activities/:id/view", component: ViewActivities },
  { path: "/equipment/activities", component: EquipActivityList },
  { path: "/equipment/activities/add", component: EquipActivityAdd },
  { path: "/activities/:id/view", component: ViewActivities },
  { path: "/transactions", component: TransactionsList },

  // { path: "/settings", component: Settings }
  { path: "/general", component: Settings },
  { path: "/blockchain", component: BlockchainNetwork },
  { path: "/contract", component: ContractSettings },
  { path: "/wallet", component: Wallet },
  { path: "/nfts-metadata", component: NftsMetadataList },
  { path: "/nft-metadata/:_id", component: EditNftMetadata },
  { path: "/nfts-full-metadata", component: NftsFullMetadataList },
  { path: "/nft-full-metadata/:_id", component: EditFullNftMetadata },
  { path: "/nfts-mint-transfer", component: NftsMintTransfer },

  { path: "/dashboard", component: Dashboard },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/signup", component: Signup },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { userRoutes, authRoutes };
