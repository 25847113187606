export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAIL = "GET_CURRENT_USER_FAIL";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";

export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";

export const USER_WHITE_LIST = "USER_WHITE_LIST";
export const USER_WHITE_LIST_FAIL = "USER_WHITE_LIST_FAIL";
export const USER_WHITE_LIST_SUCCESS = "USER_WHITE_LIST_SUCCESS";
