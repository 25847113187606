import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import Blockchain from "components/Select/Blockchain";

import {
  addEquipActivityRequest,
} from "store/actions";

// Images
import avatar4 from "assets/images/users/avatar-9.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { listArmorsNfts } from "helpers/contants";

const AddEdit = ({
  history,
  error,
  loading,
  collection,
  onGetCollection,
  onUploadFile,
  settings,
  ...props
}) => {
  const { id } = useParams();
  const [fields, setFields] = useState({
    type:"weapons-and-gear",
    token_id :"",
    equipment_token_id:"",
    equipment_status:"unequiped",
    equipment_trait:"",
    walletAddress:""
});


  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

   //og_contract_address is contract address of og mint nfts contract for type claim_weapon
    props.addEquipActivityRequest(fields, ()=>{
      history.goBack();
    });
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Equipment Activity"
                breadcrumbItem={props.t("Add")}
                breadcrumbItems={[
                  { title: props.t("Equipment Activities"), link: "/equipment/activities" },
                  { title: props.t(id ? "Edit" : "Add") },
                ]}
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                        <Col md={6}>
                                <FormGroup>
                                    <Label>Type</Label>

                                    <select 
                                      className="custom-select w-100"
                                      placeholder="Please select armor token id" 
                                      name="type" 
                                      value={fields.type}
                                      onChange={handleChange("type")}>
                                      {["weapons-and-gear","special-power","legendary-drone"].map((status)=>
                                       <option value={status}>
                                         {status}
                                       </option>)}
                                    </select >
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Wallet Address")}</Label>
                              <InputGroup>
                                <Input
                                  type="text"
                                  name="walletAddress"
                                  value={fields.walletAddress}
                                  onChange={handleChange("walletAddress")}
                                  required
                                />
                             
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Syndicate Token id")}</Label>

                              <InputGroup>
                                <Input
                                  type="number"
                                  name="token_id"
                                  value={fields.token_id}
                                  onChange={handleChange("token_id")}
                                  min={1}
                                  max={3333}
                                  required
                                />
                             
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                                <FormGroup>
                                    <Label>Armor Token Id</Label>

                                    <select 
                                      className="custom-select w-100"
                                      placeholder="Please select armor token id" 
                                      name="equipment_token_id" 
                                      value={fields.equipment_token_id}
                                      onChange={handleChange("equipment_token_id")}>
                                      {listArmorsNfts.map((nft)=>
                                       <option value={nft.tokenId}>
                                         {`${nft.name} (${nft.tokenId})`}
                                       </option>)}
                                    </select >
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Equipment trait value")}</Label>

                              <InputGroup>
                                <Input
                                  type="text"
                                  name="equipment_trait"
                                  value={fields.equipment_trait}
                                  onChange={handleChange("equipment_trait")}
                                  required
                                />
                             
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                                <FormGroup>
                                    <Label>Equipment status</Label>

                                    <select 
                                      className="custom-select w-100"
                                      placeholder="Please select armor token id" 
                                      name="equipment_status" 
                                      value={fields.equipment_status}
                                      onChange={handleChange("equipment_status")}>
                                      {["equiped","unequiped"].map((status)=>
                                       <option value={status}>
                                         {status}
                                       </option>)}
                                    </select >
                                </FormGroup>
                            </Col>
                            
   
                        </Row>


                        <div className="mt-4">
                          <button
                            type="submit"
                            onClick={onSubmit}
                            className="btn btn-primary w-md"
                          >
                            {props.t("Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


const mapStateToProps = ({ Settings }) => ({
  settings : Settings.settings
});

const mapDispatchToProps = (dispatch) => ({
  addEquipActivityRequest: (data, callback) => dispatch(addEquipActivityRequest(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddEdit))
);
