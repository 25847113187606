import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const ViewModal = props => {
  const { isOpen, toggle, data } = props

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
      closeButton
    >
     <ModalHeader toggle={toggle}>Message</ModalHeader>
        <ModalBody>
          <div>
            <p>{data?.message}</p>
          </div>

        </ModalBody>
    </Modal>
  )
}

export default withTranslation()(ViewModal)
