import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, CHANGE_PASSWORD } from "./actionTypes"
import {
  profileSuccess,
  profileError,
  putChangePwdSuccess,
  putChangePwdError,
} from "./actions"

//Include Both Helper File with needed methods
import { putProfile, putChangePassword } from "helpers/backend_helper"

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(putProfile, user)
    if (response.status === "failure") {
      return yield put(profileError(response.message))
    }

    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError("Internal Server Error"))
  }
}

function* changePassword({ payload: { data, callback } }) {
  try {
    const response = yield call(putChangePassword, data)
    if (response.status === "failure") {
      return yield put(putChangePwdError(response.message))
    }

    callback && callback()
    yield put(putChangePwdSuccess(response))
  } catch (error) {
    yield put(putChangePwdError("Internal Server Error"))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
