import * as TYPES from "./actionTypes";

export const apiFail = (error) => ({
  type: TYPES.COLLECTION_API_FAIL,
  payload: error,
});

/* Get collections */
export const getCollections = (data) => ({
  type: TYPES.GET_COLLECTIONS,
  payload: data,
});

export const getCollectionsSuccess = (collections) => ({
  type: TYPES.GET_COLLECTIONS_SUCCESS,
  payload: collections,
});

export const getCollectionsFail = (error) => ({
  type: TYPES.GET_COLLECTIONS_FAIL,
  payload: error,
});
/* Get Collections END */

/* Add Collection */
export const addCollection = (collection, history) => ({
  type: TYPES.ADD_COLLECTION,
  payload: { collection, history },
});

export const addCollectionFail = (error) => ({
  type: TYPES.ADD_COLLECTION_FAIL,
  payload: error,
});

export const addCollectionSuccess = () => ({
  type: TYPES.ADD_COLLECTION_SUCCESS,
});
/* Add Collection END */

/* Get Collection */
export const getCollection = (id,callback) => ({
  type: TYPES.GET_COLLECTION,
  payload: id,
  callback
});

export const getCollectionFail = (error) => ({
  type: TYPES.GET_COLLECTION_FAIL,
  payload: error,
});

export const getCollectionSuccess = (collection) => ({
  type: TYPES.GET_COLLECTION_SUCCESS,
  payload: collection,
});
/* Get Collection END */

/* Update Collection */
export const putCollection = (data, history) => ({
  type: TYPES.PUT_COLLECTION,
  payload: { data, history },
});

export const putCollectionFail = (error) => ({
  type: TYPES.PUT_COLLECTION_FAIL,
  payload: error,
});

export const putCollectionSuccess = () => ({
  type: TYPES.PUT_COLLECTION_SUCCESS,
});
/* Update Collection END */

/* Delete Collection */
export const deleteCollection = (data, callback) => ({
  type: TYPES.DELETE_COLLECTION,
  payload: { data, callback },
});

export const deleteCollectionFail = (error) => ({
  type: TYPES.DELETE_COLLECTION_FAIL,
  payload: error,
});

export const deleteCollectionSuccess = () => ({
  type: TYPES.DELETE_COLLECTION_SUCCESS,
});
/* Delete Faz END */

/* Collection Action */
export const postCollectionAction = (data, callback) => ({
  type: TYPES.POST_COLLECTION_ACTION,
  payload: { data, callback },
});

export const postCollectionActionFail = (error) => ({
  type: TYPES.POST_COLLECTION_ACTION_FAIL,
  payload: error,
});

export const postCollectionActionSuccess = () => ({
  type: TYPES.POST_COLLECTION_ACTION_SUCCESS,
});
/* Collection Action END */

/* Post Layers */
export const postLayers = (data, callback) => ({
  type: TYPES.POST_LAYERS,
  payload: { data, callback },
});

export const postLayersSuccess = (data) => ({
  type: TYPES.POST_LAYERS_SUCCESS,
  payload: data,
});

export const postLayersFail = (error) => ({
  type: TYPES.POST_LAYERS_FAIL,
  payload: error,
});
/* Post Layers END */

/* Get Layers */
export const getLayers = (data) => ({
  type: TYPES.GET_LAYERS,
  payload: data,
});

export const getLayersSuccess = (data) => ({
  type: TYPES.GET_LAYERS_SUCCESS,
  payload: data,
});

export const getLayersFail = (error) => ({
  type: TYPES.GET_LAYERS_FAIL,
  payload: error,
});
/* Get Layers END */

/* Generate NFT */
export const generateNft = (data, callback) => ({
  type: TYPES.GENERATE_NFT,
  payload: { data, callback },
});

export const generateNftSuccess = (data) => ({
  type: TYPES.GENERATE_NFT_SUCCESS,
  payload: data,
});

export const generateNftFail = (error) => ({
  type: TYPES.GENERATE_NFT_FAIL,
  payload: error,
});
/* Generate NFT END */

/* Post Deploy */
export const postDeployCollection = (data, callback) => ({
  type: TYPES.POST_DEPLOY_COLLECTION,
  payload: { data, callback },
});

export const postDeployCollectionFail = (error) => ({
  type: TYPES.POST_DEPLOY_COLLECTION_FAIL,
  payload: error,
});

export const postDeployCollectionSuccess = () => ({
  type: TYPES.POST_DEPLOY_COLLECTION_SUCCESS,
});
/* Post Deploy END */

//after deploy collection update

export const afterDeployCollectionUpdateRequest = (data, callback) => ({
  type: TYPES.AFTER_DEPLOY_COLLECTION_UPDATE_REQUEST,
  payload: { data, callback },
});

export const afterDeployCollectionUpdateSuccess = (data) => ({
  type: TYPES.AFTER_DEPLOY_COLLECTION_UPDATE_SUCCESS,
  payload: data,
});

export const afterDeployCollectionUpdateFail = (error) => ({
  type: TYPES.AFTER_DEPLOY_COLLECTION_UPDATE_FAIL,
  payload: error,
});

//add and remove discord villain 

export const addDiscordVillainRequest = (data, callback) => ({
  type: TYPES.ADD_DISCORD_VILLAIN_REQUEST,
  payload: { data, callback },
});

export const addDiscordVillainSuccess = (data) => ({
  type: TYPES.ADD_DISCORD_VILLAIN_SUCCESS,
  payload: data,
});

export const addDiscordVillainFail = (error) => ({
  type: TYPES.ADD_DISCORD_VILLAIN_FAIL,
  payload: error,
});

export const removeDiscordVillainRequest = (data, callback) => ({
  type: TYPES.REMOVE_DISCORD_VILLAIN_REQUEST,
  payload: { data, callback },
});

export const removeDiscordVillainSuccess = (data) => ({
  type: TYPES.REMOVE_DISCORD_VILLAIN_SUCCESS,
  payload: data,
});

export const removeDiscordVillainFail = (error) => ({
  type: TYPES.REMOVE_DISCORD_VILLAIN_FAIL,
  payload: error,
});

