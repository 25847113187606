import React, { useEffect, useState,useRef } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";

import {
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  CardHeader,
  Button,
  Spinner,
  Badge,
} from "reactstrap";
import Web3InteractionEth, {
  convertFromWei,
  convertHexToNumber,
} from "util/web3InteractionEth";

// Actions
import {
  getCollection,
  getSettings,
  postNftRrcToMainBucketRequest,
  postMintActivity,
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletHooks } from "util/walletHooks";
import * as XLSX from "xlsx";

const initVal = {
  cost: "",
  transfer_from: "",
  transfer_to: "",
  token_id: "",
};
const SyndicateAirdrop = ({}) => {
  const wallet = walletHooks();
  const [key, setKey] = useState(0); // Key for resetting the input
  const dispatch = useDispatch();
  const { loadingCollection, loadingSettings, settings, activityLoader } =
    useSelector(({ Settings, collections, items }) => ({
      loadingSettings: Settings.loading,
      settings: Settings.settings,
      loadingCollection: collections.loading,
      activityLoader: items.loading,
    }));

  const [walletAddress, setWalletAddress] = useState("");
  const [walletAddresses, setWalletAddresses] = useState([]);

  const [loaders, setLoaders] = useState({
    syndicateMint: false,
    syndicateTransfer: false,
  });

  const [rrsCollection, setRrsCollection] = useState(null);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (JSON.stringify(settings !== "{}")) {
      dispatch(
        getCollection(settings.activeCollectionId, (data) => {
          console.log("syndicate collection", data);
          setRrsCollection(data);
        })
      );
    }
  }, [JSON.stringify(settings)]);

  const onHandleChange = (e) => {
    const { value } = e.target;
    setWalletAddress(value);
  };

  const onAddWallet = () => {
    if (!walletAddress) {
      return toastr.error("Wallet address is required!");
    }
    const copy = [...walletAddresses];

    copy.push(walletAddress);
    setWalletAddresses(copy);
    setWalletAddress("");
  };

  const onRemoveWallet = (index) => {
    setWalletAddresses((wallets) => wallets.filter((_, idx) => index !== idx));
  };

  const onClickAirdrop = async () => {
    try {
      if (walletAddresses?.length < 1) {
        return toastr.error("wallet address is required!");
      }
      if (!rrsCollection?._id) {
        return toastr.error("collection not load or found!");
      }

      const web3InstanceEth = new Web3InteractionEth(
        rrsCollection.blockchain,
        window.ethereum,
        settings
      );
      await web3InstanceEth.switchChain();
      const contract = await web3InstanceEth.getContract(
        rrsCollection.contractAbi,
        rrsCollection.contractAddress
      );

      if (!contract) {
        // setloading(false);
        return toastr.error("Service not provided!");
      }

      console.log("contract", contract);

      const result = await contract.callStatic["airdropNFT"](walletAddresses);

      console.log("Successfully chek : ", result);

      setLoaders((prev) => ({ ...prev, syndicateMint: true }));

      const transaction = await contract.airdropNFT(walletAddresses);
      const tx = await transaction.wait();
      console.log("tx", tx);
      const txHash = tx.transactionHash;

      setLoaders((prev) => ({ ...prev, syndicateMint: false }));

      //save item and history
      if (txHash) {
        toastr.success("Nft's minted successfully!");

        let payload = await Promise.all(
          tx.logs.map(async (log) => {
            const rawAddress = log.topics[2]; // Assuming there's only one address in the logs
            const mintedTokenId = convertHexToNumber(log.topics[3]);
            const formattedAddress = "0x" + rawAddress?.slice(26);
            console.log("wallet address for transaction to", formattedAddress);
            // let _tokens = await contract
            // .walletOfOwner(formattedAddress);
            //  _tokens =  _tokens?.map(token => token?.toNumber());

            // console.log("v", _tokens);
            //save item and history
            if (!mintedTokenId) return; //extra log without tokenid
            const recentMintedTokens = [mintedTokenId];
            // const recentMintedTokens = _tokens.slice(-1);
            return {
              type: rrsCollection.blockchain,
              collection_id: rrsCollection._id,
              transactionHash: txHash,
              price: rrsCollection.cost,
              quantity: 1,
              walletAddress: formattedAddress,
              mint_type: "og-mint",
              token_ids: recentMintedTokens,
              isAirDropped: true,
            };
          })
        );
        console.log("payload for save activity", payload);
        payload = payload.filter(
          (element) => element !== null && element !== undefined
        );
        console.log("updated payload for save activity", payload);

        dispatch(
          postMintActivity(
            {
              type: rrsCollection.blockchain,
              activities_type: "multiple",
              activities: payload,
            },
            (res) => {
              console.log("response activity", res);
              toastr.success("Data successfully saved in our db");
              setWalletAddress(null);
              setWalletAddresses([]);
            }
          )
        );

        const allTokenIds = payload.reduce((accumulator, currentObject) => {
          // Concatenate the current object's token_ids to the accumulator array
          accumulator.push(...currentObject.token_ids);
          return accumulator;
        }, []);
        // Remove duplicates using Set and convert back to array
        const uniqueTokenIds = [...new Set(allTokenIds)];
        // //reveal nfts
        dispatch(
          postNftRrcToMainBucketRequest(
            { tokens: uniqueTokenIds },
            (response) => {
              console.log("response after tokens move", response);
            }
          )
        );
        
        

      }
    } catch (error) {
      console.log("Error", error);
      setLoaders((prev) => ({ ...prev, syndicateMint: false }));
      if (error?.message?.includes("reverted")) {
        // Extract the error message from the revert error
        const errorMessage = error.message.split('"')[1];
        
        // Display the error message to the user
        return toastr.error(errorMessage); // You can use other UI elements to display the message
      } 
      else if (error?.message) {
        return toastr.error(error?.message);
      }
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
   

    // Check file type
    if (!file) {
      toastr.error("No file uploaded.");
      return;
    }
    if (!file.name.endsWith(".xlsx") && !file.name.endsWith(".xls")) {
      toastr.error("Invalid file format. Please upload an Excel file.");
      return;
    }

    // Check file size (optional)
    const maxSize = 20 * 1024 * 1024; // 10 MB
    if (file.size > maxSize) {
      toastr.error("File size exceeds the maximum limit (20 MB).");
      return;
    }
    
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
console.log("data excell file",data)
      const headerRow = data[0]; // First row contains headers
      const result = [];

      for (let i = 1; i < data.length; i++) {
        if(!data[i][1]) continue;
        result.push(data[i][1]);
      }

      // Perform additional validation checks on the data if needed
      console.log("xlsx wallets", result);
      setWalletAddresses(result);
      setKey(prevKey => prevKey + 1);
    };

    reader.readAsBinaryString(file);
  };

  const onDownloadSampleFile = () =>{
    const data = [
      {
        "TOKEN_ID": 1,
        "WALLET_ADDRESS": "0x0d1da04610611862c1198b2426AdaD3a445cdD5E",
      }
    ];    
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // You can change "Sheet1" to whatever you want

    // Generate Excel file
    XLSX.writeFile(wb, "sample.xlsx");
  }

  return (
    <Card>
      <CardHeader className="bg-primary text-white">
        SYNDICATE AIRDROP
      </CardHeader>
      <CardBody className="spinner-content">
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Wallet Address</Label>

                <Input
                  type="string"
                  placeholder="Please enter wallet address"
                  name="walletAddress"
                  value={walletAddress}
                  onChange={onHandleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Button
                  className="mt-4"
                  onClick={onAddWallet}
                  type="button"
                  color="primary"
                >
                  Add
                </Button>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
               

<label class="filelabel">
    <i class="fa fa-paperclip">
    </i>
    <span class="title">
        Add File
    </span>
    
     <Input
                  type="file"
                  placeholder="upload wallet address xlsx"
                  onChange={handleFileUpload}
                  className="FileUpload1"
                  id="FileInput" 
                  key={key} 
                />
</label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                  <Button
                         type="button"
                         color="primary"
                    onClick={()=>onDownloadSampleFile()}
                  >
                   <svg className="hover-icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="#ffffff"  d="m14 2l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm4 18V9h-5V4H6v16zm-6-1l-4-4h2.5v-3h3v3H16z"/></svg> {" "}
                       Sample file
                      
                    </Button>
              </FormGroup>
            </Col>
          </Row>
          {walletAddresses.map((wallet, index) => (
            <Row>
              <Col md={6}>
                <Badge className="p-1" color="primary">
                  {wallet}
                </Badge>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Button
                    onClick={() => onRemoveWallet(index)}
                    type="button"
                    color="primary"
                  >
                    Remove
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          ))}
          <Row>
            <Col md={12}>
              <FormGroup>
                <Button
                  disabled={loaders.syndicateMint || activityLoader}
                  onClick={() => onClickAirdrop()}
                  type="button"
                  color="primary"
                >
                  {(loaders.syndicateMint || activityLoader) && (
                    <Spinner size={"sm"} color="light" />
                  )}{" "}
                  Airdrop
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default withRouter(SyndicateAirdrop);
