import * as CONST from "./actionTypes";

export const apiFail = (error) => ({
  type: CONST.GALLERY_API_FAIL,
  payload: error,
});

/* Get items */
export const getgallerys = (data) => ({
  type: CONST.GET_GALLERYS,
  payload: data,
});

export const getgallerysSuccess = (items) => ({
  type: CONST.GET_GALLERYS_SUCCESS,
  payload: items,
});

export const getgallerysFail = (error) => ({
  type: CONST.GET_GALLERYS_FAIL,
  payload: error,
});
/* Get gallery END */

/* Add gallery */
export const addgallery = (item, history) => ({
  type: CONST.ADD_GALLERY,
  payload: { item, history },
});

export const addgalleryFail = (error) => ({
  type: CONST.ADD_GALLERY_FAIL,
  payload: error,
});

export const addgallerySuccess = () => ({
  type: CONST.ADD_GALLERY_SUCCESS,
});
/* Add gallery END */

/* Get gallery */
export const getgallery = (id) => ({
  type: CONST.GET_GALLERY,
  payload: id,
});

export const getgalleryFail = (error) => ({
  type: CONST.GET_GALLERY_FAIL,
  payload: error,
});

export const getgallerySuccess = (item) => ({
  type: CONST.GET_GALLERY_SUCCESS,
  payload: item,
});
/* Get gallery END */

/* Update gallery */
export const putgallery = (data, history) => ({
  type: CONST.PUT_GALLERY,
  payload: { data, history },
});

export const putgalleryFail = (error) => ({
  type: CONST.PUT_GALLERY_FAIL,
  payload: error,
});

export const putgallerySuccess = () => ({
  type: CONST.PUT_GALLERY_SUCCESS,
});
/* Update gallery END */

/* Delete gallery */
export const deletegallery = (data, callback) => ({
  type: CONST.DELETE_GALLERY,
  payload: { data, callback },
});

export const deletegalleryFail = (error) => ({
  type: CONST.DELETE_GALLERY_FAIL,
  payload: error,
});

export const deletegallerySuccess = () => ({
  type: CONST.DELETE_GALLERY_SUCCESS,
});
/* Delete Faz END */

/* Update Multi gallery Status */
export const putgalleryStatus = (data, callback) => ({
  type: CONST.PUT_GALLERYS_STATUS,
  payload: { data, callback },
});

export const putgalleryStatusFail = (error) => ({
  type: CONST.PUT_GALLERYS_STATUS_FAIL,
  payload: error,
});

export const putgalleryStatusSuccess = () => ({
  type: CONST.PUT_GALLERYS_STATUS_SUCCESS,
});
/* Update Multi gallery Status END */
