import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Customer Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";
import toastr from "toastr";

function* onGetSettings() {
  try {
    const response = yield call(API.getSettings);

    if (response.status == "failure") {
      return yield put(ACTIONS.getSettingsFail(response.message));
    }

    yield put(ACTIONS.getSettingsSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getSettingsFail(error));
  }
}

function* onPutSettings({ payload }) {
  try {
    const response = yield call(API.putSettings, payload);

    if (response.status == "failure") {
      toastr.error(response.message)

      return yield put(ACTIONS.putSettingsFail(response.message));
    }
    yield put(ACTIONS.getSettings());
    // yield put(currentUser())
    yield put(ACTIONS.putSettingsSuccess());
    toastr.success("Update settings Successfully!")
  } catch (error) {
    toastr.error("Internal Error!")
    yield put(ACTIONS.putSettingsFail(error));
  }
}

function* onGetAccessList({ payload }) {
  try {
    const response = yield call(API.getAccessList, payload);

    if (response.status == "failure") {
      return yield put(ACTIONS.getAccessListFail(response.message));
    }

    yield put(ACTIONS.getAccessListSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getAccessListFail(error));
  }
}

function* onGetStoreTypes({ payload }) {
  try {
    const response = yield call(API.getStoreTypes, payload);

    if (response.status == "failure") {
      return yield put(ACTIONS.getStoreTypesFail(response.message));
    }

    yield put(ACTIONS.getStoreTypesSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getStoreTypesFail(error));
  }
}

function* onGetStarted({}) {
  try {
    const response = yield call(API.getStarted);

    if (response.status == "failure") {
      return yield put(ACTIONS.getStartedFail(response.message));
    }

    yield put(ACTIONS.getStartedSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getStartedFail("Server Error"));
  }
}

function* onPostDeployContract({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postDeployContract, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postDeployContractFail(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.postDeployContractSuccess(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    yield put(ACTIONS.postDeployContractFail("Server Error"));
  }
}

function* onGetDashboard() {
  try {
    const response = yield call(API.getDashboard);
    if (response.status == "failure") {
      return yield put(ACTIONS.getDashboardFail(response.message));
    }

    yield put(ACTIONS.getDashboardSuccess(response.data));
  } catch (error) {
    console.log(error, "65456564456");
    yield put(ACTIONS.getDashboardFail(error));
  }
}


function* onRegainUserLife({ payload: { data} }) {
  try {
    const response = yield call(API.regainUserPlayChancesApi,data);
    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(ACTIONS.regainUserPlayChancesFail(response.message));
    }

    yield put(ACTIONS.regainUserPlayChancesSuccess(response.data));
    toastr.success("Play chances regain successfully!");
  } catch (error) {
    console.log(error, "error");
    toastr.error("Internal Error!")
    yield put(ACTIONS.regainUserPlayChancesFail(error));
  }
}

function* settingsSaga() {
  yield takeEvery(TYPES.GET_DASHBOARD, onGetDashboard);
  yield takeEvery(TYPES.GET_SETTINGS, onGetSettings);
  yield takeEvery(TYPES.PUT_SETTINGS, onPutSettings);
  yield takeEvery(TYPES.GET_ACCESS_LIST, onGetAccessList);
  yield takeLatest(TYPES.GET_STORE_TYPES, onGetStoreTypes);
  yield takeLatest(TYPES.GET_STARTED, onGetStarted);
  yield takeLatest(TYPES.POST_CONTRACT_DEPLOY, onPostDeployContract);
  yield takeLatest(TYPES.REGAIN_USER_PLAYCHANCES_REQUEST, onRegainUserLife)
}

export default settingsSaga;
