import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);

// Categories
export const getCategories = (data) => post(url.VIEW_CATEGORIES, data);
export const postCategory = (data) => post(url.ADD_CATEGORY, data);
export const getCategory = (id) => post(url.GET_CATEGORY + "/" + id);
export const putCategory = (data) => post(url.PUT_CATEGORY, data);
export const deleteCategory = (data) => post(url.DELETE_CATEGORY, data);

// Gallery
export const getGalerys = (data) => post(url.VIEW_GALLERY, data);
export const postGallery = (data) => post(url.ADD_GALLERY, data);
export const getGallery = (id) => post(url.GET_GALLERY + "/" + id);
export const putGallery = (data) => post(url.PUT_GALLERY, data);
export const deleteGallery = (data) => post(url.DELETE_GALLERY, data);

// Register
export const postRegisterEmail = (data) => post(url.POST_REGISTER_EMAIL, data);
export const postStoreName = (data) => post(url.POST_STORE_NAME, data);
export const postRegister = (data) => post(url.POST_REGISTER, data);

// Forgot Password
export const postForgetPwd = (data) => post(url.POST_FORGOT_PASSWORD, data);
export const postResetPwd = (data) => post(url.POST_RESET_PASSWORD, data);

// Profile
export const getCurrentUser = () => get(url.GET_CURRENT_USER);
export const putProfile = (data) => post(url.PUT_PROFILE, data);
export const putChangePassword = (data) => post(url.CHANGE_PASSWORD, data);
export const getLanguages = () => get(url.GET_LANGUAGES);
export const deleteStore = (data) => post(url.DELETE_STORE, data);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};
export const postRemoveFile = (data) => post(url.POST_REMOVE_FILE, data);

// Customers
export const getCustomers = (data) => post(url.GET_USERS, data);
export const getCustomer = (id) => get(url.VIEW_USER + "/" + id);
export const updateStoreStatus = (data) => post(url.UPDATE_STORE_STATUS, data);
export const putStoreKeys = (data) => post(url.PUT_STORE_KEYS, data);
export const postCheckMail = (query) => get(url.POST_CHECK_MAIL + query);
export const postHideThings = (data) => post(url.POST_HIDE_THINGS, data);
export const whitelistAcceptReject = (data) =>
  post(url.WHITELIST_ACCEPT_REJECT, data);

// Collections
export const getCollections = (data) => post(url.GET_COLLECTIONS, data);
export const postCollection = (data) => post(url.POST_COLLECTION, data);
export const putCollection = (data) => post(url.PUT_COLLECTION, data);
export const deleteCollection = (data) => post(url.DELETE_COLLECTION, data);
export const getCollection = (id) => get(url.GET_COLLECTION + "/" + id);
export const postDeployCollection = (data) =>
  post(url.POST_DEPLOY_COLLECTION, data);
export const postCollectionAction = (data) =>
  post(url.POST_COLLECTION_ACTION, data);
export const postLayers = (data) => post(url.POST_LAYERS, data);
export const getLayers = (data) => post(url.GET_LAYERS, data);
export const generateNft = (data) => post(url.GENERATE_NFT, data);

// Transactions
export const getTransactions = (data) => post(url.GET_TRANSACTIONS, data);
export const chargeStore = (data) => post(url.POST_STORE_CHARGE, data);
export const refundStore = (data) => post(url.POST_STORE_REFUND, data);

// Store Login
export const storeLogin = (data) => post(url.STORE_LOGIN_BY_ADMIN, data);

// Settings
export const getDashboard = () => get(url.GET_DASHBOARD);
export const getSettings = () => get(url.GET_SETTINGS);
export const putSettings = (data) => post(url.PUT_SETTINGS, data);
export const getStoreSettings = (storeType, id) =>
  get([url.API_SETTINGS, storeType, url.GET_STORE_SETTINGS, id].join("/"));
export const putStoreSettings = (storeType, data) =>
  post([url.API_SETTINGS, storeType, url.PUT_STORE_SETTINGS].join("/"), data);
export const getAccessList = () => get(url.GET_ACCESS_LIST);
export const getStoreTypes = () => get(url.GET_STORE_TYPES);
export const getStarted = () => get(url.GET_STARTED);
export const postDeployContract = (data) =>
  post(url.POST_CONTRACT_DEPLOY, data);

// Activities
export const getActivities = (data) => post(url.GET_ACTIVITIES, data);

// Items
export const getItems = (data) => post(url.GET_ITEMS, data);
export const postItem = (data) => post(url.POST_ITEM, data);
export const putItem = (data) => post(url.PUT_ITEM, data);
export const deleteItem = (data) => post(url.DELETE_ITEM, data);
export const getItem = (id) => get(url.GET_ITEM + "/" + id);
export const putItemsStatus = (data) => post(url.PUT_ITEM_STATUS, data);

// Content
export const getCONTENTs = (data) => post(url.GET_CONTENTS, data);
export const postCONTENT = (data) => post(url.ADD_CONTENT, data);
export const getCONTENT = (id) => get(url.GET_CONTENT + "/" + id);
export const putCONTENT = (data) => post(url.PUT_CONTENT, data);
export const deleteCONTENT = (data) => post(url.DELETE_CONTENT, data);
export const putCONTENTsStatus = (data) => post(url.PUT_CONTENTS_STATUS, data);

// Faqs
export const getFaqs = (data) => post(url.GET_FAQS, data);
export const postFaq = (data) => post(url.ADD_FAQ, data);
export const getFaq = (id) => get(url.GET_FAQ + "/" + id);
export const putFaq = (data) => post(url.PUT_FAQ, data);
export const deleteFaq = (data) => post(url.DELETE_FAQ, data);
export const putFaqsStatus = (data) => post(url.PUT_FAQS_STATUS, data);

export const postUserWhiteList = (data) => post(url.POST_USER_WHITE_LIST, data);

// Teams
export const postTeam = (data) => post(url.POST_TEAM, data);
export const getTeams = (data) => post(url.GET_TEAMS, data);
export const getTeam = (id) => get(url.GET_TEAM + "/" + id);
export const putTeam = (data) => post(url.PUT_TEAM, data);
export const deleteTeam = (data) => post(url.DELETE_TEAM, data);
export const getNftsMetadataListApi = (data) =>
  post(url.GET_NFTS_METADATA, data);
export const getNftMetadataApi = (data) => {
  console.log(
    "dadadadada",data
  )
  return get(`${url.GET_NFT_METADATA}/${data._id}${data?.isFullNfts ? "?isFullNfts=" + data?.isFullNfts : ""}`);
}
export const refreshNftsMetadataApi = (data) =>
  post(url.REFRESH_NFTS_METADATA, data);
export const updateNftMetadataApi = (data) =>
  post(url.UPDATE_NFT_METADATA, data);

export const updateNftImageApi = (data) => post(url.UPDATE_NFT_IMAGE, data);

export const afterDeployCollectionUpdateApi = (data) =>
  post(url.AFTER_DEPLOY_COLLECTION_UPDATE, data);

export const regainUserPlayChancesApi = (data) =>
  post(url.REGAIN_USERS_PLAYCHANCES, data);

export const addDiscordVillainApi = (data) =>
  post(url.ADD_ALLOW_DISCORD_VILLAIN, data);

export const removeDiscordVillainApi = (data) =>
  post(url.REMOVE_ALLOW_DISCORD_VILLAIN, data);
export const postNftRrcToMainApi = (data) => post(url.NFT_COPY_RRCTOMAIN, data);
export const postMintActivity = (data) => post(url.POST_MINT_ACTIVITY, data);

export const postRevealNftsApi = (data) => post(url.REVEAL_NFTS, data);
export const downloadNftsMetadata = (data) =>
  post(url.DOWNLOAD_NFT_METADATA, data);

export const uploadMetadataUpdate = (data) => {
  const formData = new FormData();
  console.log("data", data);
  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.UPLOAD_METADATA_UPDATE, formData, config);
};

export const getContactUsListApi = (data) => post(url.GET_CONTACT_US_LIST,data)
export const postArmorsItemsActivity = (data)=> post(url.POST_ARMORS_ACTIVITY, data);

export const postEquipmentActivity = (data)=> post(url.ADD_EQUIP_ACTIVITY, data);
export const getEquipmentActivities = (data)=> post(url.GET_EQUIP_ACTIVITIES, data);
export const removeEquipmentActivity =(data) => post(url.REMOVE_EQUIP_ACTIVITY,data); 