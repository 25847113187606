import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";

import { Row, Col, FormGroup,Form, Label, Input,Card,CardBody,Container, CardHeader,Button, Spinner, Badge } from "reactstrap";
import Web3InteractionEth, { convertFromWei } from "util/web3InteractionEth";

// Actions
import { getCollection,getSettings,postNftRrcToMainBucketRequest,postMintActivity } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletHooks } from "util/walletHooks";

const OriginAirdrop = ({}) => {
    const wallet = walletHooks();
    const dispatch = useDispatch();
   const {loadingCollection,loadingSettings,settings,activityLoader} = useSelector(({Settings, collections,items})=>({
      loadingSettings : Settings.loading,
      settings: Settings.settings,
      loadingCollection : collections.loading,
      activityLoader : items.loading
   }));

   const [walletAddress,setWalletAddress] = useState("");
  const [walletAddresses,setWalletAddresses] = useState([]);

  const [loaders,setLoaders] = useState({
    originAirdrop : false
  });
  const [rroCollection,setRroCollection] = useState(null);

  useEffect(() => {
     dispatch(getSettings());
  }, []);

  useEffect(()=>{
      if(JSON.stringify(settings !== '{}')){
        dispatch(getCollection(settings.activeOriginsClaimCollectionId,(data)=>{
            console.log("origins collection",data)
            setRroCollection(data);
        }));
      }
  },[JSON.stringify(settings)]);


  const onHandleChange = (e) =>{
      const {value} = e.target;
     setWalletAddress(value);
  }

  const onAddWallet = () =>{
    if(!walletAddress){
     return toastr.error("Wallet address is required!")
    }
    const copy = [...walletAddresses];
    if(walletAddresses.includes(walletAddress)){
      return toastr.error("Wallet address already added!")
    }
    copy.push(walletAddress)
      setWalletAddresses(copy);
      setWalletAddress("");
  }

  const onRemoveWallet = (index) =>{
     setWalletAddresses((wallets)=>wallets.filter((_,idx)=> index !== idx));
  }

  const onClickAirdrop = async() =>{
    try{
      
        if(walletAddresses < 1){
            return  toastr.error("wallet address is required!");
        }
        if(!rroCollection?._id){
          return toastr.error("collection not load or found!");
        }
    
        const web3InstanceEth = new Web3InteractionEth(
            rroCollection.blockchain,
            window.ethereum,
            settings
          );
          await web3InstanceEth.switchChain();
          const contract = await web3InstanceEth.getContract(
            rroCollection.contractAbi,
            rroCollection.contractAddress
          );

         if (!contract) {
            // setloading(false);
            return toastr.error("Service not provided!");
          }
        
          console.log("contract",contract)

    
          const result = await contract.callStatic["multiAirdropNFT"](walletAddresses,{
            gasLimit : 3000000
          });

          console.log("Successfully chek : ",result);
    
          setLoaders((prev)=>({...prev,originAirdrop:true}))
          

          const transaction = await contract.multiAirdropNFT(walletAddresses,{
            gasLimit : 3000000
          });
            const tx = await transaction.wait();
            console.log("tx", tx);
           const  txHash = tx.transactionHash;
          
           setLoaders((prev)=>({...prev,originAirdrop:false}))            
         
         //save item and history
        if(txHash){
          toastr.success("Nft's minted successfully!")

          const payload = await Promise.all(tx.logs.map(async (log) => {
            const rawAddress = log.topics[2]; // Assuming there's only one address in the logs
            // const mintedTokenId = convertHexToNumber(log.topics[3]);
            const formattedAddress = '0x' + rawAddress.slice(26);
            console.log("wallet address for transaction to", formattedAddress)
            let _tokens = await contract
            .walletOfOwner(formattedAddress);
             _tokens =  _tokens?.map(token => token?.toNumber());

             console.log("v", _tokens);
         //save item and history
        // const recentMintedTokens = [mintedTokenId];
        const recentMintedTokens = _tokens.slice(-1);
              return {
                type: rroCollection.blockchain,
                collection_id: rroCollection._id,
                transactionHash: txHash,
                price: rroCollection?.cost || 0,
                quantity: 1,
                walletAddress: formattedAddress,
                mint_type: "claim-origin",
                token_ids: recentMintedTokens,
                isAirDropped : true
              }
          }));
          console.log("payload for save activity",payload);
   
          dispatch(
            postMintActivity({
              type : rroCollection.blockchain,
              activities_type :"multiple",
              activities :  payload
            },(res)=>{
                 console.log("response activity",res)
                 toastr.success("Data successfully saved in our db");
                 setWalletAddress(null);
                 setWalletAddresses([]);
            }));

        const allTokenIds = payload.reduce((accumulator, currentObject) => {
          // Concatenate the current object's token_ids to the accumulator array
          accumulator.push(...currentObject.token_ids);
          return accumulator;
        }, []);
        // Remove duplicates using Set and convert back to array
        const uniqueTokenIds = [...new Set(allTokenIds)];
        // //reveal nfts
        dispatch(postNftRrcToMainBucketRequest({tokens : uniqueTokenIds, type : "claim-origin"},(response)=>{
          console.log("response after tokens move",response);
        }));
        }
    }catch(error){
      console.log("Error",error);
      setLoaders((prev)=>({...prev,originAirdrop:false}))
      toastr.error(error?.message || "Something went wrong!");
    }
    
  }


  return (
 
            <Card>
                <CardHeader className="bg-primary text-white">
                    Robotic Rabbit Origin Airdrop 
                </CardHeader>
              <CardBody className="spinner-content">
                   <Form>
                          <Row>
                           <Col md={6}>
                                <FormGroup>
                                    <Label>Wallet Address</Label>

                                    <Input
                                        type="string"
                                        placeholder="Please enter wallet address"
                                        name="walletAddress"
                                        value={walletAddress}
                                        onChange={onHandleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                 <Button className="mt-4" onClick={onAddWallet} type="button" color="primary">
                                    Add
                                 </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                            {walletAddresses.map((wallet,index)=>(
                            <Row>
                            <Col md={6}>
                                <Badge className="p-1" color="primary">{wallet}</Badge>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                                 <Button onClick={()=>onRemoveWallet(index)} type="button" color="primary">
                                    Remove
                                 </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                            ))}
                            <Row>
                            <Col md={12}>
                            <FormGroup>
                                <Button disabled={loaders.originAirdrop || activityLoader} onClick={()=>onClickAirdrop()} type="button" color="primary">
                                {(loaders.originAirdrop  || activityLoader) && <Spinner size={"sm"} color="light"/> } Airdrop
                                </Button>
                                </FormGroup>
                            </Col>
                            </Row>
                   </Form>
              </CardBody>
            </Card>
  );
};

export default withRouter(
  OriginAirdrop
);
