export const FAQ_API_FAIL = "FAQ_API_FAIL"

// Faqs
export const GET_FAQS = "GET_FAQS"
export const GET_FAQS_FAIL = "GET_FAQS_FAIL"
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS"

// Add faq
export const ADD_FAQ = "ADD_FAQ"
export const ADD_FAQ_FAIL = "ADD_FAQ_FAIL"
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS"

// Get faq
export const GET_FAQ = "GET_FAQ"
export const GET_FAQ_FAIL = "GET_FAQ_FAIL"
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS"

// update faq
export const PUT_FAQ = "PUT_FAQ"
export const PUT_FAQ_FAIL = "PUT_FAQ_FAIL"
export const PUT_FAQ_SUCCESS = "PUT_FAQ_SUCCESS"

// Delete faq
export const DELETE_FAQ = "DELETE_FAQ"
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL"
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS"

// Update Multi Faq Status
export const PUT_FAQS_STATUS = "PUT_FAQS_STATUS"
export const PUT_FAQS_STATUS_FAIL = "PUT_FAQS_STATUS_FAIL"
export const PUT_FAQS_STATUS_SUCCESS = "PUT_FAQS_STATUS_SUCCESS"
