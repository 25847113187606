import {
  CONTENT_API_FAIL,
  GET_CONTENTS,
  GET_CONTENTS_FAIL,
  GET_CONTENTS_SUCCESS,
  ADD_CONTENT,
  ADD_CONTENT_FAIL,
  ADD_CONTENT_SUCCESS,
  GET_CONTENT,
  GET_CONTENT_FAIL,
  GET_CONTENT_SUCCESS,
  PUT_CONTENT,
  PUT_CONTENT_FAIL,
  PUT_CONTENT_SUCCESS,
  DELETE_CONTENT,
  DELETE_CONTENT_FAIL,
  DELETE_CONTENT_SUCCESS,
  PUT_CONTENTS_STATUS,
  PUT_CONTENTS_STATUS_FAIL,
  PUT_CONTENTS_STATUS_SUCCESS,
} from "./actionTypes"

export const apiFail = error => ({
  type: CONTENT_API_FAIL,
  payload: error,
})

/* Get CONTENTs */
export const getCONTENTs = data => ({
  type: GET_CONTENTS,
  payload: data,
})

export const getCONTENTsSuccess = CONTENTs => ({
  type: GET_CONTENTS_SUCCESS,
  payload: CONTENTs,
})

export const getCONTENTsFail = error => ({
  type: GET_CONTENTS_FAIL,
  payload: error,
})
/* Get CONTENTs END */

/* Add CONTENT */
export const addCONTENT = (CONTENT, history) => ({
  type: ADD_CONTENT,
  payload: { CONTENT, history },
})

export const addCONTENTFail = error => ({
  type: ADD_CONTENT_FAIL,
  payload: error,
})

export const addCONTENTSuccess = () => ({
  type: ADD_CONTENT_SUCCESS,
})
/* Add CONTENT END */

/* Get CONTENT */
export const getCONTENT = id => ({
  type: GET_CONTENT,
  payload: id,
})

export const getCONTENTFail = error => ({
  type: GET_CONTENT_FAIL,
  payload: error,
})

export const getCONTENTSuccess = CONTENT => ({
  type: GET_CONTENT_SUCCESS,
  payload: CONTENT,
})
/* Get CONTENT END */

/* Update CONTENT */
export const putCONTENT = (data, history) => ({
  type: PUT_CONTENT,
  payload: { data, history },
})

export const putCONTENTFail = error => ({
  type: PUT_CONTENT_FAIL,
  payload: error,
})

export const putCONTENTSuccess = () => ({
  type: PUT_CONTENT_SUCCESS,
})
/* Update CONTENT END */

/* Delete CONTENT */
export const deleteCONTENT = (data, callback) => ({
  type: DELETE_CONTENT,
  payload: { data, callback },
})

export const deleteCONTENTFail = error => ({
  type: DELETE_CONTENT_FAIL,
  payload: error,
})

export const deleteCONTENTSuccess = () => ({
  type: DELETE_CONTENT_SUCCESS,
})
/* Delete Faz END */

/* Update Multi CONTENTs Status */
export const putCONTENTsStatus = (data, callback) => ({
  type: PUT_CONTENTS_STATUS,
  payload: { data, callback },
})

export const putCONTENTsStatusFail = error => ({
  type: PUT_CONTENTS_STATUS_FAIL,
  payload: error,
})

export const putCONTENTsStatusSuccess = () => ({
  type: PUT_CONTENTS_STATUS_SUCCESS,
})
/* Update Multi CONTENTs Status END */
