// Get Settings
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL";

// Put Settings
export const PUT_SETTINGS = "PUT_SETTINGS";
export const PUT_SETTINGS_SUCCESS = "PUT_SETTINGS_SUCCESS";
export const PUT_SETTINGS_FAIL = "PUT_SETTINGS_FAIL";

// Access List
export const GET_ACCESS_LIST = "GET_ACCESS_LIST";
export const GET_ACCESS_LIST_SUCCESS = "GET_ACCESS_LIST_SUCCESS";
export const GET_ACCESS_LIST_FAIL = "GET_ACCESS_LIST_FAIL";

// Store Types
export const GET_STORE_TYPES = "GET_STORE_TYPES";
export const GET_STORE_TYPES_SUCCESS = "GET_STORE_TYPES_SUCCESS";
export const GET_STORE_TYPES_FAIL = "GET_STORE_TYPES_FAIL";

// Get Started
export const GET_STARTED = "GET_STARTED";
export const GET_STARTED_SUCCESS = "GET_STARTED_SUCCESS";
export const GET_STARTED_FAIL = "GET_STARTED_FAIL";

// Deploy Contract
export const POST_CONTRACT_DEPLOY = "POST_CONTRACT_DEPLOY";
export const POST_CONTRACT_DEPLOY_SUCCESS = "POST_CONTRACT_DEPLOY_SUCCESS";
export const POST_CONTRACT_DEPLOY_FAIL = "POST_CONTRACT_DEPLOY_FAIL";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";


export const REGAIN_USER_PLAYCHANCES_REQUEST = "REGAIN_USER_PLAYCHANCES_REQUEST";
export const REGAIN_USER_PLAYCHANCES_SUCCESS = "REGAIN_USER_PLAYCHANCES_SUCCESS";
export const REGAIN_USER_PLAYCHANCES_FAIL = "REGAIN_USER_PLAYCHANCES_FAIL";