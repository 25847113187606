import {
  GET_ITEMS,
  GET_ITEMS_FAIL,
  GET_ITEMS_SUCCESS,
  ADD_ITEM,
  ADD_ITEM_FAIL,
  ADD_ITEM_SUCCESS,
  GET_ITEM,
  GET_ITEM_FAIL,
  GET_ITEM_SUCCESS,
  PUT_ITEM,
  PUT_ITEM_FAIL,
  PUT_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  PUT_ITEMS_STATUS,
  PUT_ITEMS_STATUS_FAIL,
  PUT_ITEMS_STATUS_SUCCESS,
  ITEM_API_FAIL,
  GET_NFTS_METADATA_REQUEST,
  GET_NFTS_METADATA_SUCCESS,
  GET_NFTS_METADATA_FAIL,
  GET_NFT_METADATA_REQUEST,
  GET_NFT_METADATA_SUCCESS,
  GET_NFT_METADATA_FAIL,
  REFRESH_NFTS_METADATA_REQUEST,
  REFRESH_NFTS_METADATA_SUCCESS,
  REFRESH_NFTS_METADATA_FAIL,
  UPDATE_NFT_METADATA_REQUEST,
  UPDATE_NFT_METADATA_SUCCESS,
  UPDATE_NFT_METADATA_FAIL,
  UPDATE_NFT_IMAGE_REQUEST,
  UPDATE_NFT_IMAGE_SUCCESS,
  UPDATE_NFT_IMAGE_FAIL,
  NFTS_RRC_TO_MAINBUCKET_REQUEST,
  NFTS_RRC_TO_MAINBUCKET_SUCCESS,
  NFTS_RRC_TO_MAINBUCKET_FAIL,
  POST_MINT_ACTIVITY,
  POST_MINT_ACTIVITY_SUCCESS,
  POST_MINT_ACTIVITY_FAIL,
  UPLOAD_METADATA_UPDATE_REQUEST,
  UPLOAD_METADATA_UPDATE_SUCCESS,
  UPLOAD_METADATA_UPDATE_FAIL,
  REVEAL_NFTS_REQUEST,
  REVEAL_NFTS_SUCCESS,
  REVEAL_NFTS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  items: [],
  totalItems: 0,
  error: "",
  loading: false,
  item: {},
  nftsMetadataList: [],
  totalNftsMetadata: 0,
  nftMetadata: null,
  refreshNftsMetaLoading: false,
  updateNftMetaLoading: false,
  updateNftImageLoading: false,
  currentMintTokens: [],
  uploadMetadataLoading: false,
  revealNftLoading: false,
};

const Items = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Items */
    case GET_ITEMS:
      return {
        ...state,
        loading: true,
      };

    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case GET_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Items END */

    /* Add Item */
    case ADD_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case ADD_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Item END */

    /* Get Item */
    case GET_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
        item: {},
      };

    case GET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      };

    case GET_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload,
      };
    /* Add Item END */

    /* Update Item */
    case PUT_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Item END */

    /* Delete Item */
    case DELETE_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case DELETE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Item END */

    /* Update Items Status */
    case PUT_ITEMS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case PUT_ITEMS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case PUT_ITEMS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Items Status END */

    case ITEM_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    //GET Nfts metadata
    case GET_NFTS_METADATA_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case GET_NFTS_METADATA_SUCCESS:
      return {
        ...state,
        nftsMetadataList: action.payload.data,
        totalNftsMetadata: action.payload.totalCount,
        loading: false,
      };

    case GET_NFTS_METADATA_FAIL:
      return {
        ...state,
        error: "",
        loading: false,
      };

    case GET_NFT_METADATA_REQUEST:
      return {
        ...state,
        error: "",
        loading: true,
        nftMetadata: null,
      };

    case GET_NFT_METADATA_SUCCESS:
      return {
        ...state,
        nftMetadata: action.payload.data,
        loading: false,
      };

    case GET_NFT_METADATA_FAIL:
      return {
        ...state,
        error: "",
        loading: false,
        nftMetadata: null,
      };

    case REFRESH_NFTS_METADATA_REQUEST:
      return {
        ...state,
        error: "",
        refreshNftsMetaLoading: true,
      };

    case REFRESH_NFTS_METADATA_SUCCESS:
      return {
        ...state,
        refreshNftsMetaLoading: false,
      };

    case REFRESH_NFTS_METADATA_FAIL:
      return {
        ...state,
        error: "",
        refreshNftsMetaLoading: false,
      };
    case UPDATE_NFT_METADATA_REQUEST:
      return {
        ...state,
        error: "",
        updateNftMetaLoading: true,
      };

    case UPDATE_NFT_METADATA_SUCCESS:
      return {
        ...state,
        updateNftMetaLoading: false,
      };

    case UPDATE_NFT_METADATA_FAIL:
      return {
        ...state,
        error: "",
        updateNftMetaLoading: false,
      };

    case UPDATE_NFT_IMAGE_REQUEST:
      return {
        ...state,
        error: "",
        updateNftImageLoading: true,
      };

    case UPDATE_NFT_IMAGE_SUCCESS:
      return {
        ...state,
        updateNftImageLoading: false,
      };

    case UPDATE_NFT_IMAGE_FAIL:
      return {
        ...state,
        error: "",
        updateNftImageLoading: false,
      };

    case NFTS_RRC_TO_MAINBUCKET_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case NFTS_RRC_TO_MAINBUCKET_SUCCESS:
      return {
        ...state,
        loading: false,
        currentMintTokens: action.payload.data,
      };

    case NFTS_RRC_TO_MAINBUCKET_FAIL:
      return {
        ...state,
        loading: false,
      };
    /* Post Mint Activity */
    case POST_MINT_ACTIVITY:
      return {
        ...state,
        loading: true,
      };

    case POST_MINT_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case POST_MINT_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
      };

    /*Upload metadata update */
    case UPLOAD_METADATA_UPDATE_REQUEST:
      return {
        ...state,
        uploadMetadataLoading: true,
      };

    case UPLOAD_METADATA_UPDATE_SUCCESS:
      return {
        ...state,
        uploadMetadataLoading: false,
      };

    case UPLOAD_METADATA_UPDATE_FAIL:
      return {
        ...state,
        uploadMetadataLoading: false,
      };

    //Reveal Nfts
    case REVEAL_NFTS_REQUEST:
      return {
        ...state,
        revealNftLoading: true,
      };

    case REVEAL_NFTS_SUCCESS:
      return {
        ...state,
        revealNftLoading: false,
      };

    case REVEAL_NFTS_FAIL:
      return {
        ...state,
        revealNftLoading: false,
      };

    default:
      return state;
  }
};

export default Items;
