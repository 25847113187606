import { SHOW_ALERT, HIDE_ALERT } from "./actionTypes"

const INIT_STATE = {
  alert: false,
}

const Alert = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        alert: true,
      }

    case HIDE_ALERT:
      return {
        ...state,
        alert: false,
      }

    default:
      return state
  }
}

export default Alert
