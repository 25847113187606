export const API_FAIL = "API_FAIL"
export const CUSTOMER_LOADING = "CUSTOMER_LOADING"

// Customers
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"

// Get customer
export const GET_CUSTOMER = "GET_CUSTOMER"
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL"
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS"

export const GET_ALL_PLANS = "GET_ALL_PLANS"
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS"
export const GET_ALL_PLANS_FAIL = "GET_ALL_PLANS_FAIL"

export const UPDATE_STORE_STATUS = "UPDATE_STORE_STATUS"
export const UPDATE_STORE_STATUS_SUCCESS = "UPDATE_STORE_STATUS_SUCCESS"
export const UPDATE_STORE_STATUS_FAIL = "UPDATE_STORE_STATUS_FAIL"

export const UPDATE_STORE_PLAN = "UPDATE_STORE_PLAN"
export const UPDATE_STORE_PLAN_SUCCESS = "UPDATE_STORE_PLAN_SUCCESS"
export const UPDATE_STORE_PLAN_FAIL = "UPDATE_STORE_PLAN_FAIL"

export const GET_STORE_TRANSACTIONS = "GET_STORE_TRANSACTIONS"
export const GET_STORE_TRANSACTIONS_SUCCESS = "GET_STORE_TRANSACTIONS_SUCCESS"
export const GET_STORE_TRANSACTIONS_FAIL = "GET_STORE_TRANSACTIONS_FAIL"

export const POST_STORE_CHARGE = "POST_STORE_CHARGE"
export const POST_STORE_CHARGE_SUCCESS = "POST_STORE_CHARGE_SUCCESS"
export const POST_STORE_CHARGE_FAIL = "POST_STORE_CHARGE_FAIL"

export const POST_STORE_REFUND = "POST_STORE_REFUND"
export const POST_STORE_REFUND_SUCCESS = "POST_STORE_REFUND_SUCCESS"
export const POST_STORE_REFUND_FAIL = "POST_STORE_REFUND_FAIL"

export const STORE_USER_LOGIN = "STORE_USER_LOGIN"
export const STORE_USER_LOGIN_SUCCESS = "STORE_USER_LOGIN_SUCCESS"
export const STORE_USER_LOGIN_FAIL = "STORE_USER_LOGIN_FAIL"

export const PUT_STORE_PLAN_DISCOUNT = "PUT_STORE_PLAN_DISCOUNT"
export const PUT_STORE_PLAN_DISCOUNT_SUCCESS = "PUT_STORE_PLAN_DISCOUNT_SUCCESS"
export const PUT_STORE_PLAN_DISCOUNT_FAIL = "PUT_STORE_PLAN_DISCOUNT_FAIL"
export const DELETE_STORE = "DELETE_STORE"
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS"
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL"

export const PUT_STORE_KEYS = "PUT_STORE_KEYS"
export const PUT_STORE_KEYS_FAIL = "PUT_STORE_KEYS_FAIL"
export const PUT_STORE_KEYS_SUCCESS = "PUT_STORE_KEYS_SUCCESS"

export const POST_CHECK_MAIL = "POST_CHECK_MAIL"
export const POST_CHECK_MAIL_FAIL = "POST_CHECK_MAIL_FAIL"
export const POST_CHECK_MAIL_SUCCESS = "POST_CHECK_MAIL_SUCCESS"

export const POST_HIDE_THINGS = "POST_HIDE_THINGS"
export const POST_HIDE_THINGS_FAIL = "POST_HIDE_THINGS_FAIL"
export const POST_HIDE_THINGS_SUCCESS = "POST_HIDE_THINGS_SUCCESS"


export const WHITELIST_ACCEPT_REJECT = "WHITELIST_ACCEPT_REJECT"
export const WHITELIST_ACCEPT_REJECT_FAIL = "WHITELIST_ACCEPT_REJECT_FAIL"
export const WHITELIST_ACCEPT_REJECT_SUCCESS = "WHITELIST_ACCEPT_REJECT_SUCCESS"

//CONTACT US
export const GET_CONTACT_US_LIST_REQUEST = "GET_CONTACT_US_LIST_REQUEST"
export const GET_CONTACT_US_LIST_SUCCESS = "GET_CONTACT_US_LIST_SUCCESS"
export const GET_CONTACT_US_LIST_FAIL = "GET_CONTACT_US_LIST_FAIL"
