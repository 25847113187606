export const GALLERY_API_FAIL = "GALLERY_API_FAIL";

// GALLERYs
export const GET_GALLERYS = "GET_GALLERYS";
export const GET_GALLERYS_FAIL = "GET_GALLERYS_FAIL";
export const GET_GALLERYS_SUCCESS = "GET_GALLERYS_SUCCESS";

// Add GALLERY
export const ADD_GALLERY = "ADD_GALLERY";
export const ADD_GALLERY_FAIL = "ADD_GALLERY_FAIL";
export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS";

// Get GALLERY
export const GET_GALLERY = "GET_GALLERY";
export const GET_GALLERY_FAIL = "GET_GALLERY_FAIL";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";

// update GALLERY
export const PUT_GALLERY = "PUT_GALLERY";
export const PUT_GALLERY_FAIL = "PUT_GALLERY_FAIL";
export const PUT_GALLERY_SUCCESS = "PUT_GALLERY_SUCCESS";

// Delete GALLERY
export const DELETE_GALLERY = "DELETE_GALLERY";
export const DELETE_GALLERY_FAIL = "DELETE_GALLERY_FAIL";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";

// Update Multi GALLERY Status
export const PUT_GALLERYS_STATUS = "PUT_GALLERYS_STATUS";
export const PUT_GALLERYS_STATUS_FAIL = "PUT_GALLERYS_STATUS_FAIL";
export const PUT_GALLERYS_STATUS_SUCCESS = "PUT_GALLERYS_STATUS_SUCCESS";
