import * as TYPES from "./actionTypes";
const SETTINGS = {};

const INIT_STATE = {
  loading: false,
  settings: SETTINGS,
  starterLoading: false,
  dashboard: {},
  error: "",
  loadingRegainLife : false
};

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Global Settings */
    case TYPES.GET_SETTINGS:
      return {
        ...state,
        loading: true,
        storeLoading: true,
        isStoreFailed: false,
        error: "",
      };

    case TYPES.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        settings: action.payload,
        isStoreFailed: false,
        storeLoading: false,
      };

    case TYPES.GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        storeLoading: false,
        isStoreFailed: true,
      };
    /* Get Global Settings END */

    /* Update Global Settings */
    case TYPES.PUT_SETTINGS:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.PUT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case TYPES.PUT_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Update Global Settings END */

    /* Get Access List */
    case TYPES.GET_ACCESS_LIST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.GET_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        accessList: action.payload,
      };

    case TYPES.GET_ACCESS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Access List END */

    /* Get Store Types */
    case TYPES.GET_STORE_TYPES:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.GET_STORE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        storeTypes: action.payload,
      };

    case TYPES.GET_STORE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Store Types END */

    /* Get Store Started */
    case TYPES.GET_STARTED:
      return {
        ...state,
        starterLoading: true,
      };

    case TYPES.GET_STARTED_SUCCESS:
      return {
        ...state,
        starterLoading: false,
        getStarted: action.payload,
      };

    case TYPES.GET_STARTED_FAIL:
      return {
        ...state,
        starterLoading: false,
        error: action.payload,
        getStarted: {},
      };
    /* Get Store Started END */

    /* Post Deploy Contract */
    case TYPES.POST_CONTRACT_DEPLOY:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_CONTRACT_DEPLOY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_CONTRACT_DEPLOY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Deploy Contract END */

    case TYPES.GET_DASHBOARD:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        dashboard: action.payload,
      };

    case TYPES.GET_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

      case TYPES.REGAIN_USER_PLAYCHANCES_REQUEST:
        return {
          ...state,
          loadingRegainLife: true,
          error: "",
        };
  
      case TYPES.REGAIN_USER_PLAYCHANCES_SUCCESS:
        return {
          ...state,
          loadingRegainLife: false,
          error: "",      
        };
  
      case TYPES.REGAIN_USER_PLAYCHANCES_FAIL:
        return {
          ...state,
          error: action.payload,
          loadingRegainLife: false,
        };

    default:
      return state;
  }
};

export default Settings;
