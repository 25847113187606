import { call, put, takeEvery } from "redux-saga/effects";
import toastr from "toastr";

// Collection Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

import * as API from "helpers/backend_helper";

function* fetchCollections({ payload }) {
  try {
    const response = yield call(API.getCollections, payload);

    if (response.status == "failure") {
      return yield put(ACTIONS.getCollectionsFail(response.message));
    }

    yield put(ACTIONS.getCollectionsSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getCollectionsFail(error));
  }
}

function* onAddNewCollection({ payload: { collection, history } }) {
  try {
    const response = yield call(API.postCollection, collection);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(ACTIONS.addCollectionFail(response.message));
    }

    history && history.replace("/collections");

    yield put(ACTIONS.addCollectionSuccess());
  } catch (error) {
    yield put(ACTIONS.addCollectionFail("Internal Error!"));
  }
}

function* fetchCollection({ payload,callback }) {
  try {
    const response = yield call(API.getCollection, payload);
    if (response.status == "failure") {
      return yield put(ACTIONS.getCollectionFail(response.message));
    }
    yield put(ACTIONS.getCollectionSuccess(response.data));
    callback && callback(response.data);
  } catch (error) {
    yield put(ACTIONS.getCollectionFail(error));
  }
}

function* onPutCollection({ payload: { data, history } }) {
  try {
    const response = yield call(API.putCollection, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.putCollectionFail(response.message));
    }

    history && history.goBack();

    yield put(ACTIONS.putCollectionSuccess());
  } catch (error) {
    yield put(ACTIONS.putCollectionFail("Internal Error!"));
  }
}

function* onDeleteCollection({ payload: { data, callback } }) {
  try {
    const response = yield call(API.deleteCollection, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.deleteCollectionFail(response.message));
    }

    yield put(ACTIONS.deleteCollectionSuccess());
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.deleteCollectionFail("Internal Error!"));
  }
}

function* postLayers({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postLayers, data);

    if (response.status === "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.postLayersFail(response.message));
    }

    callback && callback(resposne);
    yield put(ACTIONS.postLayersSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(ACTIONS.postLayersFail(""));
  }
}

function* getLayers({ payload }) {
  try {
    const response = yield call(API.getLayers, payload);

    if (response.status === "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.getLayersFail(response.message));
    }

    yield put(ACTIONS.getLayersSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(ACTIONS.getLayersFail(""));
  }
}

function* generateNft({ payload: { data, callback } }) {
  try {
    const response = yield call(API.generateNft, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.generateNftFail(response.message));
    }

    yield put(ACTIONS.generateNftSuccess(response.data));
    yield put(ACTIONS.getCollection(data?.collectionId));
    callback && callback(response);
  } catch (error) {
    callback && callback(error);
  }
}

function* onPostDeployCollection({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postDeployCollection, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postDeployCollectionFail(response.message));
    }

    yield put(ACTIONS.postDeployCollectionSuccess());
    callback && callback(response);
  } catch (error) {
    yield put(ACTIONS.postDeployCollectionFail("Internal Error!"));
  }
}

function* onPostCollectionAction({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postCollectionAction, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.postCollectionActionFail(response.message));
    }

    yield put(ACTIONS.getCollection(data.itemId));
    yield put(ACTIONS.postCollectionActionSuccess());
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.postCollectionActionFail("Internal Error!"));
  }
}


function* afterDeplyCollectionUpdateReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.afterDeployCollectionUpdateApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.afterDeployCollectionUpdateFail(response.message));
    }
    yield put(ACTIONS.afterDeployCollectionUpdateSuccess(response));
    callback && callback(response);
    toastr.success("Collection Deployed Successfully!")
  } catch (error) {
    toastr.error("Internal Error!");
    yield put(ACTIONS.afterDeployCollectionUpdateFail("Internal Error!"));
  }
}



function* addDiscordVillainReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.addDiscordVillainApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.addDiscordVillainFail(response.message));
    }
    yield put(ACTIONS.addDiscordVillainSuccess(response));
    callback && callback(response);
    toastr.success("Discord ids added in our database successfully!")
  } catch (error) {
    toastr.error("Internal Error!");
    yield put(ACTIONS.addDiscordVillainFail("Internal Error!"));
  }
}

function* removeDiscordVillainReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.removeDiscordVillainApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.removeDiscordVillainFail(response.message));
    }
    yield put(ACTIONS.removeDiscordVillainSuccess(response));
    callback && callback(response);
    toastr.success("Discord id removed successfully!")
  } catch (error) {
    toastr.error("Internal Error!");
    yield put(ACTIONS.removeDiscordVillainFail("Internal Error!"));
  }
}


function* CollectionsSaga() {
  yield takeEvery(TYPES.GET_COLLECTIONS, fetchCollections);
  yield takeEvery(TYPES.ADD_COLLECTION, onAddNewCollection);
  yield takeEvery(TYPES.GET_COLLECTION, fetchCollection);
  yield takeEvery(TYPES.PUT_COLLECTION, onPutCollection);
  yield takeEvery(TYPES.DELETE_COLLECTION, onDeleteCollection);
  yield takeEvery(TYPES.POST_LAYERS, postLayers);
  yield takeEvery(TYPES.GET_LAYERS, getLayers);
  yield takeEvery(TYPES.GENERATE_NFT, generateNft);
  yield takeEvery(TYPES.POST_DEPLOY_COLLECTION, onPostDeployCollection);
  yield takeEvery(TYPES.POST_COLLECTION_ACTION, onPostCollectionAction);
  yield takeEvery(TYPES.AFTER_DEPLOY_COLLECTION_UPDATE_REQUEST, afterDeplyCollectionUpdateReq);
  yield takeEvery(TYPES.ADD_DISCORD_VILLAIN_REQUEST, addDiscordVillainReq);
  yield takeEvery(TYPES.REMOVE_DISCORD_VILLAIN_REQUEST, removeDiscordVillainReq);
}

export default CollectionsSaga;
