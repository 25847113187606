import {
  CONTENT_API_FAIL,
  GET_CONTENTS,
  GET_CONTENTS_FAIL,
  GET_CONTENTS_SUCCESS,
  ADD_CONTENT,
  ADD_CONTENT_FAIL,
  ADD_CONTENT_SUCCESS,
  GET_CONTENT,
  GET_CONTENT_FAIL,
  GET_CONTENT_SUCCESS,
  PUT_CONTENT,
  PUT_CONTENT_FAIL,
  PUT_CONTENT_SUCCESS,
  DELETE_CONTENT,
  DELETE_CONTENT_FAIL,
  DELETE_CONTENT_SUCCESS,
  PUT_CONTENTS_STATUS,
  PUT_CONTENTS_STATUS_FAIL,
  PUT_CONTENTS_STATUS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  CONTENTs: [],
  totalCONTENTs: 0,
  error: "",
  loading: false,
  CONTENT: {},
  CONTENTBYID: {},
}

const CONTENTs = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get CONTENTs */
    case GET_CONTENTS:
      return {
        ...state,
        loading: true,
      }

    case GET_CONTENTS_SUCCESS:
      console.log("action.payload", action.payload)
      return {
        ...state,
        CONTENTs: action.payload.data || [],
        totalCONTENTs: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case GET_CONTENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get CONTENTs END */

    /* Add CONTENT */
    case ADD_CONTENT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case ADD_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_CONTENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Add CONTENT END */

    /* Get CONTENT */
    case GET_CONTENT:
      return {
        ...state,
        error: "",
        loading: true,
        CONTENT: {},
      }

    case GET_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        CONTENT: {},
      }

    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        CONTENTBYID: action.payload,
      }
    /* Add CONTENT END */

    /* Update CONTENT */
    case PUT_CONTENT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_CONTENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update CONTENT END */

    /* Delete CONTENT */
    case DELETE_CONTENT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_CONTENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Delete CONTENT END */

    /* Update CONTENTs Status */
    case PUT_CONTENTS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_CONTENTS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_CONTENTS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update CONTENTs Status END */

    case CONTENT_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default CONTENTs
