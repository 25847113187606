import React,{useEffect} from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
// import {updateNotificationReadStatusRequest} from "store/actions";

//i18n
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const NotificationModal = props => {
  const { isOpen, toggle, data,role,user } = props

//  useEffect(()=>{
//    if(role && data && user){
//     user && props.updateNotificationReadStatusRequest({
//       ids:[data?._id],
//       role:role, 
//       vendorId : role === "ADMIN" ? undefined : user?._id
//     });
//    }
//  },[data,role,user]);

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-content">
          <ModalHeader toggle={toggle}>
             Notification
          </ModalHeader>
        <ModalBody>
           {data?.content}
        </ModalBody>
      </div>
    </Modal>
  )
}


NotificationModal.propTypes = {
    role : PropTypes.string,
    user : PropTypes.object,
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
  }
  const mapStateToProps = ({  Login }) => ({
    role: Login?.user?.user?.role || "",
    user: Login?.user?.user || {},
  })
  
  const mapDispatchToProps = (dispatch) => ({
    updateNotificationReadStatusRequest:(data,callback) => dispatch(updateNotificationReadStatusRequest(data,callback))
  })
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationModal)),
  )