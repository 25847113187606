export const ITEM_API_FAIL = "ITEM_API_FAIL";

// ITEMs
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";

// Add ITEM
export const ADD_ITEM = "ADD_ITEM";
export const ADD_ITEM_FAIL = "ADD_ITEM_FAIL";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";

// Get ITEM
export const GET_ITEM = "GET_ITEM";
export const GET_ITEM_FAIL = "GET_ITEM_FAIL";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";

// update ITEM
export const PUT_ITEM = "PUT_ITEM";
export const PUT_ITEM_FAIL = "PUT_ITEM_FAIL";
export const PUT_ITEM_SUCCESS = "PUT_ITEM_SUCCESS";

// Delete ITEM
export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

// Update Multi ITEM Status
export const PUT_ITEMS_STATUS = "PUT_ITEMS_STATUS";
export const PUT_ITEMS_STATUS_FAIL = "PUT_ITEMS_STATUS_FAIL";
export const PUT_ITEMS_STATUS_SUCCESS = "PUT_ITEMS_STATUS_SUCCESS";

//GET NFTS METADATA

export const GET_NFTS_METADATA_REQUEST = "GET_NFTS_METADATA_REQUEST";
export const GET_NFTS_METADATA_SUCCESS = "GET_NFTS_METADATA_SUCCESS";
export const GET_NFTS_METADATA_FAIL = "GET_NFTS_METADATA_FAIL";

export const GET_NFT_METADATA_REQUEST = "GET_NFT_METADATA_REQUEST";
export const GET_NFT_METADATA_SUCCESS = "GET_NFT_METADATA_SUCCESS";
export const GET_NFT_METADATA_FAIL = "GET_NFT_METADATA_FAIL";

export const REFRESH_NFTS_METADATA_REQUEST = "REFRESH_NFTS_METADATA_REQUEST";
export const REFRESH_NFTS_METADATA_SUCCESS = "REFRESH_NFTS_METADATA_SUCCESS";
export const REFRESH_NFTS_METADATA_FAIL = "REFRESH_NFTS_METADATA_FAIL";

export const UPDATE_NFT_METADATA_REQUEST = "UPDATE_NFT_METADATA_REQUEST";
export const UPDATE_NFT_METADATA_SUCCESS = "UPDATE_NFT_METADATA_SUCCESS";
export const UPDATE_NFT_METADATA_FAIL = "UPDATE_NFT_METADATA_FAIL";

export const UPDATE_NFT_IMAGE_REQUEST = "UPDATE_NFT_IMAGE_REQUEST";
export const UPDATE_NFT_IMAGE_SUCCESS = "UPDATE_NFT_IMAGE_SUCCESS";
export const UPDATE_NFT_IMAGE_FAIL = "UPDATE_NFT_IMAGE_FAIL";

export const NFTS_RRC_TO_MAINBUCKET_REQUEST = "NFTS_RRC_TO_MAINBUCKET_REQUEST";
export const NFTS_RRC_TO_MAINBUCKET_SUCCESS = "NFTS_RRC_TO_MAINBUCKET_SUCCESS";
export const NFTS_RRC_TO_MAINBUCKET_FAIL = "NFTS_RRC_TO_MAINBUCKET_FAIL";

// Mint Acitvity
export const POST_MINT_ACTIVITY = "POST_MINT_ACTIVITY";
export const POST_MINT_ACTIVITY_FAIL = "POST_MINT_ACTIVITY_FAIL";
export const POST_MINT_ACTIVITY_SUCCESS = "POST_MINT_ACTIVITY_SUCCESS";

export const UPLOAD_METADATA_UPDATE_REQUEST = "UPLOAD_METADATA_UPDATE_REQUEST";
export const UPLOAD_METADATA_UPDATE_FAIL = "UPLOAD_METADATA_UPDATE_FAIL";
export const UPLOAD_METADATA_UPDATE_SUCCESS = "UPLOAD_METADATA_UPDATE_SUCCESS";

// Reveal Nft

export const REVEAL_NFTS_REQUEST = "REVEAL_NFTS_REQUEST";
export const REVEAL_NFTS_SUCCESS = "REVEAL_NFTS_SUCCESS";
export const REVEAL_NFTS_FAIL = "REVEAL_NFTS_FAIL";
