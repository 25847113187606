export const COLLECTION_API_FAIL = "COLLECTION_API_FAIL";

// COLLECTIONs
export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS_FAIL";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";

// Add COLLECTION
export const ADD_COLLECTION = "ADD_COLLECTION";
export const ADD_COLLECTION_FAIL = "ADD_COLLECTION_FAIL";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";

// Get COLLECTION
export const GET_COLLECTION = "GET_COLLECTION";
export const GET_COLLECTION_FAIL = "GET_COLLECTION_FAIL";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";

// update COLLECTION
export const PUT_COLLECTION = "PUT_COLLECTION";
export const PUT_COLLECTION_FAIL = "PUT_COLLECTION_FAIL";
export const PUT_COLLECTION_SUCCESS = "PUT_COLLECTION_SUCCESS";

// Delete COLLECTION
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const DELETE_COLLECTION_FAIL = "DELETE_COLLECTION_FAIL";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";

// Action Collection
export const POST_COLLECTION_ACTION = "POST_COLLECTION_ACTION";
export const POST_COLLECTION_ACTION_FAIL = "POST_COLLECTION_ACTION_FAIL";
export const POST_COLLECTION_ACTION_SUCCESS = "POST_COLLECTION_ACTION_SUCCESS";

// Create layers
export const POST_LAYERS = "POST_LAYERS";
export const POST_LAYERS_FAIL = "POST_LAYERS_FAIL";
export const POST_LAYERS_SUCCESS = "POST_LAYERS_SUCCESS";

// Get Layers
export const GET_LAYERS = "GET_LAYERS";
export const GET_LAYERS_FAIL = "GET_LAYERS_FAIL";
export const GET_LAYERS_SUCCESS = "GET_LAYERS_SUCCESS";

// Post Generate Layout
export const GENERATE_NFT = "GENERATE_NFT";
export const GENERATE_NFT_FAIL = "GENERATE_NFT_FAIL";
export const GENERATE_NFT_SUCCESS = "GENERATE_NFT_SUCCESS";

// Deploy Collection
export const POST_DEPLOY_COLLECTION = "POST_DEPLOY_COLLECTION";
export const POST_DEPLOY_COLLECTION_FAIL = "POST_DEPLOY_COLLECTION_FAIL";
export const POST_DEPLOY_COLLECTION_SUCCESS = "POST_DEPLOY_COLLECTION_SUCCESS";


// After Deploy Collection
export const AFTER_DEPLOY_COLLECTION_UPDATE_REQUEST = "AFTER_DEPLOY_COLLECTION_UPDATE_REQUEST";
export const AFTER_DEPLOY_COLLECTION_UPDATE_FAIL = "AFTER_DEPLOY_COLLECTION_UPDATE_FAIL";
export const AFTER_DEPLOY_COLLECTION_UPDATE_SUCCESS = "AFTER_DEPLOY_COLLECTION_UPDATE_SUCCESS";

//add dicord villain

export const ADD_DISCORD_VILLAIN_REQUEST = "ADD_DISCORD_VILLAIN_REQUEST";
export const ADD_DISCORD_VILLAIN_SUCCESS = "ADD_DISCORD_VILLAIN_SUCCESS";
export const ADD_DISCORD_VILLAIN_FAIL = "ADD_DISCORD_VILLAIN_FAIL";

export const REMOVE_DISCORD_VILLAIN_REQUEST = "REMOVE_DISCORD_VILLAIN_REQUEST";
export const REMOVE_DISCORD_VILLAIN_SUCCESS = "REMOVE_DISCORD_VILLAIN_SUCCESS";
export const REMOVE_DISCORD_VILLAIN_FAIL = "REMOVE_DISCORD_VILLAIN_FAIL";
