import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Alert,
} from "reactstrap";

// Actions
import { getSettings, putSettings } from "store/actions";

// Other Settings
// import GeneralSettings from "./General";
import HomePageSettings from "./Home";
// import FooterSettings from "./Footer";
import SocialMediaSettings from "./SocialMedia";
// import SupportSettings from "./Support";
// import Community from "./Community";
// import Story from "./Story";
// import RoadMap from "./RoadMap";
// import Battle from "./Battle";
// import Mailchimp from "./Mailchimp";
// import Banner from "./Banner";
// import Gallery from "./Gallery";
import Faq from "./Faq";
import GamePlay from "./GamePlay";
import HomeNftsSlider from "./HomeNftsSlider";
import RoadMap from "./RoadMap";

const Settings = (props) => {
  const { onGetSettings, onPutSettings, loading, error } = props;
  const [submitInfo, setSubmitInfo] = useState(null);
  const [callFun, setcallFun] = useState(45);
  const [fields, setFields] = useState({
    name: props.settings.name,
    logo: props.settings.logo,
    adminCommission: props.settings.adminCommission,
    activeCollectionId: props?.settings?.activeCollectionId,
    actionWGClaimCollectionId: props?.settings?.actionWGClaimCollectionId,
    activeVillainClaimCollectionId:
      props?.settings?.activeVillainClaimCollectionId,
    homePageBannerSetting: {
      description: "",
    },
    activeOriginsClaimCollectionId:
      props?.settings?.activeOriginsClaimCollectionId,
      activeArmorsCollectionId: props?.settings?.activeArmorsCollectionId,
    socialMediaSetting: {
      opensea: "",
      facebook: "",
      twitter: "",
      youtube: "",
      discord: "",
      vimeo: "",
      tiktok: "",
      telegram: "",
      isBackground: "",
      isBackgroundImage: "",
      playstore:"",
      amazon:""
    },
    gamePlaySettings: {
      play_session: "",
      whitelist_mint_price: 0,
      public_mint_price: 0,
      reveal_play_to_mint: false,
      direct_mint_price: 0,
    },

    // supportSetting: {
    //   email: "",
    //   mobileNumber: "",
    //   address: "",
    // },
  });

  const fetchAPi = useCallback(() => {
    onGetSettings();
    // console.log(fields, 454656454);
  }, [callFun]);

  useEffect(() => {
    fetchAPi();
  }, []);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        activeCollectionId: props?.settings?.activeCollectionId,
        actionWGClaimCollectionId: props?.settings?.actionWGClaimCollectionId,
        activeVillainClaimCollectionId:
          props?.settings?.activeVillainClaimCollectionId,
        activeOriginsClaimCollectionId:
          props?.settings?.activeOriginsClaimCollectionId,
          activeArmorsCollectionId: props?.settings?.activeArmorsCollectionId,
        homePageBannerSetting: props?.settings?.homePageBannerSetting || {
          description: "",
        },

        socialMediaSetting: props.settings.socialMediaSetting || {
          opensea: "",
          facebook: "",
          twitter: "",
          youtube: "",
          discord: "",
          vimeo: "",
          tiktok: "",
          telegram: "",
          isBackground: "",
          isBackgroundImage: "",
          playstore:"",
          amazon:""
        },

        gamePlaySettings: props.settings.gamePlaySettings || {
          play_session: "",
          whitelist_mint_price: 0,
          public_mint_price: 0,
          reveal_play_to_mint: false,
          direct_mint_price: 0,
        },
        homeNftsSlider: props.settings?.homeNftsSlider || [],
        roadMapSetting: props.settings?.roadMapSetting || [],
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleInnerChange = (name) => (name2) => (event) => {
    const { value } = event.target;
    console.log("name2", name2, "name", name);
    if (name2 == "activeCollectionId" || name2 === "gamePlaySettings") {
      setFields((prevFields) => ({ ...prevFields, [name2]: value }));
      return;
    }
    if (
      name2 == "actionWGClaimCollectionId" ||
      name2 == "activeVillainClaimCollectionId" ||
      name2 == "activeOriginsClaimCollectionId" ||
      name2 == "activeArmorsCollectionId" ) {
      setFields((prevFields) => ({ ...prevFields, [name2]: value }));
      return;
    }
    if (name === "homePageBannerSetting") {
      setFields((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], [name2]: value },
      }));
    }
    setFields((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [name2]: value },
    }));
  };

  function submit() {
    // setcallFun(callFun + 1);
    try {
      onPutSettings({
        ...fields,
        _id: props.settings?._id,
        activeCollectionId: fields.activeCollectionId,
      });
    } catch {
      setSubmitInfo("Some Error Occurred!!");
    }
  }
  console.log(fields, 9787897);
  // return null;
  return (
    <div className="page-content">
      <Container fluid>
        {/* <Breadcrumbs title="Settings" breadcrumbItem="Settings" /> */}

        <Row>
          {/* <Col sm={1} lg={2}></Col> */}

          <Col sm={10} lg={12}>
            <Card>
              <CardBody className="spinner-content">
                {error && typeof error === "string" ? (
                  <Alert color="danger">{error}</Alert>
                ) : null}
                {loading && <div className="spinner"></div>}

                <HomePageSettings
                  fields={fields}
                  // fields={fields.homePageBannerSetting}
                  handleChange={handleInnerChange("homePageBannerSetting")}
                />

                <hr className="my-3" />
                <HomeNftsSlider
                  fields={fields.homeNftsSlider}
                  setFields={setFields}
                />
                <hr className="my-3" />
                <RoadMap fields={fields.roadMapSetting} setFields={setFields} />
                <hr className="my-3" />

                <SocialMediaSettings
                  fields={fields.socialMediaSetting}
                  handleChange={handleInnerChange("socialMediaSetting")}
                />
                <hr className="my-3" />
                <GamePlay
                  fields={fields.gamePlaySettings}
                  handleChange={handleInnerChange("gamePlaySettings")}
                />
                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}

                    <Button
                      color="primary"
                      className="buttoncolor"
                      onClick={submit}
                    >
                      Save Changes
                    </Button>
                  </Col>
                </Row>
                <div className="mt-2">
                  {submitInfo && <Alert>{submitInfo}</Alert>}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col sm={1} lg={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

Settings.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onGetSettings: PropTypes.func,
  onPutSettings: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
