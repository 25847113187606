import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, Spinner, Alert, Badge } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

// Actions
import { postDeployCollection, afterDeployCollectionUpdateRequest } from "store/actions";
import Web3Intraction from "util/web3Intraction";
import Web3InteractionEth from "util/web3InteractionEth";
import { walletHooks } from "util/walletHooks";
import toastr from "toastr";

const DeployConfirmModal = (props) => {
  const { isOpen, toggle, collection,og_contract_address,settings,special_weapons_address } = props;
  const wallet = walletHooks();
  const [loading,setLoading] = useState(false);

  const downloadBlobFile = (data,filename,type) =>{
    let blob = new Blob([data], {
      type: type,
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const onConfirm = async () => {
    if(!wallet?.account){
      toastr.error("Please connect metamask!");
       return;
    }
    props.onPostDeployCollection(
      { 
         collectionId: collection._id, 
         type: collection.blockchain,
         og_contract_address,
         special_weapons_address
      },
      async (response)=>{
      try{
        setLoading(true);
        // after compile deploy from frontend
        // const web3Intraction = new Web3Intraction(
        //   collection.blockchain,
        //   settings
        // );
        // if(!response?.data?.bytecode){
        //     return toastr.error("Bytecode error while deploying!")
        // }
        // const { txHash, receipt } = await web3Intraction.deployContract(wallet?.account, response, ()=>console.log("deployed contract")) 

        const web3InstanceEth = new Web3InteractionEth(
            collection.blockchain,
            window.ethereum,
            settings
          );
          await web3InstanceEth.switchChain();
        const { txHash, receipt } = await web3InstanceEth.deployContract(response, ()=>console.log("deployed contract")) 

        setLoading(false);
        if(txHash && receipt){
          const payload  = {
              collection_id : collection._id,
              contractAddress : receipt.contractAddress,
              transactionHash : txHash
          }
          props.afterDeployCollectionUpdateRequest(payload, (res2)=>{
            downloadBlobFile(JSON.stringify(res2.data.compiledJson),`${collection.symbol}.json`,"application/json");
               props.onSuccess(res2);
          });
        }
      }catch(error){
        setLoading(false);
         console.log("Error while deploying the contract",error);
         toastr.error(error?.message || "Error while deploying the contract!");
      }
      }
    );
  };

  console.log("loading",loading);
  console.log("compile loading",props.loading)
  console.log("deployLoading",props.deployLoading)

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-content remove-confirm spinner-content">
        {loading || props.loading || props.deployLoading  ? (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        ): null}

        <ModalBody>
          {props.error && typeof props.error === "string" ? (
            <Alert color="danger">{props.error}</Alert>
          ) : null}

          <h4>{props.t("Compile & Deploy Collection")}</h4>

          <p className="text-center">
            <span className="text-dark">Contract Address: </span>
            <br />
            {collection?.contractAddress || "NA"}{" "}
            {collection?.receiptStatus === "pending" && (
              <Badge color="warning">InProcess</Badge>
            )}
          </p>

          <div className="delete-btns">
            <Button type="button" outline color="secondary" onClick={toggle}>
              {props.t("No Cancel")}
            </Button>

            <Button type="button" color="primary" onClick={onConfirm}>
              {props.t("Yes Deploy")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

DeployConfirmModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

const mapStateToProps = ({ collections,Settings }) => ({
  loading: collections.loading,
  deployLoading : collections.deployLoading,
  error: collections.error,
  settings : Settings.settings
});

const mapDispatchToProps = (dispatch) => ({
  onPostDeployCollection: (data, callback) =>
    dispatch(postDeployCollection(data, callback)),
    afterDeployCollectionUpdateRequest: (data,callback) => dispatch(afterDeployCollectionUpdateRequest(data,callback))
    
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeployConfirmModal));
