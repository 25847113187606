import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),

  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const CustomersColumns = (history, toggleConfirmModal, t, accesses) => [
  {
    dataField: "image",
    text: t("Image"),
    sort: true,
    formatter:(_,row)=> {
      if(row?.image){
        return <img width={80} height={95} src={row?.image}/>
      }
    }
  },
  {
    dataField: "name",
    text: t("Name"),
    sort: true,
    formatter:(_,row)=> row?.name
  },
  {
    text: t("Created At"),
    dataField: "date",
    sort: true,
    formatter: (_, row) =>
    row?.created_date || row?.date &&  moment(row?.date || row?.created_date).format("DD MMM YYYY hh:mm A"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link to={`/nft-metadata/${row?._id}`} className="mr-3 text-secondary">
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default CustomersColumns;
