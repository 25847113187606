import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";

import {
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  CardHeader,
  Button,
  Spinner,
} from "reactstrap";
import Instructions from "./Instructions";
import Web3InteractionEth from "util/web3InteractionEth";

// Actions
import {
  getCollection,
  getSettings,
  postNftRrcToMainBucketRequest,
  postMintActivity,
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletHooks } from "util/walletHooks";
import SyndicateMint from "./Components/SyndicateMint";
import OriginAirdrop from "./Components/OriginAirdrop";
import OriginVipAirdrop from "./Components/OriginVipAirdrop";
import SyndicateAirdrop from "./Components/SyndicateAirdrop";
import RevealNfts from "./Components/RevealNfts";
import ArmorsAirdrop from "./Components/ArmorsAirdrop";

const NftsMintTransfer = (props) => {
  const wallet = walletHooks();
  const dispatch = useDispatch();
  const {
    loadingCollection,
    loadingSettings,
    settings,
    collection,
    activityLoader,
  } = useSelector(({ Settings, collections, items }) => ({
    loadingSettings: Settings.loading,
    settings: Settings.settings,
    collection: collections.collection,
    loadingCollection: collections.loading,
    activityLoader: items.loading,
  }));

  const [fields, setFields] = useState({
    discord_user_id: "",
    transfer_from: "",
    transfer_to: "",
    token_id: "",
  });

  const [loaders, setLoaders] = useState({
    villainMint: false,
    villaimTransfer: false,
  });
  const [rrvCollection, setRrvCollection] = useState(null);

  useEffect(() => {
    if (wallet.account) {
      setFields((prev) => ({ ...prev, transfer_from: wallet.account }));
    }
  }, [wallet.account]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (JSON.stringify(settings !== "{}")) {
      dispatch(
        getCollection(settings.activeVillainClaimCollectionId, (data) => {
          setRrvCollection(data);
        })
      );
    }
  }, [JSON.stringify(settings)]);

  console.log("rrvCollection", rrvCollection);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value?.trim() }));
  };

  const onClickMintVillan = async () => {
    try {
      const { discord_user_id } = fields;
      if (!rrvCollection?._id) {
        toastr.error("Villain collection not load or found!");
      }
      if (!wallet.account) {
        return toastr.error("Please connect your wallet!");
      }

      const web3InstanceEth = new Web3InteractionEth(
        rrvCollection.blockchain,
        window.ethereum,
        settings
      );
      await web3InstanceEth.switchChain();
      const ethercontract = await web3InstanceEth.getContract(
        rrvCollection.contractAbi,
        rrvCollection.contractAddress
      );

      console.log("contract", ethercontract);
      const checkDiscordId = await ethercontract.userStatus(discord_user_id);
      console.log("checkDiscorId", checkDiscordId);

      if (checkDiscordId && checkDiscordId[0] === "") {
        return toastr.error("Discord id not allowed for mint!");
      }
      if (
        checkDiscordId &&
        checkDiscordId[0] !== "" &&
        checkDiscordId[1] === true
      ) {
        return toastr.error("This discord id already mint nft!");
      }

      const result = await ethercontract.callStatic["mintNFT"](discord_user_id);
      console.log("Successfully check : ", result);

      setLoaders((prev) => ({ ...prev, ["villainMint"]: true }));

      const transaction = await ethercontract.mintNFT(discord_user_id);

      const tx = await transaction.wait();
      console.log("tx", tx);
      const txHash = tx.transactionHash;

      setLoaders((prev) => ({ ...prev, ["villainMint"]: false }));

      if (txHash) {
        //modal open
        console.log("txHash", txHash);
        toastr.success("Nft Mint successfully!");
        const _tokens = await ethercontract.walletOfOwner(wallet.account);
        const tokensList = _tokens?.map((token) => token?.toNumber());
        console.log("tokensList", tokensList);
        if (tokensList?.length > 0) {
          console.log("_tokens", tokensList);
          const lastestToken = tokensList.slice(-1);
          console.log("lastestToken", lastestToken);
          setFields((prev) => ({ ...prev, token_id: lastestToken[0] }));
          //reveal nfts
          dispatch(
            postNftRrcToMainBucketRequest(
              { tokens: lastestToken, type: "claim-villain" },
              (response) => {
                console.log("response after tokens move", response);
              }
            )
          );
        }
      }
    } catch (error) {
      console.log("Error", error);
      setLoaders((prev) => ({ ...prev, ["villainMint"]: false }));

      toastr.error(error?.message || "Something went wrong!");
    }
  };

  const onClickTransfer = async () => {
    try {
      const { discord_user_id, token_id, transfer_from, transfer_to } = fields;
      if (!discord_user_id) {
        return toastr.error("Discord id required!");
      }
      if (!token_id) {
        return toastr.error("Token id required!");
      }
      if (!transfer_from) {
        return toastr.error("Transfer from wallet is required!");
      }
      if (!transfer_to) {
        return toastr.error("Transfer to wallet is  required!");
      }

      if (!rrvCollection?._id) {
        toastr.error("Villain collection not load or found!");
      }
      if (!wallet.account) {
        return toastr.error("Please connect your wallet!");
      }

      const web3InstanceEth = new Web3InteractionEth(
        rrvCollection.blockchain,
        window.ethereum,
        settings
      );
      const ethercontract = await web3InstanceEth.getContract(
        rrvCollection.contractAbi,
        rrvCollection.contractAddress
      );

      console.log("contract", ethercontract);
      const checkDiscordId = await ethercontract.userStatus(discord_user_id);
      console.log("checkDiscorId", checkDiscordId);

      let ownerOfNft = await ethercontract.ownerOf(token_id);
      ownerOfNft = ownerOfNft?.toLowerCase();
      console.log("checkOwner", ownerOfNft);
      if (ownerOfNft !== transfer_from?.toLowerCase()) {
        return toastr.error("Your are not owner of this nft!");
      }

      const result = await ethercontract.callStatic["transferFrom"](
        transfer_from,
        transfer_to,
        token_id
      );
      console.log("Successfully check : ", result);

      setLoaders((prev) => ({ ...prev, ["villaimTransfer"]: true }));

      const transaction = await ethercontract.transferFrom(
        transfer_from,
        transfer_to,
        token_id
      );

      const tx = await transaction.wait();
      console.log("tx", tx);
      const txHash = tx.transactionHash;

      setLoaders((prev) => ({ ...prev, ["villaimTransfer"]: false }));

      if (txHash) {
        //modal open
        console.log("txHash", txHash);
        toastr.success("Nft Transfer successfully!");

        console.log("lastestToken", token_id);

        dispatch(
          postMintActivity(
            {
              type: rrvCollection.blockchain,
              collection_id: rrvCollection._id,
              transactionHash: txHash,
              price: rrvCollection?.cost || 0,
              quantity: 1,
              walletAddress: transfer_to,
              mint_type: "claim-villain",
              token_ids: [token_id],
              discord_user_id: discord_user_id || null,
              isAirDropped: true,
            },
            () => {
              toastr.success("Data stored in our db.");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          )
        );
      }
    } catch (error) {
      setLoaders((prev) => ({ ...prev, ["villaimTransfer"]: false }));

      toastr.error(error?.message || "Something went wrong!");
    }
  };

  console.log("fields", fields);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={3}>
            <Instructions t={props.t} />
          </Col>

          <Col lg={12}>
            <Row>
              {/* <Col sm={12} lg={12}>
                <Card>
                  <CardHeader className="bg-primary text-white">
                    VILLAIN NFT MINT
                  </CardHeader>
                  <CardBody className="spinner-content">
                    <Form>
                      <FormGroup>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Discord id</Label>

                            <Input
                              type="text"
                              placeholder="Please enter user discord id"
                              name="discord_user_id"
                              value={fields.discord_user_id}
                              onChange={onHandleChange}
                              rows={4}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Button
                              disabled={loaders.villainMint}
                              onClick={onClickMintVillan}
                              type="button"
                              color="primary"
                            >
                              {loaders.villainMint && (
                                <Spinner size={"sm"} color="light" />
                              )}{" "}
                              Mint Villain
                            </Button>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col sm={12} lg={12}>
                <Card>
                  <CardHeader className="bg-primary text-white">
                    VILLAIN NFT TRANSFER
                  </CardHeader>
                  <CardBody className="spinner-content">
                    <Form>
                      <FormGroup>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Discord id</Label>

                            <Input
                              type="text"
                              placeholder="Please enter user discord id"
                              name="discord_user_id"
                              value={fields.discord_user_id}
                              onChange={onHandleChange}
                              rows={4}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Token Id</Label>

                            <Input
                              type="text"
                              placeholder="Please enter token id"
                              name="token_id"
                              value={fields.token_id}
                              onChange={onHandleChange}
                              rows={4}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Transfer From</Label>

                            <Input
                              type="text"
                              placeholder="please enter wallet address"
                              name="transfer_from"
                              value={fields.transfer_from}
                              onChange={onHandleChange}
                              rows={4}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Transfer to</Label>

                            <Input
                              type="text"
                              placeholder="please enter wallet address"
                              name="transfer_to"
                              value={fields.transfer_to}
                              onChange={onHandleChange}
                              rows={4}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Button
                              disabled={
                                loaders.villaimTransfer || activityLoader
                              }
                              onClick={onClickTransfer}
                              type="button"
                              color="primary"
                            >
                              {(loaders.villaimTransfer || activityLoader) && (
                                <Spinner size={"sm"} color="light" />
                              )}{" "}
                              Transfer
                            </Button>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col> */}
              <Col sm={12} lg={12}>
                <SyndicateMint />
              </Col>
              {/* <Col sm={12} lg={12}>
                <OriginAirdrop />
              </Col>
              <Col sm={12} lg={12}>
                <OriginVipAirdrop />
              </Col> */}
              <Col sm={12} lg={12}>
                <SyndicateAirdrop />
              </Col>
              <Col sm={12} lg={12}>
                <RevealNfts />
              </Col>
              <Col sm={12} lg={12}>
                <ArmorsAirdrop />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(NftsMintTransfer);
