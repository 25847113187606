import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import AnimateHeight from "react-animate-height"
import {getContactUsListRequest} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"

import SearchInput from "components/Common/SearchInput"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2"
import ListColumns, { selectRow } from "./ListColumns"
import NotificationModal from "components/Modals/NotificatioView"
import ViewModal from "./ViewModal"

const ContactUs = ({
  accessLevel,
  history,
  faqs,
  getContactUsListRequest,
  user,
  role,
  totalContactUs, 
  contactUsList,
  loading,
  ...props
}) => {
  const [filters,setFilters] = useState({
    page : 1,
    limit : 10, 
    search : ""
  });

  useEffect(()=>{
    getContactUsListRequest(filters);
  },[filters]);
  
  
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  })
  const [selected, setSelected] = useState([])
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    faqId: null,
  })
 
  const [searchText, setSearchText] = useState("")
  const [faqsList, setFaqsList] = useState([])
  const [rowData,setRowData] = useState([]);
  const [showViewModal,setShowViewModal] = useState(false);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalContactUs,
    custom: true,
    onPageChange: (page) => setFilters((prevState) => ({ ...prevState, page })),
  }
  const { SearchBar } = Search

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      }

      return setaccesses(data)
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    }

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value
          break

        case "UPDATE":
          data.canEdit = item.value
          break

        case "DELETE":
          data.canDelete = item.value
          break

        case "BLOCK":
          data.canBlock = item.value
          break
      }
    })

    setaccesses(data)
  }, [JSON.stringify(accessLevel)])


  const toggleConfirmModal = (faqId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, faqId }))
  }

  const removeConfirm = () => {
    const { faqId, isOpen } = confirmModal
  }

  const handleTableChange = () =>{

  }

 const hanldeViewModal = (data) =>{
      setRowData(data);
      setShowViewModal(true);
 }

  return (
    <React.Fragment>
      {showViewModal && <ViewModal
         isOpen={showViewModal}
         toggle={()=>{
           setShowViewModal(false);
           setRowData(null);
         }}
         data={rowData}
       />
        }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Contact Us"
            breadcrumbItem={props.t("Contact Us")}
            breadcrumbItems={[{ title: props.t("Contact Us") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={contactUsList || []}
                        columns={ListColumns(
                          history,
                          toggleConfirmModal,
                          accesses,
                          props.t,
                          hanldeViewModal
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <div className="custom-flex-xs d-flex align-items-center mb-3">

                                </div>
                              </AnimateHeight>
                            )}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ customers }) => {
 console.log("customers",customers)
  return({
  loading: customers.loading,
  contactUsList : customers.contactUsList,
  totalContactUs : customers.totalContactUs,
})
}

const mapDispatchToProps = (dispatch) => ({
  getContactUsListRequest:(data,callback) => dispatch(getContactUsListRequest(data,callback)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactUs)),
)
