import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import {
  ACTIVITY_TYPE,
  BLOCKCHAIN_CURRENCY_CODE,
  formatWalletAddress,
} from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "walletAddress",
    text: t("Wallet Address"),
    formatter: (wallet) => <p title={wallet}>{wallet}</p>,
  },
  {
    dataField: "token_id",
    text: t("Token Id"),
    formatter: (_, row) => <p>{row.token_id || "---"}</p>,
  },
  {
    dataField : "equipment_token_id",
    text : "Equipment Token Id",
    formatter: (_, row) => <p>{row.equipment_token_id || "---"}</p>,
  },
  {
    dataField: "equipment_status",
    text: t("Equipment status"),
    formatter: (_, row) => <p>{row?.equipment_status || "---"}</p>,
  },
  {
    dataField: "status",
    text: t("status"),
    formatter: (_, row) => <p>{row?.status || "---"}</p>,
  },
  {
    text: t("Created At"),
    dataField: "create_date",
    sort: true,
    formatter: (_, row) => <p>{moment(row.create_date).format("DD MMM YYYY")}</p>,
  },
  {
    isDummyField: true,
    text: t("action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
          <Link
            to="#"
            onClick={e => {
              e.preventDefault()
              toggleConfirmModal(row._id)
            }}
            className="text-secondary"
          >
            <i
              className="fas fa-trash-alt mr-3"
              id={`delete-${row._id}-tooltip`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`delete-${row._id}-tooltip`}
            >
              {t("delete")}
            </UncontrolledTooltip>
          </Link>

      </>
    ),
  },
];

export default ListColumns;
