import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import AnimateHeight from "react-animate-height";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import SearchInput from "components/Common/SearchInput";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import {
  getActivities,
  deleteActivity,
  putActivitiesStatus,
} from "store/actions";
import ListColumns, { selectRow } from "./ListColumns";
import ConfirmModal from "./ConfirmModal";

const ActivityList = ({
  accessLevel,
  history,
  items,
  totalItems,
  onGetActivities,
  onDeleteActivity,
  onPutActivitiesStatus,
  loading,
  ...props
}) => {
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canBlock: false,
  });
  const [selected, setSelected] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    itemId: null,
  });
  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 1,
    limit: 10,
    search: "",
    fields: [
      {
        fieldName: "",
        fieldValue: "",
      },
    ],
  });
  const [searchText, setSearchText] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalItems,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  const { SearchBar } = Search;

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canBlock: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBlock: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;

        case "DELETE":
          data.canDelete = item.value;
          break;

        case "BLOCK":
          data.canBlock = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    onGetActivities(filter);
    setItemsList(items);
  }, [onGetActivities, JSON.stringify(filter)]);

  useEffect(() => {
    setItemsList(items || []);
  }, [items]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (itemId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      itemId,
    }));
  };

  const removeConfirm = () => {
    const { itemId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetActivities(filter);
    };

    setConfirmModal({ isOpen: !isOpen, itemId: null });
    onDeleteActivity({ _id: itemId }, onComplete);
  };

  const onStatusChange = (value) => {
    const fieldName = !!value ? "status" : "";
    const fieldValue = value;

    setFilter((prevState) => ({
      ...prevState,
      fields: { fieldName, fieldValue },
    }));
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };

  const onSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((x) => x !== row._id));
    }
  };

  const onSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r._id);
    if (isSelect) {
      setSelected(ids);
    } else {
      setSelected([]);
    }
  };

  const handleItemStatusChange = (status) => {
    const callback = () => {
      setSelected([]);
      onGetActivities(filter);
    };

    onPutActivitiesStatus({ _id: selected, status }, callback);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Activities"
            breadcrumbItem={props.t("Activities")}
            breadcrumbItems={[{ title: props.t("Activities") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={itemsList || []}
                        columns={ListColumns(
                          history,
                          toggleConfirmModal,
                          accesses,
                          props.t
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="3">
                                <SearchInput
                                  {...toolkitProps.searchProps}
                                  triggerSearch={onSearch}
                                  placeholder={props.t("Search")}
                                  searchText={searchText}
                                />
                              </Col>

                              <Col sm="2">
                                {!!filter.fields.fieldName ||
                                  (!!filter.search && (
                                    <div
                                      className="mr-4 mb-2"
                                      style={{
                                        height: "calc( 1.5em + 0.94rem + 2px)",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilter((prevState) => ({
                                            ...prevState,
                                            fields: {
                                              fieldName: "",
                                              fieldValue: "",
                                            },
                                            search: "",
                                          }));
                                          setSearchText("");
                                        }}
                                        className=""
                                      >
                                        {props.t("clear_filters")}
                                      </Link>
                                    </div>
                                  ))}
                              </Col>
                            </Row>

                            {(accesses.canEdit || accesses.canDelete) && (
                              <AnimateHeight
                                duration={500}
                                height={selected.length > 0 ? "auto" : 0}
                              >
                                <Row className="pb-2">
                                  {accesses.canEdit && (
                                    <Col sm="2">
                                      <div className="form-group row">
                                        <label className="col-md-5 pr-0 col-form-label">
                                          {props.t("action")}:
                                        </label>

                                        <div className="col-md-7">
                                          <select
                                            className="custom-select"
                                            onChange={(e) =>
                                              handleItemStatusChange(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              {props.t("status")}
                                            </option>
                                            <option value="active">
                                              {props.t("active")}
                                            </option>
                                            <option value="inactive">
                                              {props.t("inactive")}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {accesses.canDelete && (
                                    <Col sm="2">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleItemStatusChange("archived")
                                        }
                                      >
                                        {props.t("delete")}{" "}
                                        {props.t("selected")}
                                      </button>
                                    </Col>
                                  )}

                                  <Col></Col>
                                </Row>
                              </AnimateHeight>
                            )}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                    // selectRow={selectRow({
                                    //   selected,
                                    //   onSelect,
                                    //   onSelectAll,
                                    // })}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ActivityList.propTypes = {
  items: PropTypes.array,
  totalItems: PropTypes.number,
  onGetActivities: PropTypes.func,
  onDeleteActivity: PropTypes.func,
  onPutActivitiesStatus: PropTypes.func,
};

const mapStateToProps = ({ Activities }) => ({
  loading: Activities.loading,
  items: Activities.items,
  totalItems: Activities.totalActivities,
});

const mapDispatchToProps = (dispatch) => ({
  onGetActivities: (data) => dispatch(getActivities(data)),
  onDeleteActivity: (data, callback) =>
    dispatch(deleteActivity(data, callback)),
  onPutActivitiesStatus: (data, callback) =>
    dispatch(putActivitiesStatus(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActivityList))
);
