import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withRouter, useParams } from "react-router-dom"
import { connect } from "react-redux"
/* import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditorUploadAdapterPlugin from "../../../plugins/ckeditor/UploadAdapterPlugin" */
import CKEditor from "react-ckeditor-component"
import FormButton from "components/Common/FormButtons"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap"

import { addCONTENT, getCONTENT, putCONTENT,getCONTENTs } from "store/actions"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2"

//i18n
import { withTranslation } from "react-i18next"

const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddContent,
  onGetContent,
  onPutContent,
  content,
  Contents,
  loading,
  ...props
}) => {
  const { id } = useParams()
  useEffect(()=>{
    props.onGetContents();
  },[]);

  console.log("Contents ", Contents)
  const didMountRef = useRef(null)

  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  })
  const [fields, setFields] = useState({
    type:"",
    title: "",
    content: "",
    status: "active",
  })

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      }

      return setaccesses(data)
    }

    const data = {
      canAdd: false,
      canEdit: false,
    }

    accessLevel?.map(item => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value
          break

        case "UPDATE":
          data.canEdit = item.value
          break
      }
    })

    setaccesses(data)
  }, [JSON.stringify(accessLevel)])

  useEffect(() => {
    if (id) {
      onGetContent(id)
    }
  }, [id])

  useEffect(() => {
    console.log("content ==> ", props.contentById)
    if (props.contentById && id) {
      // if (!!content && !!content.contentById) {
        try {
          setFields({
            title: props.contentById.title || "",
            content: props.contentById.content || "",
            status: props.contentById.status || "active",
            type : props.contentById?.type || ""
          })
        } catch (err) {
          console.log("parsing error ", err)
        }
      // }
    } else didMountRef.current = true
  }, [JSON.stringify(props.contentById),id])

  const handleChange = name => event => {
    setFields(prevState => ({ ...prevState, [name]: event.target.value }))
  }

  const onSubmit = event => {
    event.preventDefault()

    if (loading) return

    if (id) {
      if (!accesses.canEdit) return
      onPutContent({ _id: props.contentById?._id, ...fields }, history)
    } else {
      if (!accesses.canAdd) return
      onAddContent({ ...fields }, history)
    }
  }
  console.log("CONTENT ==> ", props)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Contents"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) + " " + props.t("content")
            }
            breadcrumbItems={[
              { title: props.t("contents"), link: "/contents" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("title")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.title}
                            onChange={handleChange("title")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("type")}
                          </Label>
                          <Input
                            type="select"
                            id="formrow-firstname-Input"
                            value={fields.type}
                            placeholder="Select a type..."
                            onChange={handleChange("type")}
                            required
                          >
                            <option value={""} disabled>Select a type</option>
                            {Contents?.map((item)=>{
                              if(!item?.type) return;
                              return(
                                <option value={item?.type}>{item?.type}</option>
                              );
                            })} 
                         
                          </Input>  
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>{props.t("Content")}</Label>

                          <CKEditor
                            content={fields.content}
                            events={{
                              change: evt => {
                                const data = evt?.editor?.getData()

                                handleChange("content")({
                                  target: { value: data },
                                })
                              },
                            }}
                            config={{
                              height: 250,
                              toolbar: [
                                [
                                  "Undo",
                                  "Redo",
                                  "Font",
                                  "FontSize",
                                  "Styles",
                                  "Format",
                                  "-",
                                  "Maximize",
                                  "-",
                                  "Source",
                                ],
                                [
                                  "Bold",
                                  "Italic",
                                  "Underline",
                                  "Strike",
                                  "-",
                                  "RemoveFormat",
                                  "-",
                                  "NumberedList",
                                  "BulletedList",
                                ],
                                [
                                  "Link",
                                  "Unlink",
                                  "-",
                                  "JustifyLeft",
                                  "JustifyCenter",
                                  "JustifyRight",
                                  "JustifyBlock",
                                  "-",
                                  "Outdent",
                                  "Indent",
                                  "-",
                                  "TextColor",
                                  "BGColor",
                                ],
                                [
                                  "Image",
                                  "Table",
                                  "HorizontalRule",
                                  "SpecialChar",
                                  "-",
                                  "Blockquote",
                                ],
                              ],
                            }}
                            scriptUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
                          />

                          {/* <CKEditor
                            editor={ClassicEditor}
                            data={fields.answer}
                            config={{
                              extraPlugins: [EditorUploadAdapterPlugin],
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()

                              handleChange("answer")({
                                target: { value: data },
                              })
                            }}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active"

                                handleChange("status")({ target: { value } })
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  content: PropTypes.object,
  onAddContent: PropTypes.func,
  onGetContent: PropTypes.func,
  onPutContent: PropTypes.func,
}

const mapStateToProps = ({ content }) => ({
  error: content.error,
  loading: content.loading,
  contentById: content.CONTENTBYID,
  Contents: content.CONTENTs,
})

const mapDispatchToProps = dispatch => ({
  onAddContent: (data, history) => dispatch(addCONTENT(data, history)),
  onGetContent: id => dispatch(getCONTENT(id)),
  onPutContent: (data, history) => dispatch(putCONTENT(data, history)),
  onGetContents: (data) => dispatch(getCONTENTs(data)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
)
