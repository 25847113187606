export const REGISTER_USER_EMAIL = "REGISTER_USER_EMAIL"
export const REGISTER_USER_EMAIL_SUCCESSFUL = "REGISTER_USER_EMAIL_SUCCESSFUL"
export const REGISTER_USER_EMAIL_FAILED = "REGISTER_USER_EMAIL_FAILED"

export const REGISTER_STORE_NAME = "REGISTER_STORE_NAME"
export const REGISTER_STORE_NAME_FAIL = "REGISTER_STORE_NAME_FAIL"
export const REGISTER_STORE_NAME_SUCCESS = "REGISTER_STORE_NAME_SUCCESS"

export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"
