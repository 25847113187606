import { call, put, takeEvery } from "redux-saga/effects";

// Faq Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* fetchFaqs({ payload }) {
  try {
    const response = yield call(API.getFaqs, payload);
    if (response.status == "failure") {
      return yield put(ACTIONS.getFaqsFail(response.message));
    }

    yield put(ACTIONS.getFaqsSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getFaqsFail(error));
  }
}

function* onAddNewFaq({ payload: { faq, history } }) {
  try {
    const response = yield call(API.postFaq, faq);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.addFaqFail(response.message));
    }

    history && history.replace("/faqs");

    yield put(ACTIONS.addFaqSuccess());
  } catch (error) {
    yield put(ACTIONS.addFaqFail("Internal Error!"));
  }
}

function* fetchFaq({ payload }) {
  try {
    const response = yield call(API.getFaq, payload);
    if (response.status == "failure") {
      return yield put(ACTIONS.getFaqFail(response.message));
    }

    yield put(ACTIONS.getFaqSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getFaqFail(error));
  }
}

function* onPutFaq({ payload: { data, history } }) {
  try {
    const response = yield call(API.putFaq, data);
    if (response.status == "failure") {
      return yield put(ACTIONS.putFaqFail(response.message));
    }

    history && history.replace("/faqs");

    yield put(ACTIONS.putFaqSuccess());
  } catch (error) {
    yield put(ACTIONS.putFaqFail("Internal Error!"));
  }
}

function* onDeleteFaq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.deleteFaq, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.deleteFaqFail(response.message));
    }
    callback && callback(response);
    yield put(ACTIONS.deleteFaqSuccess(response));
  } catch (error) {
    yield put(ACTIONS.deleteFaqFail("Internal Error!"));
  }
}

function* onPutFaqsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(API.putFaqsStatus, data);
    if (response.status == "failure") {
      return yield put(ACTIONS.putFaqsStatusFail(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.putFaqsStatusSuccess(response));
  } catch (error) {
    yield put(ACTIONS.putFaqsStatusFail("Internal Error!"));
  }
}

function* FaqsSaga() {
  yield takeEvery(TYPES.GET_FAQS, fetchFaqs);
  yield takeEvery(TYPES.ADD_FAQ, onAddNewFaq);
  yield takeEvery(TYPES.GET_FAQ, fetchFaq);
  yield takeEvery(TYPES.PUT_FAQ, onPutFaq);
  yield takeEvery(TYPES.DELETE_FAQ, onDeleteFaq);
  yield takeEvery(TYPES.PUT_FAQS_STATUS, onPutFaqsStatus);
}

export default FaqsSaga;
