import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getActivity } from "store/actions";

const ViewActivities = (props) => {
  const {
    match: { params },
    getActivitiesReq,
    item,
    loading,
  } = props;

  console.log("item", item);

  useEffect(() => {
    if (params && params.id) {
      console.log("params.id", params.id);
      getActivitiesReq(params.id);
    }
  }, [params]);

  console.log("Item asasasas", item);

  return (
    <React.Fragment>
      {loading && (
        <div className="page-content">
          <Spinner color="primary" />
        </div>
      )}

      {item && !loading && (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Vendors" breadcrumbItem="Vendor Details" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <div className="user-details">
                            <h4 className="userdetails-name">
                              Wallet Address:
                            </h4>
                            <h5 className="userdetails-value">
                              {item?.walletAddress}
                            </h5>
                          </div>
                          <div className="user-details">
                            <h4 className="userdetails-name">User Name: </h4>
                            <h5 className="userdetails-value">
                              {item?.author_id?.username}
                            </h5>
                          </div>

                          <div className="user-details">
                            <h4 className="userdetails-name">Email: </h4>
                            <h5 className="userdetails-value">
                              {item?.author_id?.email}
                            </h5>
                          </div>
                          <div className="user-details">
                            <h4 className="userdetails-name">Token Id: </h4>
                            <h5 className="userdetails-value">
                              {item?.token_id}
                            </h5>
                          </div>
                          <div className="user-details">
                            <h4 className="userdetails-name">Cost: </h4>
                            <h5 className="userdetails-value">{item?.price}</h5>
                          </div>
                          <div className="user-details">
                            <h4 className="userdetails-name">
                              Is AirDropped:{" "}
                            </h4>
                            <h5 className="userdetails-value">
                              {item?.isAirDropped ? "Yes" : "No"}
                            </h5>
                          </div>
                          {item?.play_to_mint_type ? (
                            <>
                              <div className="user-details">
                                <h4 className="userdetails-name">
                                  Play session:{" "}
                                </h4>
                                <h5 className="userdetails-value">
                                  {item?.play_session}
                                </h5>
                              </div>
                              <div className="user-details">
                                <h4 className="userdetails-name">
                                  Play to mint type:{" "}
                                </h4>
                                <h5 className="userdetails-value">
                                  {item?.play_to_mint_type}
                                </h5>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ Activities }) => ({
  loading: Activities.loading,
  item: Activities.item,
});

const mapDispatchToProps = (dispatch) => ({
  getActivitiesReq: (id) => dispatch(getActivity(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewActivities);
