import * as TYPES from "./actionTypes";

/* Get Global Settings */
export const getSettings = () => ({
  type: TYPES.GET_SETTINGS,
});

export const getSettingsSuccess = (data) => ({
  type: TYPES.GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsFail = (error) => ({
  type: TYPES.GET_SETTINGS_FAIL,
  payload: error,
});
/* Get Global Settings END */

/* Update Global Settings */
export const putSettings = (data) => ({
  type: TYPES.PUT_SETTINGS,
  payload: data,
});

export const putSettingsSuccess = (data) => ({
  type: TYPES.PUT_SETTINGS_SUCCESS,
  payload: data,
});

export const putSettingsFail = (error) => ({
  type: TYPES.PUT_SETTINGS_FAIL,
  payload: error,
});
/* Update Global Settings END */

/* Get Access List */
export const getAccessList = () => ({
  type: TYPES.GET_ACCESS_LIST,
});

export const getAccessListSuccess = (data) => ({
  type: TYPES.GET_ACCESS_LIST_SUCCESS,
  payload: data,
});

export const getAccessListFail = (error) => ({
  type: TYPES.GET_ACCESS_LIST_FAIL,
  payload: error,
});
/* Get Access List END */

/* Get Store Types */
export const getStoreTypes = () => ({
  type: TYPES.GET_STORE_TYPES,
});

export const getStoreTypesSuccess = (data) => ({
  type: TYPES.GET_STORE_TYPES_SUCCESS,
  payload: data,
});

export const getStoreTypesFail = (error) => ({
  type: TYPES.GET_STORE_TYPES_FAIL,
  payload: error,
});
/* Get Store Types END */

/* Get Store Started */
export const getStarted = () => ({
  type: TYPES.GET_STARTED,
});

export const getStartedSuccess = (data) => ({
  type: TYPES.GET_STARTED_SUCCESS,
  payload: data,
});

export const getStartedFail = (error) => ({
  type: TYPES.GET_STARTED_FAIL,
  payload: error,
});
/* Get Store Started END */

/* Post Contract Deploy */
export const postDeployContract = (data, callback) => ({
  type: TYPES.POST_CONTRACT_DEPLOY,
  payload: { data, callback },
});

export const postDeployContractSuccess = (data) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_SUCCESS,
  payload: data,
});

export const postDeployContractFail = (error) => ({
  type: TYPES.POST_CONTRACT_DEPLOY_FAIL,
  payload: error,
});
/* Post Contract Deploy */

export const getDashboard = () => ({
  type: TYPES.GET_DASHBOARD,
});

export const getDashboardSuccess = (data) => ({
  type: TYPES.GET_DASHBOARD_SUCCESS,
  payload: data,
});

export const getDashboardFail = (error) => ({
  type: TYPES.GET_DASHBOARD_FAIL,
  payload: error,
});


//regian user life

export const regainUserPlayChancesRequest = (data) => ({
  type: TYPES.REGAIN_USER_PLAYCHANCES_REQUEST,
  payload: {data},
});

export const regainUserPlayChancesSuccess = (data) => ({
  type: TYPES.REGAIN_USER_PLAYCHANCES_SUCCESS,
  payload: data,
});

export const regainUserPlayChancesFail = (error) => ({
  type: TYPES.REGAIN_USER_PLAYCHANCES_FAIL,
  payload: error,
});
