import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toastr from "toastr";
// Item Redux States
import {
  GET_ITEMS,
  ADD_ITEM,
  GET_ITEM,
  PUT_ITEM,
  DELETE_ITEM,
  PUT_ITEMS_STATUS,
  GET_NFTS_METADATA_REQUEST,
  GET_NFT_METADATA_REQUEST,
  REFRESH_NFTS_METADATA_REQUEST,
  UPDATE_NFT_METADATA_REQUEST,
  UPDATE_NFT_IMAGE_REQUEST,
  NFTS_RRC_TO_MAINBUCKET_REQUEST,
  POST_MINT_ACTIVITY,
  UPLOAD_METADATA_UPDATE_REQUEST,
  REVEAL_NFTS_REQUEST,
} from "./actionTypes";
import {
  getItemsFail,
  getItemsSuccess,
  addItemFail,
  addItemSuccess,
  getItemFail,
  getItemSuccess,
  putItemFail,
  putItemSuccess,
  deleteItemFail,
  deleteItemSuccess,
  putItemsStatusFail,
  putItemsStatusSuccess,
  getNftsMetadataFail,
  getNftsMetadataSuccess,
  getNftMetadataRequest,
  getNftMetadataFail,
  getNftMetadataSuccess,
  refreshNftsMetadataFail,
  refreshNftsMetadataSuccess,
  updateNftMetadataFail,
  updateNftMetadataSuccess,
  updateNftImageFail,
  updateNftImageSuccess,
  postNftRrcToMainBucketFail,
  postNftRrcToMainBucketSuccess,
  postMintActivityFail,
  postMintActivitySuccess,
  uploadMetadataUpdateFail,
  uploadMetadataUpdateSuccess,
  revealNftsFail,
  revealNftsSuccess,
} from "./actions";

import {
  getItems,
  postItem,
  getItem,
  putItem,
  deleteItem,
  putItemsStatus,
  getNftsMetadataListApi,
  getNftMetadataApi,
  refreshNftsMetadataApi,
  updateNftMetadataApi,
  updateNftImageApi,
  postMintActivity,
  postNftRrcToMainApi,
  uploadMetadataUpdate,
  postRevealNftsApi,
} from "helpers/backend_helper";

function* fetchItems({ payload }) {
  try {
    const response = yield call(getItems, payload);

    if (response.status == "failure") {
      return yield put(getItemsFail(response.message));
    }

    yield put(getItemsSuccess(response));
  } catch (error) {
    yield put(getItemsFail(error));
  }
}

function* onAddNewItem({ payload: { item, history } }) {
  try {
    const response = yield call(postItem, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addItemFail(response.message));
    }

    history && history.goBack();

    yield put(addItemSuccess());
  } catch (error) {
    yield put(addItemFail("Internal Error!"));
  }
}

function* fetchItem({ payload }) {
  try {
    const response = yield call(getItem, payload);

    if (response.status == "failure") {
      return yield put(getItemFail(response.message));
    }

    yield put(getItemSuccess(response.data));
  } catch (error) {
    yield put(getItemFail(error));
  }
}

function* onPutItem({ payload: { data, history } }) {
  try {
    const response = yield call(putItem, data);

    if (response.status == "failure") {
      return yield put(putItemFail(response.message));
    }

    history && history.goBack();

    yield put(putItemSuccess());
  } catch (error) {
    yield put(putItemFail("Internal Error!"));
  }
}

function* onDeleteItem({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteItem, data);

    if (response.status == "failure") {
      return yield put(deleteItemFail(response.message));
    }

    yield put(deleteItemSuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteItemFail("Internal Error!"));
  }
}

function* onPutItemsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putItemsStatus, data);

    if (response.status == "failure") {
      return yield put(putItemsStatusFail(response.message));
    }

    yield put(putItemsStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putItemsStatusFail("Internal Error!"));
  }
}

function* getNftsMetadataReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getNftsMetadataListApi, data);

    if (response.status == "failure") {
      return yield put(getNftsMetadataFail(response.message));
    }

    yield put(getNftsMetadataSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(getNftsMetadataFail("Internal Error!"));
  }
}

function* getNftMetadataReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getNftMetadataApi, data);

    if (response.status == "failure") {
      return yield put(getNftMetadataFail(response.message));
    }

    yield put(getNftMetadataSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(getNftMetadataFail("Internal Error!"));
  }
}

function* refreshNftsMetadataReq({ payload: { data, callback } }) {
  try {
    const response = yield call(refreshNftsMetadataApi, data);

    if (response.status == "failure") {
      return yield put(refreshNftsMetadataFail(response.message));
    }

    yield put(refreshNftsMetadataSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(refreshNftsMetadataFail("Internal Error!"));
  }
}

function* updateNftMetadataReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateNftMetadataApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(updateNftMetadataFail(response.message));
    }
    toastr.success("Nft metadata updated successfully!");
    yield put(updateNftMetadataSuccess(response));
    callback && callback(response);
  } catch (error) {
    toastr.error("Internal Error!");
    yield put(updateNftMetadataFail("Internal Error!"));
  }
}

function* updateNftImageReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateNftImageApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(updateNftImageFail(response.message));
    }
    toastr.success("Nft image updated successfully!");
    yield put(updateNftImageSuccess(response));
    callback && callback(response);
  } catch (error) {
    toastr.error("Internal Error!");
    yield put(updateNftImageFail("Internal Error!"));
  }
}

function* postNftRrcToMainBucketReq({ payload: { data, callback } }) {
  try {
    const response = yield call(postNftRrcToMainApi, data);
    if (response.status === "failure") {
      // toast.error(response.message);
      console.log("error", response.message);
      return yield put(postNftRrcToMainBucketFail(response.message));
    }
    yield put(postNftRrcToMainBucketSuccess(response));
    callback && callback(response.data);
  } catch (error) {
    console.log("error", error);
    yield put(postNftRrcToMainBucketFail(error));
  }
}

function* onPostMintActivity({ payload: { data, callback } }) {
  try {
    const response = yield call(postMintActivity, data);

    if (response.status === "failure") {
      // toast.error(response.message);
      console.log("error", response.message);
      return yield put(postMintActivityFail(response.message));
    }

    yield put(postMintActivitySuccess(response));
    callback && callback();
  } catch (error) {
    console.log("error", error);
    yield put(postMintActivityFail(error));
  }
}

function* onUploadMetadataUpdate({ payload: { data, callback } }) {
  try {
    const response = yield call(uploadMetadataUpdate, data);

    if (response.status === "failure") {
      toastr.error(response.message);
      console.log("error", response.message);
      return yield put(uploadMetadataUpdateFail(response.message));
    }

    yield put(uploadMetadataUpdateSuccess(response));
    callback && callback();
  } catch (error) {
    console.log("error", error);
    toastr.error(error?.message || "Something went wrong!");
    yield put(uploadMetadataUpdateFail(error));
  }
}

//Reveal Nfts

function* onRevealNftsReq({ payload: { data, callback } }) {
  try {
    const response = yield call(postRevealNftsApi, data);

    if (response.status === "failure") {
      // toast.error(response.message);
      console.log("error", response.message);
      return yield put(revealNftsFail(response.message));
    }
    toastr.success("Nft revealed successfully!");
    yield put(revealNftsSuccess(response));
    callback && callback();
  } catch (error) {
    console.log("error", error);
    yield put(revealNftsFail(error));
  }
}

function* ItemsSaga() {
  yield takeEvery(GET_ITEMS, fetchItems);
  yield takeEvery(ADD_ITEM, onAddNewItem);
  yield takeEvery(GET_ITEM, fetchItem);
  yield takeEvery(PUT_ITEM, onPutItem);
  yield takeEvery(DELETE_ITEM, onDeleteItem);
  yield takeEvery(PUT_ITEMS_STATUS, onPutItemsStatus);
  yield takeLatest(GET_NFTS_METADATA_REQUEST, getNftsMetadataReq);
  yield takeLatest(GET_NFT_METADATA_REQUEST, getNftMetadataReq);
  yield takeLatest(REFRESH_NFTS_METADATA_REQUEST, refreshNftsMetadataReq);
  yield takeLatest(UPDATE_NFT_METADATA_REQUEST, updateNftMetadataReq);
  yield takeLatest(UPDATE_NFT_IMAGE_REQUEST, updateNftImageReq);
  yield takeLatest(NFTS_RRC_TO_MAINBUCKET_REQUEST, postNftRrcToMainBucketReq);
  yield takeLatest(POST_MINT_ACTIVITY, onPostMintActivity);
  yield takeLatest(UPLOAD_METADATA_UPDATE_REQUEST, onUploadMetadataUpdate);
  yield takeLatest(REVEAL_NFTS_REQUEST, onRevealNftsReq);
}

export default ItemsSaga;
