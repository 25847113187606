import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import Instructions from "./Instructions";
import { uploadFile as onUploadFile, getCollections } from "store/actions";
import { useDispatch } from "react-redux";

const HomeNftsSlider = (props) => {
  const { fields, setFields } = props;
  const dispatch = useDispatch();
  const [homeNftsSlider, setHomeNftsSlider] = useState(fields);

  console.log(fields, "fields", "homeNftsSlider", homeNftsSlider);

  useEffect(() => {}, []);

  const removeHomeNfts = (index) => {
    setFields((prevState) => ({
      ...prevState,
      homeNftsSlider: [
        ...prevState["homeNftsSlider"].slice(0, index),
        ...prevState["homeNftsSlider"].slice(index + 1),
      ],
    }));
  };

  const handleHomeNftsChange = (index) => (name, data) => {
    setFields((prevState) => {
      return {
        ...prevState,
        homeNftsSlider: prevState["homeNftsSlider"].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [name]: data,
            };
          } else {
            return item;
          }
        }),
      };
    });
  };

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  const uploadFileSuccess = (index) => (response) => {
    const { link } = response.data;

    setFields((prevState) => {
      return {
        ...prevState,
        homeNftsSlider: prevState["homeNftsSlider"].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              image: link,
            };
          } else {
            return item;
          }
        }),
      };
    });
  };

  const changeHandler = (bannerIndex) => (event) => {
    const _files = event.target.files;

    // if (_files && _files[0] && _files[0].size > 100000000) {
    //   return toastr.error("Maximum upload file size: 100MB");
    // }

    dispatch(
      onUploadFile({ image: _files[0] }, uploadFileSuccess(bannerIndex))
    );
  };

  console.log("fields", fields);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Instructions />
        </Col>
      </Row>
      <Row>
        {(fields || []).map((item, index) => (
          <Row className="align-items-center my-2 mx-2">
            {!item?.image && (
              <Col md={2} className="text-truncate">
                <input
                  type="file"
                  name="file"
                  onChange={changeHandler(index)}
                />
              </Col>
            )}

            {!!item?.image && (
              <Col md={2}>
                {get_url_extension(item?.image) === "mp4" ? (
                  <video
                    className="rounded-new avatar-xl"
                    loop="true"
                    autoplay="autoplay"
                  >
                    <source src={item?.image} type="video/mp4"></source>
                  </video>
                ) : (
                  <img
                    className="rounded-new"
                    src={item?.image}
                    alt=""
                    height={110}
                  />
                )}
              </Col>
            )}
            <Col md={3}>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  type="text"
                  value={item?.title}
                  onChange={(e) =>
                    handleHomeNftsChange(index)("title", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  value={item?.description}
                  onChange={(e) =>
                    handleHomeNftsChange(index)("description", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Opensea</Label>
                <Input
                  type="text"
                  value={item?.opensea_link}
                  onChange={(e) =>
                    handleHomeNftsChange(index)("opensea_link", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            {/* <Col md={2} className="text-truncate">
              <FormGroup>
                <Label>Sort</Label>
                <Input
                  type="number"
                  value={item?.sort}
                  onChange={(e) =>
                    handleHomeNftsChange(index)("sort", e.target.value)
                  }
                  min={0}
                  required
                />
              </FormGroup>
            </Col> */}

            <Col md={2}>
              <Button onClick={() => removeHomeNfts(index)}>Remove</Button>
            </Col>
          </Row>
        ))}
        <Col md={12}>
          <Button
            color="primary"
            className="buttoncolor mt-3"
            onClick={() => {
              setFields((prevState) => ({
                ...prevState,
                homeNftsSlider: [
                  ...prevState.homeNftsSlider,
                  {
                    image: "",
                    sort: 0,
                    opensea_link: "",
                    title: "",
                    description: "",
                  },
                ],
              }));
            }}
          >
            Add More
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

HomeNftsSlider.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeNftsSlider)
);
