import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CKEditor from "react-ckeditor-component";
import Select from "react-select";

import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";
import Collections from "./Collections";
import { isObject } from "lodash";
import Dropzone from "react-dropzone";
import { FormText } from "reactstrap";
import logo_placeholder from "assets/images/logo-placeholder.png";
import { uploadFile as onUploadFile, getCollections } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function useOutside(callback, ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
const HomePageSettings = (props) => {
  const { fields, handleChange } = props;
  const [logoselectedFiles, setlogoselectedFiles] = useState([]);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const banner_wrapperRef = useRef(null);
  const wrapperRef2 = useRef(null);
  const wrapperRef3 = useRef(null);
  const wrapperRef4 = useRef(null);
  const wrapperRef5 = useRef(null);
  const banner_wrapperRef2 = useRef(null);
  const banner_wrapperRef3 = useRef(null);

  const [color, setColor] = useState("red");
  const [showBannerColorPicker, setBannerColorPicker] = useState(false);
  const [showBannerColorPicker2, setBannerColorPicker2] = useState(false);
  const [showBannerColorPicker3, setBannerColorPicker3] = useState(false);
  const [showColorPicker, setColorPicker] = useState(false);
  const [showColorPicker2, setColorPicker2] = useState(false);
  const [showColorPicker3, setColorPicker3] = useState(false);
  const [showColorPicker4, setColorPicker4] = useState(false);
  const [showColorPicker5, setColorPicker5] = useState(false);

  const [body, setbody] = useState("");

  const onDrag = (color) => {
    setColor(color);
  };

  useOutside(() => {
    setColorPicker(false);
  }, wrapperRef);

  useOutside(() => {
    setColorPicker2(false);
  }, wrapperRef2);

  useOutside(() => {
    setBannerColorPicker3(false);
  }, banner_wrapperRef3);

  useOutside(() => {
    setBannerColorPicker2(false);
  }, banner_wrapperRef2);

  useOutside(() => {
    setBannerColorPicker(false);
  }, banner_wrapperRef);

  useOutside(() => {
    setColorPicker3(false);
  }, wrapperRef3);

  useOutside(() => {
    setColorPicker4(false);
  }, wrapperRef4);

  useOutside(() => {
    setColorPicker5(false);
  }, wrapperRef5);

  useEffect(() => {
    console.log(fields?.beignRoboticRabbitTopimage, 89787789789);

    if (isObject(fields?.image) && !!fields?.image?.link) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 0),
        {
          preview: fields?.image?.link,
        },
        ...prev.slice(0 + 1),
      ]);
      // setlogoselectedFiles([{ preview: fields?.image?.link }]);
    }

    if (
      isObject(fields?.beignRoboticRabbitTopimage) &&
      !!fields?.beignRoboticRabbitTopimage?.link
    ) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 1),
        {
          preview: fields?.beignRoboticRabbitTopimage?.link,
        },
        ...prev.slice(1 + 1),
      ]);
    }

    if (
      isObject(fields?.beignRoboticRabbitBottomimage) &&
      !!fields?.beignRoboticRabbitBottomimage?.link
    ) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 2),
        {
          preview: fields?.beignRoboticRabbitBottomimage?.link,
        },
        ...prev.slice(2 + 1),
      ]);
    }

    if (
      isObject(fields?.isBackgroundImage) &&
      !!fields?.isBackgroundImage?.link
    ) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 3),
        {
          preview: fields?.isBackgroundImage?.link,
        },
        ...prev.slice(3 + 1),
      ]);
    }

    if (
      isObject(fields?.subisBackgroundImage) &&
      !!fields?.subisBackgroundImage?.link
    ) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 4),
        {
          preview: fields?.subisBackgroundImage?.link,
        },
        ...prev.slice(4 + 1),
      ]);
    }

    if (isObject(fields?.mintImage) && !!fields?.mintImage?.link) {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 5),
        {
          preview: fields?.mintImage?.link,
        },
        ...prev.slice(5 + 1),
      ]);
    }
  }, [fields?.image, fields?.beignRoboticRabbitTopimage]);

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;

    handleChange(name)({ target: { value: _id } });
  };

  const handleAcceptedFiles = (name) => (_files) => {
    const files = _files?.filter((file) => file.size < 5242880);

    if (files.length < _files.length) {
      return toastr.error("Maximum upload file size: 5MB");
    }

    dispatch(onUploadFile({ image: files[0] }, uploadFileSuccess(name)));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    console.log(files[0], 898978978);
    if (name === "image") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 0),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(0 + 1),
      ]);
    } else if (name === "beignRoboticRabbitTopimage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 1),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(1 + 1),
      ]);
    } else if (name === "beignRoboticRabbitBottomimage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 2),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(2 + 1),
      ]);
    } else if (name === "isBackgroundImage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 3),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(3 + 1),
      ]);
    } else if (name === "subisBackgroundImage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 4),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(4 + 1),
      ]);
    } else if (name === "mintImage") {
      setlogoselectedFiles((prev) => [
        ...prev.slice(0, 5),
        {
          preview: URL.createObjectURL(files[0]),
          formattedSize: formatBytes(files[0].size),
        },
        ...prev.slice(5 + 1),
      ]);
    }
  };

  //Collection details
  const { collections } = useSelector((state) => state.collections);
  const [options, setoptions] = useState([]);
  const [weaponCollections, setWeaponCollections] = useState([]);
  const [villainCollection, setVillainCollections] = useState([]);
  const [originsCollection, setOriginsCollections] = useState([]);
  const [armorsCollection, setArmorsCollection] = useState([]);

  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    // activeForSale: true
  });
  useEffect(() => {
    dispatch(getCollections(filter));
  }, [JSON.stringify(filter)]);
  useEffect(() => {
    if (collections) {
      setoptions(
        collections
          .filter((col) => col?.type !== "claim_weapon")
          .map((col) => ({
            label: col.name,
            value: col._id,
          }))
      );
      setWeaponCollections(
        collections
          .filter((col) => col?.type === "claim_weapon")
          .map((col) => ({
            label: col.name,
            value: col._id,
          }))
      );
      setVillainCollections(
        collections
          .filter((col) => col?.type === "claim_villain")
          .map((col) => ({
            label: col.name,
            value: col._id,
          }))
      );
      setOriginsCollections(
        collections
          .filter((col) => col?.type === "claim_origin")
          .map((col) => ({
            label: col.name,
            value: col._id,
          }))
      );

      setArmorsCollection(
        collections
          .filter((col) => col?.type === "armors")
          .map((col) => ({
            label: col.name,
            value: col._id,
          }))
      );

    }
  }, [collections]);
  console.log(fields, "fields");
  return (
    <React.Fragment>
      <Row>
        <Col lg={3}>
          <Instructions />
        </Col>

        <Col lg={9}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Active Collection</Label>
                <Select
                  options={options}
                  value={options.find(
                    (item) => item.value === fields.activeCollectionId
                  )}
                  onChange={(e) =>
                    handleChange("activeCollectionId")({
                      target: { value: e.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>Active Weapon and Gears Claim Collection</Label>
                <Select
                  options={weaponCollections}
                  value={weaponCollections.find(
                    (item) => item.value === fields.actionWGClaimCollectionId
                  )}
                  onChange={(e) =>
                    handleChange("actionWGClaimCollectionId")({
                      target: { value: e.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>Active Villain Claim Collection</Label>
                <Select
                  options={villainCollection}
                  value={villainCollection.find(
                    (item) =>
                      item.value === fields.activeVillainClaimCollectionId
                  )}
                  onChange={(e) =>
                    handleChange("activeVillainClaimCollectionId")({
                      target: { value: e.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>Active Origins Claim Collection</Label>
                <Select
                  options={originsCollection}
                  value={originsCollection.find(
                    (item) =>
                      item.value === fields.activeOriginsClaimCollectionId
                  )}
                  onChange={(e) =>
                    handleChange("activeOriginsClaimCollectionId")({
                      target: { value: e.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
             <Col md={12}>
              <FormGroup>
                <Label>Active Armory Collection</Label>
                <Select
                  options={armorsCollection}
                  value={armorsCollection.find(
                    (item) =>
                      item.value === fields.activeArmorsCollectionId
                  )}
                  onChange={(e) =>
                    handleChange("activeArmorsCollectionId")({
                      target: { value: e.value },
                    })
                  }
                />
              </FormGroup>
            </Col>
           
            <Col md={12}>
              <FormGroup>
                <Label>Description</Label>

                <Input
                  type="textarea"
                  value={fields.homePageBannerSetting?.description}
                  onChange={handleChange("description")}
                  rows={4}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

HomePageSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePageSettings)
);
