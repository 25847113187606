import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

const Logout = props => {
  useEffect(() => {
    props.logoutUser(props.history, props?.role)
  }, [])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
}

const mapStateToProps = ({ Login }) => ({ role: Login?.user?.role })

export default withRouter(connect(mapStateToProps, { logoutUser })(Logout))
