import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import {
  ACTIVITY_TYPE,
  BLOCKCHAIN_CURRENCY_CODE,
  formatWalletAddress,
} from "helpers/contants";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (history, toggleConfirmModal, accesses, t) => [
  {
    dataField: "walletAddress",
    text: t("Wallet Address"),
    formatter: (wallet) => <p title={wallet}>{wallet}</p>,
  },
  {
    dataField: "item.name",
    text: t("Token Id"),
    formatter: (_, row) => <p>{row.token_id || "---"}</p>,
  },
  {
    dataField: "username",
    text: t("Username"),
    formatter: (_, row) => <p>{row?.author_id?.username || "---"}</p>,
  },
  {
    dataField: "price",
    text: t("Price"),
    formatter: (_, row) => Number(row.price).toFixed(8) + " " + "ETH",
  },
  {
    dataField: "item.transactionHash",
    text: t("Collection"),
    formatter: (_, row) => <p>{row?.collection?.name || "---"}</p>,
  },
  {
    text: t("Created At"),
    dataField: "create_date",
    sort: true,
    formatter: (_, row) => moment(row.create_date).format("DD MMM YYYY"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link
          to={`/activities/${row._id}/view`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
