import * as CONST from "./actionTypes";

const INIT_STATE = {
  items: [],
  totalCategories: 0,
  error: "",
  loading: false,
};

const Gallery = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Categories */
    case CONST?.GET_GALLERYS:
      return {
        ...state,
        loading: true,
      };

    case CONST?.GET_GALLERYS_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalCategories: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case CONST?.GET_GALLERYS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Categories END */

    /* Add Category */
    case CONST?.ADD_GALLERY:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST?.ADD_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST?.ADD_GALLERY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Category END */

    /* Get Category */
    case CONST?.GET_GALLERY:
      return {
        ...state,
        error: "",
        loading: true,
        item: {},
      };

    case CONST?.GET_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      };

    case CONST?.GET_GALLERY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload,
      };
    /* Add Category END */

    /* Update Category */
    case CONST?.PUT_GALLERY:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST?.PUT_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST?.PUT_GALLERY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Category END */

    /* Delete Category */
    case CONST?.DELETE_GALLERY:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST?.DELETE_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST?.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Category END */

    /* Update Categories Status */
    case CONST?.PUT_GALLERYS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST?.PUT_GALLERYS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST?.PUT_GALLERYS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Categories Status END */

    case CONST?.GALLERY_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Gallery;
