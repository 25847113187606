import * as TYPES from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loading: false,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.EDIT_PROFILE:
      state = { ...state, loading: true };
      break;
    case TYPES.PROFILE_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case TYPES.PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case TYPES.CHANGE_PASSWORD:
      state = { ...state, loading: true };
      break;
    case TYPES.CHANGE_PASSWORD_SUCCESS:
      state = { ...state, success: action.payload, loading: false };
      break;
    case TYPES.CHANGE_PASSWORD_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
