import * as TYPES from "./actionTypes";

export const apiFail = (error) => ({
  type: TYPES.FAQ_API_FAIL,
  payload: error,
});

/* Get faqs */
export const getFaqs = (data) => ({
  type: TYPES.GET_FAQS,
  payload: data,
});

export const getFaqsSuccess = (faqs) => ({
  type: TYPES.GET_FAQS_SUCCESS,
  payload: faqs,
});

export const getFaqsFail = (error) => ({
  type: TYPES.GET_FAQS_FAIL,
  payload: error,
});
/* Get Faqs END */

/* Add Faq */
export const addFaq = (faq, history) => ({
  type: TYPES.ADD_FAQ,
  payload: { faq, history },
});

export const addFaqFail = (error) => ({
  type: TYPES.ADD_FAQ_FAIL,
  payload: error,
});

export const addFaqSuccess = () => ({
  type: TYPES.ADD_FAQ_SUCCESS,
});
/* Add Faq END */

/* Get Faq */
export const getFaq = (id) => ({
  type: TYPES.GET_FAQ,
  payload: id,
});

export const getFaqFail = (error) => ({
  type: TYPES.GET_FAQ_FAIL,
  payload: error,
});

export const getFaqSuccess = (faq) => ({
  type: TYPES.GET_FAQ_SUCCESS,
  payload: faq,
});
/* Get Faq END */

/* Update Faq */
export const putFaq = (data, history) => ({
  type: TYPES.PUT_FAQ,
  payload: { data, history },
});

export const putFaqFail = (error) => ({
  type: TYPES.PUT_FAQ_FAIL,
  payload: error,
});

export const putFaqSuccess = () => ({
  type: TYPES.PUT_FAQ_SUCCESS,
});
/* Update Faq END */

/* Delete Faq */
export const deleteFaq = (data, callback) => ({
  type: TYPES.DELETE_FAQ,
  payload: { data, callback },
});

export const deleteFaqFail = (error) => ({
  type: TYPES.DELETE_FAQ_FAIL,
  payload: error,
});

export const deleteFaqSuccess = () => ({
  type: TYPES.DELETE_FAQ_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Faqs Status */
export const putFaqsStatus = (data, callback) => ({
  type: TYPES.PUT_FAQS_STATUS,
  payload: { data, callback },
});

export const putFaqsStatusFail = (error) => ({
  type: TYPES.PUT_FAQS_STATUS_FAIL,
  payload: error,
});

export const putFaqsStatusSuccess = () => ({
  type: TYPES.PUT_FAQS_STATUS_SUCCESS,
});
/* Update Multi Faqs Status END */
