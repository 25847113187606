import Web3 from "web3";

// Constant
import { getNetworkUrl } from "helpers/contants";
const { Alchemy, Network } = require("alchemy-sdk");

class Web3Intraction {
  constructor(blockchain, settings) {
    const networkUrl = getNetworkUrl(blockchain || "ethereum", settings);

    this.settings = settings;
    if (networkUrl) {
      this.networkUrl = networkUrl;
      this.web3 = new Web3(networkUrl.url);
    }
  }

  isNetworkServiceWorking = () => {
    if (this.web3) {
      return true;
    }

    return false;
  };

  convertPriceToEther = (price) => {
    return Web3.utils.toWei(Number(price).toFixed(8), "ether");
  };

  getContract = (abi, address) => {
    try {
      let contract = new this.web3.eth.Contract(JSON.parse(abi), address);

      return contract;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  eth_sendTransaction = (params, getToken) => {
    return new Promise(async (resolve, reject) => {
      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [params],
        });

        let receipt = await this.getTransactionReceipt(txHash, getToken);
        resolve({ txHash, receipt });
      } catch (error) {
        reject(error);
      }
    });
  };

  getTransactionReceipt = (transactionHash, getToken) => {
    return new Promise(async (resolve, reject) => {
      this.contractInterval = setInterval(async () => {
        try {
          let receipt = await this.web3.eth.getTransactionReceipt(
            transactionHash
          );

          console.log("final receipt", receipt);

          if (!!receipt) {
            if (getToken && !!receipt?.logs && !!receipt.logs[0]) {
              receipt.token_id = this.web3.utils.hexToNumberString(
                receipt.logs[0].topics[3]
              );
            }

            clearInterval(this.contractInterval);
            this.contractInterval = null;

            resolve(receipt);
          }
        } catch (error) {
          clearInterval(this.contractInterval);
          this.contractInterval = null;

          reject(error);
        }
      }, 15000);
    });
  };

  /**
   * Deploy collection contract.
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Object (Transaction Hash, Contract Address) in Success or Error in Fail
   */
  deployContract = (userWallet, collection, callback) => {
    return new Promise(async (resolve, reject) => {
      //set up transaction parameters
      console.log("userWallet",userWallet)
      console.log("collection.data.bytecode",collection.data.bytecode);
      const transactionParameters = {
        from: userWallet, // must match user's active address.
        data: collection.data.bytecode,
        gas: '0x2DC6C0'
      };

      try {
        let receipt = await this.eth_sendTransaction(transactionParameters);

        callback && callback(null, receipt);
        resolve(receipt);
      } catch (error) {
        callback && callback(error.message);
        reject(error.message);
      }
    });
  };

  /**
   * Check user approved contract transactions, if not then make transaction to approve.
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, contract address, bytecode)
   * @param {function} callback Callback function
   *
   * @returns {Promise} Success for approved or Fail for error
   */
  verifyApproved = (userWallet, collection, callback) => {
    return new Promise(async (resolve, reject) => {
      if (collection.abi && collection.data.contractAddress) {
        const contract = this.getContract(
          collection.abi,
          collection.data.contractAddress
        );

        if (!contract) {
          const error_message = "Invalid Contract";
          callback && callback(error_message);
          reject(error_message);
          return;
        }

        const isApproved = await contract.methods
          .isApprovedForAll(userWallet, this.settings.walletAddress.publicKey)
          .call();

        if (isApproved) {
          callback && callback(null, collection);
          resolve(collection);
          return;
        }

        const transactionParameters = {
          to: collection.data.contractAddress, // Required except during contract publications.
          from: userWallet, // must match user's active address.
          data: contract.methods
            .setApprovalForAll(this.settings.walletAddress.publicKey, true)
            .encodeABI(),
        };

        try {
          let { receipt } = await this.eth_sendTransaction(
            transactionParameters
          );

          callback && callback(null, receipt);
          resolve(receipt);
        } catch (error) {
          callback && callback(error.message);
          reject(error.message);
        }
      } else {
        const error_message = "No Collection Data!";
        callback && callback(error_message);
        reject(error_message);
      }
    });
  };

  /**
   * Mint NFT
   *
   * @param {string} userWallet Current user wallet address
   * @param {object} collectionData Collection Details (ie. abi, contract address, bytecode)
   * @param {object} itemData (NFT) Item details
   * @param {function} callback Callback function
   *
   * @returns {Promise} Receipt in Success or Error in Fail
   */
  mintNFT = (userWallet, collection, item, callback) => {
    return new Promise(async (resolve, reject) => {
      if (collection.abi && collection.data.contractAddress) {
        const contract = this.getContract(
          collection.abi,
          collection.data.contractAddress
        );

        if (!contract) {
          const error_message = "Invalid Contract";
          callback && callback(error_message);
          reject(error_message);
          return;
        }

        //set up transaction parameters
        const transactionParameters = {
          to: collection.data.contractAddress, // Required except during contract publications.
          from: userWallet, // must match user's active address.
          data: contract.methods
            .mintNFT(userWallet, item.token_uri)
            .encodeABI(),
        };

        try {
          let { receipt } = await this.eth_sendTransaction(
            transactionParameters,
            true
          );

          callback && callback(null, receipt);
          resolve(receipt);
        } catch (error) {
          callback && callback(error);
          reject(error);
        }
      }
    });
  };
  getCollectionInfo = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if(this.tokenContractSetting){
          const contract = this.getContract(
            this.tokenContractSetting.abi,
            this.tokenContractSetting.contractAddress
          );

          // let minHoldTime = await contract.methods.minHoldTime().call();
          // minHoldTime = MAKE_UNIX_DAYS(minHoldTime);

          // let maxHoldTime = await contract.methods.maxHoldTime().call();
          // maxHoldTime = MAKE_UNIX_DAYS(maxHoldTime);

          // let minReward = await contract.methods.minReward().call();
          // let maxReward = await contract.methods.maxReward().call();

          let supply = await contract.methods.totalSupply().call();
          supply = Number(this.web3.utils.fromWei(supply));

          let soldTokens = 0;
          try {
            soldTokens = await contract.methods.soldTokens().call();
            soldTokens = Number(this.web3.utils.fromWei(soldTokens));
          } catch (err) {}

          resolve({
            tokens: supply,
            sold: soldTokens,
            unsold: Number(supply) - Number(soldTokens),
          });
        }else{
          reject("")
        }
      } catch (err) {
        reject(err);
      }
    });
  };
}

export default Web3Intraction;

export const convertPriceToEther = (price) => {
  return Web3.utils.toWei(Number(price).toFixed(8), "ether");
};

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};


export const getNftMetadataAlchemy = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const alchemyConfig = {
        apiKey: params.networkUrl.apiKey, // Replace with your Alchemy API Key.
        network:
          params.blockchainNetworkMode === "testnet"
            ? Network.ETH_GOERLI
            : Network.ETH_MAINNET, // Replace with your network.
      };
      const alchemy = new Alchemy(alchemyConfig);
        const filters = {
          contractAddress: params.contractAddress,
          tokenId: params.tokenId,
        };
        console.log("filters",filters)

        const response = await alchemy.nft.getNftMetadata(
          params.contractAddress,
          params.tokenId
        );
        const owner = await alchemy.nft.getOwnersForNft(
          params.contractAddress,
          params.tokenId
        );
console.log("response",response)
      resolve({...response,owner:owner["owners"][0]});
    } catch (error) {
      console.log("Error in alchemy", error);
      reject(error);
    }
  });
};
