import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";

import {
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  CardHeader,
  Button,
  Spinner,
  Badge,
} from "reactstrap";
import Web3InteractionEth, { convertFromWei } from "util/web3InteractionEth";

// Actions
import { revealNftsRequest } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletHooks } from "util/walletHooks";

const initVal = {
  nft_type: "og_mint",
  tokens: [],
};

const RevealNfts = ({}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ items }) => ({
    loading: items.revealNftLoading,
  }));
  const [tokenId, setTokenId] = useState("");
  const [fields, setFields] = useState(initVal);

  const onClickReveaal = async () => {
    try {
      const { tokens, nft_type } = fields;
      dispatch(
        revealNftsRequest({ tokens: tokens, nft_type }, () => {
          setFields(initVal);
        })
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  const onHandleChange = (index) => (e) => {
    const { value } = e.target;
    setFields((prev) => ({
      ...prev,
      tokens: prev.tokens.map((token, idx) => (idx === index ? value : token)),
    }));
  };
  const onAddToken = () => {
    if (!tokenId) {
      return toastr.error("tokenId is required!");
    }
    if (fields.tokens.includes(tokenId)) {
      toastr.error("Token id already entered!");
      return;
    }
    const copy = [...fields.tokens];

    copy.push(tokenId?.toString());
    setFields((pre) => ({ ...pre, tokens: copy }));
  };

  const onRemoveTokenId = (index) => {
    setFields((pre) => ({
      ...pre,
      tokens: pre.tokens.filter((_, idx) => index !== idx),
    }));
  };

  return (
    <Card>
      <CardHeader className="bg-primary text-white">
        SYNDICATE NFT REVEAL
      </CardHeader>
      <CardBody className="spinner-content">
        <Form>
          <Col sm={12} lg={12}>
            <Form>
              <Row>
                <Col md={4} lg={4}>
                  <FormGroup>
                    <Label>Token Id</Label>

                    <Input
                      type="text"
                      placeholder="Please enter token id"
                      name="token_id"
                      value={tokenId}
                      onChange={(e) => setTokenId(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Button
                      className="mt-4"
                      onClick={onAddToken}
                      type="button"
                      color="primary"
                    >
                      Add
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-baseline flex-wrap " lg="12">
                  {fields.tokens.map((token, index) => (
                    <Badge className="px-2 m-1" color="primary">
                      {token}{" "}
                      <svg
                        style={{ cursor: "pointer" }}
                        onClick={() => onRemoveTokenId(index)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M16.066 8.995a.75.75 0 1 0-1.06-1.061L12 10.939L8.995 7.934a.75.75 0 1 0-1.06 1.06L10.938 12l-3.005 3.005a.75.75 0 0 0 1.06 1.06L12 13.06l3.005 3.006a.75.75 0 0 0 1.06-1.06L13.062 12z"
                        />
                      </svg>
                    </Badge>
                  ))}
                </Col>
              </Row>

              <Col md={6}>
                <FormGroup>
                  <Button
                    disabled={loading}
                    onClick={onClickReveaal}
                    type="button"
                    color="primary"
                  >
                    {loading && <Spinner size={"sm"} color="light" />} Reveal
                  </Button>
                </FormGroup>
              </Col>
            </Form>
          </Col>
        </Form>
      </CardBody>
    </Card>
  );
};

export default withRouter(RevealNfts);
