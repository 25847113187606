import {
  CATEGORY_API_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_CATEGORY,
  ADD_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
  PUT_CATEGORY,
  PUT_CATEGORY_FAIL,
  PUT_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  PUT_CATEGORIES_STATUS,
  PUT_CATEGORIES_STATUS_FAIL,
  PUT_CATEGORIES_STATUS_SUCCESS,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: CATEGORY_API_FAIL,
  payload: error,
});

/* Get items */
export const getCategories = (data) => ({
  type: GET_CATEGORIES,
  payload: data,
});

export const getCategoriesSuccess = (items) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: items,
});

export const getCategoriesFail = (error) => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
});
/* Get Categories END */

/* Add Category */
export const addCategory = (item, history) => ({
  type: ADD_CATEGORY,
  payload: { item, history },
});

export const addCategoryFail = (error) => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
});

export const addCategorySuccess = () => ({
  type: ADD_CATEGORY_SUCCESS,
});
/* Add Category END */

/* Get Category */
export const getCategory = (id) => ({
  type: GET_CATEGORY,
  payload: id,
});

export const getCategoryFail = (error) => ({
  type: GET_CATEGORY_FAIL,
  payload: error,
});

export const getCategorySuccess = (item) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: item,
});
/* Get Category END */

/* Update Category */
export const putCategory = (data, history) => ({
  type: PUT_CATEGORY,
  payload: { data, history },
});

export const putCategoryFail = (error) => ({
  type: PUT_CATEGORY_FAIL,
  payload: error,
});

export const putCategorySuccess = () => ({
  type: PUT_CATEGORY_SUCCESS,
});
/* Update Category END */

/* Delete Category */
export const deleteCategory = (data, callback) => ({
  type: DELETE_CATEGORY,
  payload: { data, callback },
});

export const deleteCategoryFail = (error) => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
});

export const deleteCategorySuccess = () => ({
  type: DELETE_CATEGORY_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Categories Status */
export const putCategoriesStatus = (data, callback) => ({
  type: PUT_CATEGORIES_STATUS,
  payload: { data, callback },
});

export const putCategoriesStatusFail = (error) => ({
  type: PUT_CATEGORIES_STATUS_FAIL,
  payload: error,
});

export const putCategoriesStatusSuccess = () => ({
  type: PUT_CATEGORIES_STATUS_SUCCESS,
});
/* Update Multi Categories Status END */
