import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Instructions from "./Instructions";

const SocialMediaSettings = (props) => {
  const { fields, handleChange } = props;

  return (
    <React.Fragment>
      <Row>
        <Col lg={3}>
          <Instructions t={props.t} />
        </Col>

        <Col lg={9}>
          <Row>
            {/* <Col md={6}>
              <FormGroup>
                <Label>Facebook</Label>

                <Input
                  type="text"
                  value={fields.facebook}
                  onChange={handleChange("facebook")}
                />
              </FormGroup>
            </Col> */}
            <Col md={6}>
              <FormGroup>
                <Label>Opensea</Label>

                <Input
                  type="text"
                  value={fields.opensea}
                  onChange={handleChange("opensea")}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Tiktok</Label>

                <Input
                  type="text"
                  value={fields.tiktok}
                  onChange={handleChange("tiktok")}
                />
              </FormGroup>
            </Col>

            {/* <Col md={6}>
              <FormGroup>
                <Label>Vimeo</Label>

                <Input
                  type="text"
                  value={fields.vimeo}
                  onChange={handleChange("vimeo")}
                />
              </FormGroup>
            </Col> */}

            <Col md={6}>
              <FormGroup>
                <Label>Twitter</Label>

                <Input
                  type="text"
                  value={fields.twitter}
                  onChange={handleChange("twitter")}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Youtube</Label>

                <Input
                  type="text"
                  value={fields.youtube}
                  onChange={handleChange("youtube")}
                />
              </FormGroup>
            </Col>

            {/* <Col md={6}>
              <FormGroup>
                <Label>Telegram</Label>

                <Input
                  type="text"
                  value={fields.telegram}
                  onChange={handleChange("telegram")}
                />
              </FormGroup>
            </Col> */}
            <Col md={6}>
              <FormGroup>
                <Label>Discord</Label>

                <Input
                  type="text"
                  value={fields.discord}
                  onChange={handleChange("discord")}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Playstore</Label>

                <Input
                  type="text"
                  value={fields.playstore}
                  onChange={handleChange("playstore")}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Amazon</Label>

                <Input
                  type="text"
                  value={fields.amazon}
                  onChange={handleChange("amazon")}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

SocialMediaSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SocialMediaSettings)
);
