import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import FormButton from "components/Common/FormButtons";
import Dropzone from "react-dropzone";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  FormText,
} from "reactstrap";

import { uploadFile, addgallery, getgallery, putgallery } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

// Images
import avatar4 from "assets/images/logo-placeholder.png";

const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddCategory,
  onGetCategory,
  onPutCategory,
  category,
  onUploadFile,
  loading,
  ...props
}) => {
  const { category_id } = useParams();

  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    name: "",
    rarity: "",
    image: "",
    status: "active",
  });
  const [selectedFiles, setselectedFiles] = useState({});

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  console.log(category_id, 45656456);
  useEffect(() => {
    if (category_id) {
      onGetCategory(category_id);
    }
  }, [category_id]);

  useEffect(() => {
    if (category_id && !!category) {
      try {
        setFields({
          name: category.name || "",
          image: category.image?._id,
          rarity: category.rarity,
          status: category.status || "active",
        });

        let images = {};

        if (category.image?.link) {
          images.image = { preview: category.image.link };
        }

        setselectedFiles(images);
      } catch (err) {
        console.log("parsing error ", err);
      }
    }
  }, [category_id, JSON.stringify(category)]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const uploadFileSuccess = (name) => (response) => {
    const { _id: image } = response.data;

    setFields((prevState) => ({ ...prevState, [name]: image }));
  };

  function handleAcceptedFiles(name, _files) {
    if (category_id && !accesses.canEdit) return;

    const files = _files?.filter((file) => file.size < 101288000);

    if (files.length < _files.length) {
      return toastr.error(props.t("Max. upload file size: 100MB"));
    }

    onUploadFile({ image: files[0] }, uploadFileSuccess(name));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setselectedFiles((prevState) => ({ ...prevState, [name]: files[0] }));
  }

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (category_id) {
      if (!accesses.canEdit) return;
      onPutCategory({ category_id: category._id, ...fields }, history);
    } else {
      if (!accesses.canAdd) return;
      onAddCategory({ ...fields }, history);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Categorys"
            breadcrumbItem={
              (category_id ? props.t("edit") : props.t("add")) +
              " " +
              props.t("Category")
            }
            breadcrumbItems={[
              { title: props.t("Categories"), link: "/categories" },
              { title: category_id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Name")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.name}
                            onChange={handleChange("name")}
                            required
                          />
                        </FormGroup>
                      </Col>{" "}
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Rarity")}
                          </Label>
                          <Input
                            type="number"
                            id="formrow-firstname-Input"
                            value={fields.rarity}
                            placeholder="in %"
                            onChange={handleChange("rarity")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-profile-image-Input">
                            {props.t("Image")}
                          </Label>

                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("image", acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles.image;

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded avatar-xl"
                                    alt={
                                      !!imageFile && imageFile.name
                                        ? imageFile.name
                                        : "hlc"
                                    }
                                    src={
                                      !!imageFile ? imageFile.preview : avatar4
                                    }
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>

                          <FormText>Max. upload file size: 100MB</FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      needSubmit={
                        category_id ? accesses.canEdit : accesses.canAdd
                      }
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  category: PropTypes.object,
  onAddCategory: PropTypes.func,
  onGetCategory: PropTypes.func,
  onPutCategory: PropTypes.func,
};

const mapStateToProps = ({ gallery, File }) => ({
  error: gallery.error,
  loading: gallery.loading || File.loading,
  category: gallery.item,
});

const mapDispatchToProps = (dispatch) => ({
  onAddCategory: (data, history) => dispatch(addgallery(data, history)),
  onGetCategory: (id) => dispatch(getgallery(id)),
  onPutCategory: (data, history) => dispatch(putgallery(data, history)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);
