// Add Teams
export const POST_TEAM = "POST_TEAM";
export const POST_TEAM_FAIL = "POST_TEAM_FAIL";
export const POST_TEAM_SUCCESS = "POST_TEAM_SUCCESS";

// Get Teams List
export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";

// get Team View
export const GET_TEAM = "GET_TEAM";
export const GET_TEAM_FAIL = "GET_TEAM_FAIL";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";

// update Team
export const PUT_TEAM = "PUT_TEAM";
export const PUT_TEAM_FAIL = "PUT_TEAM_FAIL";
export const PUT_TEAM_SUCCESS = "PUT_TEAM_SUCCESS";

// delete Team
export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
