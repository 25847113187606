import * as TYPES from "./actionTypes";

export const editProfile = (user, history) => {
  return {
    type: TYPES.EDIT_PROFILE,
    payload: { user, history },
  };
};

export const profileSuccess = (msg) => {
  return {
    type: TYPES.PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: TYPES.PROFILE_ERROR,
    payload: error,
  };
};

export const putChangePwd = (data, callback) => {
  return {
    type: TYPES.CHANGE_PASSWORD,
    payload: { data, callback },
  };
};

export const putChangePwdSuccess = (msg) => {
  return {
    type: TYPES.CHANGE_PASSWORD_SUCCESS,
    payload: msg,
  };
};

export const putChangePwdError = (error) => {
  return {
    type: TYPES.CHANGE_PASSWORD_FAIL,
    payload: error,
  };
};
