import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { Alert, Spinner, FormGroup, Form, Input } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import logodark from "../../assets/images/logoopen.png";
import logodark2x from "../../assets/images/logoopen.png";
import logoheader from "../../assets/images/logo.png";
import logoheader2x from "../../assets/images/logoopen.png";

// actions
import { registerUser, apiError, uploadFile } from "store/actions";

// import images
// import logo from "assets/images/logo.png";

const Login = (props) => {
  const { loading } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [validationError, setValidationError] = useState();
  const myMessage = useRef(null);

  const [fields, setfields] = useState({
    email: "",
    password: "",
    name: "",
    firstName: "",
    lastName: "",
    companyLegalName: "",
    billingAddress: "",
    streetAddress: "",
    streetAddress: "",
    businessEntityType: "",
    federalTaxIdNumber: "",
    numberOfItemsOnIshopMeta: "",
    typeOfProductAndCategories: "",
    businessTime: "",
    hearAboutUs: "",
    presidentFirstName: "",
    presidentLastName: "",
    presidentPhoneNumber: "",
    presidentEmail: "",
    resaleLicenseNumber: "",
    AccountPayableFirstName: "",
    AccountPayableLastName: "",
    AccountPayableEmail: "",
    AccountPayablePhoneNumber: "",
    webManagerFirstName: "",
    webManagerLastName: "",
    webManagerEmail: "",
    webManagerPhoneNumber: "",
    customerServiceFirstName: "",
    customerServiceLastName: "",
    customerServiceEmail: "",
    customerServicePhoneNumber: "",
    escalatedCustomerServiceFirstName: "",
    escalatedCustomerServiceLastName: "",
    escalatedCustomerServiceEmail: "",
    escalatedCustomerServicePhoneNumber: "",
    W9: "",
    Resale_Certificate: "",
    Business_License: "",
    DBA: "",
    title: "",
    address: "",
    postCode: "",
    city: "",
    state: "",
    country: "",
    number: ""
  });
  const changeHandler = (event) => {
    console.log("event ==> ", event.target.files[0])
    setSelectedFile(event.target.files[0]);
    props.onUploadFile({ image: event.target.files[0] }, uploadFileSuccess(event.target.name));
  };
  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;
    console.log("response ===> ", response)
    handleChange(name)({ target: { value: _id } });
  };
  useEffect(() => {
    props.apiError();
  }, []);

  const handleChange = (name) => (e) => {
    const { value } = e.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };
  const onChangeValue = (event) => {
    setfields((prevState) => ({ ...prevState, businessEntityType: event.target.value }));
  }
  const onChangeValueHearAboutUs = (event) => {
    setfields((prevState) => ({ ...prevState, hearAboutUs: event.target.value }));
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("fields ===> ",fields)
    if (!fields.email) return (setValidationError("Email is required!"),myMessage.current.scrollIntoView())
    if (!fields.password) return (setValidationError("Password is required!"),myMessage.current.scrollIntoView())
    if (!fields.firstName) return (setValidationError("First Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.lastName) return (setValidationError("Last Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.companyLegalName) return (setValidationError("Company Legal Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.billingAddress) return (setValidationError("Billing Address is required!"),myMessage.current.scrollIntoView())
    if (!fields.streetAddress) return (setValidationError("Street Address is required!"),myMessage.current.scrollIntoView())
    if (!fields.businessEntityType) return (setValidationError("Business Entity Type is required!"),myMessage.current.scrollIntoView())
    if (!fields.federalTaxIdNumber) return (setValidationError("Federal Tax Id Number is required!"),myMessage.current.scrollIntoView())
    if (!fields.numberOfItemsOnIshopMeta) return (setValidationError("Number Of Items On IshopMeta is required!"),myMessage.current.scrollIntoView())
    if (!fields.typeOfProductAndCategories) return (setValidationError("Type Of Product And Categories is required!"),myMessage.current.scrollIntoView())
    if (!fields.hearAboutUs) return (setValidationError("Where did you hear about is required!"),myMessage.current.scrollIntoView())
    if (!fields.presidentFirstName) return (setValidationError("President First Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.presidentLastName) return (setValidationError("President Last Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.presidentPhoneNumber) return (setValidationError("President Phone Number is required!"),myMessage.current.scrollIntoView())
    if (!fields.presidentEmail) return (setValidationError("President Email is required!"),myMessage.current.scrollIntoView())
    if (!fields.resaleLicenseNumber) return (setValidationError("Resale License Number is required!"),myMessage.current.scrollIntoView())
    if (!fields.AccountPayableFirstName) return (setValidationError("Account Payable First Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.AccountPayableLastName) return (setValidationError("Account Payable LastName is required!"),myMessage.current.scrollIntoView())
    if (!fields.AccountPayableEmail) return (setValidationError("Account Payable Email is required!"),myMessage.current.scrollIntoView())
    if (!fields.AccountPayablePhoneNumber) return (setValidationError("Account Payable Phone Number is required!"),myMessage.current.scrollIntoView())
    if (!fields.webManagerFirstName) return (setValidationError("Web Manager First Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.webManagerLastName) return (setValidationError("Web Manager Last Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.webManagerEmail) return (setValidationError("Web Manager Email is required!"),myMessage.current.scrollIntoView())
    if (!fields.webManagerPhoneNumber) return (setValidationError("Web Manager Phone Number is required!"),myMessage.current.scrollIntoView())
    if (!fields.customerServiceFirstName) return (setValidationError("Customer Service First Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.customerServiceLastName) return (setValidationError("Customer Service Last Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.customerServiceEmail) return (setValidationError("Customer Service Email is required!"),myMessage.current.scrollIntoView())
    if (!fields.customerServicePhoneNumber) return (setValidationError("Customer Service Phone Number is required!"),myMessage.current.scrollIntoView())
    if (!fields.escalatedCustomerServiceFirstName) return (setValidationError("Escalated Customer Service First Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.escalatedCustomerServiceLastName) return (setValidationError("Escalated Customer Service Last Name is required!"),myMessage.current.scrollIntoView())
    if (!fields.escalatedCustomerServiceEmail) return (setValidationError("Escalated Customer Service Email is required!"),myMessage.current.scrollIntoView())
    if (!fields.escalatedCustomerServicePhoneNumber) return (setValidationError("Escalated Customer Service Phone Number is required!"),myMessage.current.scrollIntoView())
    if (!fields.W9) return (setValidationError("W9 is required!"),myMessage.current.scrollIntoView())
    if (!fields.Resale_Certificate) return (setValidationError("Resale Certificate is required!"),myMessage.current.scrollIntoView())
    if (!fields.Business_License) return (setValidationError("Business License is required!"),myMessage.current.scrollIntoView())
    if (!fields.DBA) return (setValidationError("DBA is required!"),myMessage.current.scrollIntoView())
    if (!fields.title) return (setValidationError("Title is required!"),myMessage.current.scrollIntoView())
    // if (!fields.address) return (setValidationError("address is required!"),myMessage.current.scrollIntoView())
    if (!fields.postCode) return (setValidationError("Post Code is required!"),myMessage.current.scrollIntoView())
    if (!fields.city) return (setValidationError("City is required!"),myMessage.current.scrollIntoView())
    if (!fields.state) return (setValidationError("State is required!"),myMessage.current.scrollIntoView())
    if (!fields.country) return (setValidationError("Country is required!"),myMessage.current.scrollIntoView())
    if (!fields.number) return (setValidationError("Phone Number is required!"),myMessage.current.scrollIntoView())
    // fields.name = fields.firstName + ' ' + fields.lastName
    let finalData = {
      ...fields,
      name: fields.firstName + ' ' + fields.lastName
    }
    props.registerUser(finalData, props.history);
  };

  return (
    <section id="login">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="sign-in-top">
              <img src={logodark} alt="" className="img-fluid registerlogo" />
            </div>
          </div>
        </div>

        <Form
          id="newsignupform"
          onSubmit={() => handleSubmit()}
          className="spinner-content position-relative"
        >
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>)}
          <div ref={myMessage} >
            {validationError || props.error && typeof props.error === "string" ? (
              <Alert color="danger">{validationError || props.error}</Alert>
            ) : null}
          </div>
         

          <div className="row">
            <div className="col-lg-12">
              <h1>Sign Up</h1>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Name</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.firstName}
                      onChange={handleChange("firstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.lastName}
                      onChange={handleChange("lastName")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <FormGroup>

              <div className="input-group">
                <label>Title</label>
                  <Input
                    type="text"
                    placeholder="Title"
                    value={fields?.title}
                    onChange={handleChange("title")}
                    required
                  />
              </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">  <FormGroup>
              <div className="input-group">
                <label>Email</label>
                  <Input
                    type="text"
                    placeholder="Your email"
                    value={fields?.email}
                    onChange={handleChange("email")}
                    required
                  />
              </div>
            </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Company Legal name</label>
                  <Input
                    type="text"
                    placeholder="Company Legal name"
                    value={fields?.companyLegalName}
                    onChange={handleChange("companyLegalName")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>DBA</label>
                  <Input
                    type="text"
                    placeholder="DBA"
                    value={fields?.DBA}
                    onChange={handleChange("DBA")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Billing Address</label>
                  <Input
                    type="text"
                    placeholder="Billing Address"
                    value={fields?.billingAddress}
                    onChange={handleChange("billingAddress")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Street Address</label>
                  <Input
                    type="text"
                    placeholder="Street Address"
                    value={fields?.streetAddress}
                    onChange={handleChange("streetAddress")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Street Address2</label>
                  <Input
                    type="text"
                    placeholder="Street Address2"
                    value={fields?.streetAddress2}
                    onChange={handleChange("streetAddress2")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>City</label>
                  <Input
                    type="text"
                    placeholder="Your City"
                    value={fields?.city}
                    onChange={handleChange("city")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>State</label>
                  <Input
                    type="text"
                    placeholder="Your State"
                    value={fields?.state}
                    onChange={handleChange("state")}
                    required
                  />
                </div>
              </FormGroup>
            </div>

            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Postal / Zip Code</label>
                  <Input
                    type="text"
                    placeholder="Your Post Code"
                    value={fields?.postCode}
                    onChange={handleChange("postCode")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Country</label>
                  <Input
                    type="text"
                    placeholder="Your Country"
                    value={fields?.country}
                    onChange={handleChange("country")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Phone number</label>
                  <Input
                    type="text"
                    placeholder="Your Phone number"
                    value={fields?.number}
                    onChange={handleChange("number")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <div className="input-group">
                <label>Business Entity Type</label>
                <div onChange={onChangeValue}>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="Sole Proprietorship" name="BusinessEntityType" /> Sole Proprietorship</div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="S Corporation" name="BusinessEntityType" /> S Corporation</div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="Partnership" name="BusinessEntityType" /> Partnership </div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="Limited Liability Company (LLC)" name="BusinessEntityType" /> Limited Liability Company (LLC) </div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="C Corporation" name="BusinessEntityType" /> C Corporation </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Federal Tax ID Number</label>
                  <Input
                    type="text"
                    placeholder="Federal Tax ID Number"
                    value={fields?.federalTaxIdNumber}
                    onChange={handleChange("federalTaxIdNumber")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Resale License Number</label>
                  <Input
                    type="text"
                    placeholder="Resale License Number"
                    value={fields?.resaleLicenseNumber}
                    onChange={handleChange("resaleLicenseNumber")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>How many items are you planning to list on iShopMeta?</label>
                  <Input
                    type="text"
                    placeholder="How many items are you planning to list on iShopMeta?"
                    value={fields?.numberOfItemsOnIshopMeta}
                    onChange={handleChange("numberOfItemsOnIshopMeta")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>What type of products and categories?</label>
                  <Input
                    type="text"
                    placeholder="What type of products and categories?"
                    value={fields?.typeOfProductAndCategories}
                    onChange={handleChange("typeOfProductAndCategories")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>How long you have been in business?</label>
                  <Input
                    type="text"
                    placeholder="How long you have been in business?"
                    value={fields?.businessTime}
                    onChange={handleChange("businessTime")}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <div className="input-group">
                <label>Where did you hear about us?</label>
                <div onChange={onChangeValueHearAboutUs}>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="A Sales Representative" name="hearAboutUs" /> A Sales Representative</div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="A Friend or Colleague" name="hearAboutUs" /> A Friend or Colleague</div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="Google" name="hearAboutUs" /> Google </div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="Blog Post" name="hearAboutUs" /> Blog Post </div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="News Article" name="hearAboutUs" /> News Article </div>
                  <div className="col-lg-12"><Input className="redio-button" type="radio" value="Social Media" name="hearAboutUs" /> Social Media </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-group">
                <label>President/CEO</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.presidentFirstName}
                      onChange={handleChange("presidentFirstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.presidentLastName}
                      onChange={handleChange("presidentLastName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={fields?.presidentEmail}
                      onChange={handleChange("presidentEmail")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Phone number"
                      value={fields?.presidentPhoneNumber}
                      onChange={handleChange("presidentPhoneNumber")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Accounts Payable</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.AccountPayableFirstName}
                      onChange={handleChange("AccountPayableFirstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.AccountPayableLastName}
                      onChange={handleChange("AccountPayableLastName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={fields?.AccountPayableEmail}
                      onChange={handleChange("AccountPayableEmail")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Phone number"
                      value={fields?.AccountPayablePhoneNumber}
                      onChange={handleChange("AccountPayablePhoneNumber")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Web Manager</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.webManagerFirstName}
                      onChange={handleChange("webManagerFirstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.webManagerLastName}
                      onChange={handleChange("webManagerLastName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={fields?.webManagerEmail}
                      onChange={handleChange("webManagerEmail")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Phone number"
                      value={fields?.webManagerPhoneNumber}
                      onChange={handleChange("webManagerPhoneNumber")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>


            <div className="col-lg-12">
              <div className="input-group">
                <label>Customer Service</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.customerServiceFirstName}
                      onChange={handleChange("customerServiceFirstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.customerServiceLastName}
                      onChange={handleChange("customerServiceLastName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={fields?.customerServiceEmail}
                      onChange={handleChange("customerServiceEmail")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Phone number"
                      value={fields?.customerServicePhoneNumber}
                      onChange={handleChange("customerServicePhoneNumber")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>


            <div className="col-lg-12">
              <div className="input-group">
                <label>Escalated Customer Service</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.escalatedCustomerServiceFirstName}
                      onChange={handleChange("escalatedCustomerServiceFirstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.escalatedCustomerServiceLastName}
                      onChange={handleChange("escalatedCustomerServiceLastName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={fields?.escalatedCustomerServiceEmail}
                      onChange={handleChange("escalatedCustomerServiceEmail")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Phone number"
                      value={fields?.escalatedCustomerServicePhoneNumber}
                      onChange={handleChange("escalatedCustomerServicePhoneNumber")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>


            <div className="col-lg-12">
              <div className="input-group">
                <label>Orders Notifications</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.orderNotificationsFirstName}
                      onChange={handleChange("orderNotificationsFirstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.orderNotificationsLastName}
                      onChange={handleChange("orderNotificationsLastName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={fields?.orderNotificationsEmail}
                      onChange={handleChange("orderNotificationsEmail")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Returns</label>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={fields?.returnsFirstName}
                      onChange={handleChange("escalatedCustomerServiceFirstName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={fields?.returnsLastName}
                      onChange={handleChange("returnsLastName")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={fields?.returnsEmail}
                      onChange={handleChange("returnsEmail")}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Phone number"
                      value={fields?.returnsPhoneNumber}
                      onChange={handleChange("returnsPhoneNumber")}
                      required
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <label>W9</label>
                <Input className="signupImage" type="file" name="W9" onChange={changeHandler} required />
              </FormGroup>
            </div>
            <div className="col-lg-12">
              <FormGroup>
              <label>Resale Certificate </label>
                <Input className="signupImage" type="file" name="Resale_Certificate" onChange={changeHandler} required />
              </FormGroup></div>
            <div className="col-lg-12">
              <FormGroup>
              <label>Business License</label>
                <Input className="signupImage" type="file" name="Business_License" onChange={changeHandler} required />
              </FormGroup></div>
            <div className="col-lg-12">
              <FormGroup>
              <div className="input-group">
                <label>Password</label>
                  <Input
                    type="password"
                    placeholder="Enter Password"
                    value={fields?.password}
                    onChange={handleChange("password")}
                    required
                  />
              </div>
              </FormGroup>
              <div className="forgor-password">
                <Link to="/login" className="colortextlink">
                  Already Have An Account?
                </Link>
              </div>
            </div>

            <div className="col-lg-12">
              <button
                type="submit"
                className="themebtn fullwidthbtn"
                id="nextBtn"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </Form>

        {/* <div className="col-lg-12">
          <div className="reglink">
            <Link to="/signup" className="colortextlink">
              Don't have an account?
            </Link>
          </div>
        </div> */}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  registerUser: PropTypes.func,
  onUploadFile: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  registerUser: (data, callback) => dispatch(registerUser(data, callback)),
  apiError,
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
