import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Activity Redux States
import {
  ADD_EQUIP_ACTIVITY_REQUEST,
  GET_ACTIVITIES,
  // ADD_ACTIVITY,
  GET_ACTIVITY,
  GET_EQUIP_ACTIVITIES_REQUEST,
  REMOVE_EQUIP_ACTIVITY_REQUEST,
  // PUT_ACTIVITY,
  // DELETE_ACTIVITY,
  // PUT_ACTIVITIES_STATUS,
} from "./actionTypes";
import {
  addEquipActivityFail,
  addEquipActivitySuccess,
  getActivitiesFail,
  getActivitiesSuccess,
  // addActivityFail,
  // addActivitySuccess,
  getActivityFail,
  getActivitySuccess,
  getEquipmentActivitiesFail,
  getEquipmentActivitiesRequest,
  getEquipmentActivitiesSuccess,
  removeEquipActivityFail,
  removeEquipActivitySuccess,
  // putActivityFail,
  // putActivitySuccess,
  // deleteActivityFail,
  // deleteActivitySuccess,
  // putActivitiesStatusFail,
  // putActivitiesStatusSuccess,
} from "./actions";

import {
  getActivities,
  getEquipmentActivities,
  // postActivity,
  getItem,
  postEquipmentActivity,
  removeEquipmentActivity,
  // putActivity,
  // deleteActivity,
  // putActivitiesStatus,
} from "helpers/backend_helper";
import toastr from "toastr";
function* fetchActivities({ payload }) {
  try {
    const response = yield call(getActivities, payload);

    if (response.status == "failure") {
      return yield put(getActivitiesFail(response.message));
    }

    yield put(getActivitiesSuccess(response));
  } catch (error) {
    yield put(getActivitiesFail(error));
  }
}

function* onAddNewActivity({ payload: { item, history } }) {
  try {
    const response = yield call(postActivity, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addActivityFail(response.message));
    }

    history && history.goBack();

    yield put(addActivitySuccess());
  } catch (error) {
    yield put(addActivityFail("Internal Error!"));
  }
}

function* fetchActivity({ payload }) {
  try {
    console.log("call fetchActivity");
    const response = yield call(getItem, payload);

    if (response.status == "failure") {
      return yield put(getActivityFail(response.message));
    }
    console.log("asajknsjans", response);
    yield put(getActivitySuccess(response.data));
  } catch (error) {
    yield put(getActivityFail(error));
  }
}

function* onPutActivity({ payload: { data, history } }) {
  try {
    const response = yield call(putActivity, data);

    if (response.status == "failure") {
      return yield put(putActivityFail(response.message));
    }

    history && history.goBack();

    yield put(putActivitySuccess());
  } catch (error) {
    yield put(putActivityFail("Internal Error!"));
  }
}

function* onDeleteActivity({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteActivity, data);

    if (response.status == "failure") {
      return yield put(deleteActivityFail(response.message));
    }

    yield put(deleteActivitySuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteActivityFail("Internal Error!"));
  }
}

function* onPutActivitiesStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putActivitiesStatus, data);

    if (response.status == "failure") {
      return yield put(putActivitiesStatusFail(response.message));
    }

    yield put(putActivitiesStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putActivitiesStatusFail("Internal Error!"));
  }
}


function* fetchEquipmentActivities({ payload }) {
  try {
    const response = yield call(getEquipmentActivities, payload);

    if (response.status == "failure") {
      return yield put(getEquipmentActivitiesFail(response.message));
    }

    yield put(getEquipmentActivitiesSuccess(response));
  } catch (error) {
    yield put(getEquipmentActivitiesFail(error));
  }
}

function* onAddEquipmentActivity({ payload: { item, callback } }) {
  try {
    const response = yield call(postEquipmentActivity, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      toastr.error(response.message)
      return yield put(addEquipActivityFail(response.message));
    }

    callback && callback();
    toastr.success("Equipment activity added!")
    yield put(addEquipActivitySuccess(response));
  } catch (error) {
    toastr.error("Internal Error!")
    yield put(addEquipActivityFail("Internal Error!"));
  }
}



function* onRemoveEquipmentActivity({ payload: { item, callback } }) {
  try {
    const response = yield call(removeEquipmentActivity, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      toastr.error(response.message)
      return yield put(removeEquipActivityFail(response.message));
    }

    callback && callback();
    toastr.success("Equipment activity removed!")
    yield put(removeEquipActivitySuccess(response));
  } catch (error) {
    toastr.error("Internal Error!")
    yield put(removeEquipActivityFail("Internal Error!"));
  }
}

function* ActivitiesSaga() {
  yield takeEvery(GET_ACTIVITIES, fetchActivities);
  // yield takeEvery(ADD_ACTIVITY, onAddNewActivity)
  yield takeEvery(GET_ACTIVITY, fetchActivity);
  // yield takeEvery(PUT_ACTIVITY, onPutActivity)
  // yield takeEvery(DELETE_ACTIVITY, onDeleteActivity)
  // yield takeEvery(PUT_ACTIVITIES_STATUS, onPutActivitiesStatus)
  yield takeLatest(GET_EQUIP_ACTIVITIES_REQUEST,fetchEquipmentActivities),
  yield takeLatest(ADD_EQUIP_ACTIVITY_REQUEST,onAddEquipmentActivity),
  yield takeLatest(REMOVE_EQUIP_ACTIVITY_REQUEST,onRemoveEquipmentActivity)

}

export default ActivitiesSaga;
