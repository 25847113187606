import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import Blockchain from "components/Select/Blockchain";

import {
  addCollection,
  getCollection,
  uploadFile,
  removeFile,
} from "store/actions";

// Images
import avatar4 from "assets/images/users/avatar-9.jpg";

//i18n
import { withTranslation } from "react-i18next";

const AddEdit = ({
  history,
  error,
  loading,
  collection,
  onGetCollection,
  onUploadFile,
  settings,
  ...props
}) => {
  const { id } = useParams();

  const didMountRef = useRef();

  const [fields, setFields] = useState({
    name: "",
    blockchain: "ethereum",
    image: null,
    maxSupply: "",
    description: "",
    cost: "",
    royalityFee: "",
    artist: "",
    status: "active",
    symbol: "",
    type: ""
  });
  const [selectedFiles, setselectedFiles] = useState([]);

  useEffect(() => {
    if (id) {
      onGetCollection(id);
    }
  }, [id]);


  // useEffect(() => {
  //   if (didMountRef.current) {
  //     try {
  //       setFields({
  //         name: collection.name || "",
  //         symbol: collection?.symbol || "",
  //         blockchain: collection?.blockchain,
  //         maxSupply: collection?.maxSupply,
  //         description: collection?.description || "",
  //         status: collection?.status || "active",
  //         image: collection?.image?._id,
  //         isBanner: collection.isBanner,
  //         isFeatured: collection.isFeatured,
  //         type : collection?.type
  //       });

  //       if (collection?.image) {
  //         setselectedFiles({ preview: collection.image.link });
  //       }
  //     } catch (err) {
  //       console.log("parsing error ", err);
  //     }
  //   } else didMountRef.current = true;
  // }, [JSON.stringify(collection)]);

  const uploadFileSuccess = (response) => {
    setFields((prevState) => ({ ...prevState, image: response.data._id }));
  };

  function handleAcceptedFiles(files) {
    onUploadFile({ image: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setselectedFiles(files);
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    //og_contract_address is contract address of og mint nfts contract for type claim_weapon
    props.onAddCollection(fields, history);
  };

  const collectionTypes = [
    { label: "Og Mint", value: "og_mint" },
    { label: "Claim Weapon", value: "claim_weapon" },
    { label: "Claim Villain", value: "claim_villain" },
    { label: "Claim Origin", value: "claim_origin" },
    { label: "Special Powers", value: "special-power" },
    { label: "Weapons and Gear", value: "weapons-and-gear" },
    { label: "Armors", value: "armors" }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={1} lg={2}></Col>
            <Col sm={10} lg={8}>
              <Breadcrumbs
                title="Collections"
                breadcrumbItem={props.t("Add") + " " + props.t("Collections")}
                breadcrumbItems={[
                  { title: props.t("Collection"), link: "/collections" },
                  { title: props.t(id ? "Edit" : "Add") },
                ]}
              />

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Form onSubmit={onSubmit} className="spinner-content">
                        {error && typeof error === "string" ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}

                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Blockchain")}
                              </Label>

                              <Blockchain
                                value={fields.blockchain}
                                handleChange={(data) =>
                                  handleChange("blockchain")({ target: data })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Name")}
                              </Label>

                              <Input
                                type="text"
                                id="formrow-firstname-Input"
                                value={fields.name}
                                onChange={handleChange("name")}
                                required
                              />
                            </FormGroup>
                          </Col>

                          {/* <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Chain")}</Label>

                              <Input
                                type="text"
                                readOnly
                                value={fields.blockchain}
                                onChange={handleChange("blockchain")}
                              >
                                <option value="ethereum">Ethereum</option>
                                <option value="BNB">Binance Smart Chain</option>
                              </Input>
                            </FormGroup>
                          </Col> */}

                          <Col md={6}>
                            <FormGroup>
                              <Label>{props.t("Symbol")}</Label>
                              <Input
                                type="text"
                                style={{ textTransform: "upperCase" }}
                                value={fields.symbol}
                                onChange={handleChange("symbol")}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="formrow-firstname-Input">
                                {props.t("Type ")}
                              </Label>

                              <Select
                                options={collectionTypes}
                                value={collectionTypes.find((item) => item?.value === fields?.type)}
                                onChange={(data) =>
                                  handleChange("type")({ target: data })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup>
                              <Label>{props.t("Description")}</Label>

                              <Input
                                type="textarea"
                                value={fields?.description}
                                onChange={handleChange("description")}
                              />
                            </FormGroup>
                          </Col>
                          {fields?.type !== "claim_weapon" && fields?.type !== "armors"
                            &&
                            <>
                              {(fields?.type !== "claim_villain" || fields?.type !== "claim_origin") &&
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>{props.t("Cost")}</Label>

                                    <InputGroup>
                                      <Input
                                        type="number"
                                        value={fields.cost}
                                        onChange={handleChange("cost")}
                                        min={0}
                                        step={0.01}
                                        required
                                      />

                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          id="basic-addon1"
                                        >
                                          ETH
                                        </span>
                                      </div>
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                              }
                              <Col md={6}>
                                <FormGroup>
                                  <Label>{props.t("Royality")}</Label>

                                  <InputGroup>
                                    <Input
                                      type="number"
                                      value={fields.royalityFee}
                                      onChange={handleChange("royalityFee")}
                                      min={0}
                                      step={0.01}
                                      max={10}
                                      required
                                    />
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text"
                                        id="basic-addon1"
                                      >
                                        %
                                      </span>
                                    </div>
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <FormGroup>
                                  <Label>{props.t("NFT Artist Address")}</Label>

                                  <Input
                                    type="text"
                                    value={fields.artist}
                                    onChange={handleChange("artist")}
                                    required
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <FormGroup>
                                  <Label>{props.t("Total Supply")}</Label>
                                  <Input
                                    type="number"
                                    value={fields.maxSupply}
                                    onChange={handleChange("maxSupply")}
                                    min={0}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          }
                        </Row>

                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Label for="formrow-profile-image-Input">
                                {props.t("Collection")} {props.t("Image")}
                              </Label>

                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => {
                                  const imageFile = selectedFiles[0];

                                  return (
                                    <div
                                      className="dropzone-single-image avatar-xl"
                                      {...getRootProps()}
                                    >
                                      <input
                                        {...getInputProps()}
                                        id="formrow-profile-image-Input"
                                        multiple={false}
                                      />

                                      <img
                                        className="rounded-circle avatar-xl"
                                        alt={
                                          !!imageFile && imageFile.name
                                            ? imageFile.name
                                            : "Legendary Racers"
                                        }
                                        src={
                                          !!imageFile
                                            ? imageFile.preview
                                            : avatar4
                                        }
                                      />

                                      <div className="edit">
                                        <i className="bx bx-pencil"></i>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Dropzone>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <button
                            type="submit"
                            onClick={onSubmit}
                            className="btn btn-primary w-md"
                          >
                            {props.t(id ? "Edit" : "Add")}
                          </button>

                          <button
                            type="button"
                            className="btn outline btn-outline-secondary w-md ml-3"
                            onClick={() => history.goBack()}
                          >
                            <i className="mdi mdi-arrow-left mr-1"></i>
                            {props.t("back")}
                          </button>
                        </div>

                        {loading && (
                          <div className="spinner">
                            <Spinner color="primary" />
                          </div>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={1} lg={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddEdit.propTypes = {
  t: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onAddCollection: PropTypes.func,
  onUploadFile: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

const mapStateToProps = ({ collections, File, Settings }) => ({
  error: collections.error,
  loading: File.loading || collections.loading,
  collection: collections.collection,
  settings: Settings.settings
});

const mapDispatchToProps = (dispatch) => ({
  onAddCollection: (data, history) => dispatch(addCollection(data, history)),
  onGetCollection: (id, callback) => dispatch(getCollection(id, callback)),
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onRemoveFile: (data) => dispatch(removeFile(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddEdit))
);
