import { call, put, takeEvery } from "redux-saga/effects";

// Category Redux States
import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  GET_CATEGORY,
  PUT_CATEGORY,
  DELETE_CATEGORY,
  PUT_CATEGORIES_STATUS,
} from "./actionTypes";
import {
  getCategoriesFail,
  getCategoriesSuccess,
  addCategoryFail,
  addCategorySuccess,
  getCategoryFail,
  getCategorySuccess,
  putCategoryFail,
  putCategorySuccess,
  deleteCategoryFail,
  deleteCategorySuccess,
  putCategoriesStatusFail,
  putCategoriesStatusSuccess,
} from "./actions";

import {
  getCategories,
  postCategory,
  getCategory,
  putCategory,
  deleteCategory,
  // putCategoriesStatus,
} from "helpers/backend_helper";

function* fetchCategories({ payload }) {
  try {
    const response = yield call(getCategories, payload);
    if (response.status == "failure") {
      return yield put(getCategoriesFail(response.message));
    }

    yield put(getCategoriesSuccess(response));
  } catch (error) {
    yield put(getCategoriesFail(error));
  }
}

function* onAddNewCategory({ payload: { item, history } }) {
  try {
    const response = yield call(postCategory, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addCategoryFail(response.message));
    }

    history && history.push("/categories");

    yield put(addCategorySuccess());
  } catch (error) {
    yield put(addCategoryFail("Internal Error!"));
  }
}

function* fetchCategory({ payload }) {
  try {
    const response = yield call(getCategory, payload);

    if (response.status == "failure") {
      return yield put(getCategoryFail(response.message));
    }

    yield put(getCategorySuccess(response.data));
  } catch (error) {
    yield put(getCategoryFail(error));
  }
}

function* onPutCategory({ payload: { data, history } }) {
  try {
    const response = yield call(putCategory, data);

    if (response.status == "failure") {
      return yield put(putCategoryFail(response.message));
    }

    history && history.push("/categories");

    yield put(putCategorySuccess());
  } catch (error) {
    yield put(putCategoryFail("Internal Error!"));
  }
}

function* onDeleteCategory({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCategory, data);

    if (response.status == "failure") {
      return yield put(deleteCategoryFail(response.message));
    }

    yield put(deleteCategorySuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteCategoryFail("Internal Error!"));
  }
}

function* onPutCategoriesStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putCategoriesStatus, data);

    if (response.status == "failure") {
      return yield put(putCategoriesStatusFail(response.message));
    }

    yield put(putCategoriesStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putCategoriesStatusFail("Internal Error!"));
  }
}

function* CategoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories);
  yield takeEvery(ADD_CATEGORY, onAddNewCategory);
  yield takeEvery(GET_CATEGORY, fetchCategory);
  yield takeEvery(PUT_CATEGORY, onPutCategory);
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory);
  yield takeEvery(PUT_CATEGORIES_STATUS, onPutCategoriesStatus);
}

export default CategoriesSaga;
