import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleDeployConfirmModal, t) => [
  {
    dataField: "name",
    text: t("Name"),
  },
  {
    dataField: "blockchain",
    text: t("Blockchain"),
    formatter: (_, row) => (
      <span className="text-capitalize">{row.blockchain}</span>
    ),
  },
  /* {
    dataField: "isFeatured",
    text: t("Featured"),
    formatter: (_, row) => row.isFeatured ? "Yes" : "No"
  },
  {
    dataField: "isBanner",
    text: t("Banner"),
    formatter: (_, row) => row.isBanner ? "Yes" : "No"
  }, */
  {
    text: t("Created At"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) =>
      moment(row.date_created_utc).format("DD MMM YYYY LT"),
  },
  {
    isDummyField: true,
    text: t("Action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link
          to={`/collections/${row._id}/deploy`}
          className="mr-3 text-secondary"
          onClick={(evt) => {
            evt.preventDefault();
            toggleDeployConfirmModal(row);
          }}
        >
          <i className="mdi mdi-sale mr-3" id={`deploy-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`deploy-${row._id}-tooltip`}
          >
            Compile & Depoly
          </UncontrolledTooltip>
        </Link>

        <Link
          to={`/collections/${row._id}/view`}
          className="mr-3 text-secondary"
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />

          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            View
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
