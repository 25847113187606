import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

import { getStarted } from "../../Settings/actions";
import { updateToken } from "helpers/api_helper";
import { ROLES, ADMIN_SUB_SERVICES_ROUTE } from "helpers/contants";

const permissionsFilter = (item) => {
  if (item?.type === "storetypes") return true;

  const _index = item?.permissions?.findIndex((i) =>
    ["VIEW", "view", "List", "list"].includes(i.label)
  );

  if (_index !== -1) {
    if (!item?.permissions) {
      return true;
    }

    return item?.permissions[_index]?.value;
  }

  return false;
};

const findValidRoute = ({ permissions, routes, preLink }) => {
  permissions = permissions?.filter(permissionsFilter);
  let route = "";

  if (!permissions) return route;

  const item = permissions[0];

  if (!item) return route;

  if (item?.type === "storetypes") {
    return findValidRoute({
      preLink: item?.storeTypes[0]?.storeType?.toLowerCase(),
      permissions: item?.storeTypes[0]?.navigation,
      routes: ADMIN_SUB_SERVICES_ROUTE,
    });
  }

  route = routes[item?.type]?.key;

  if (preLink) {
    route = preLink + "/" + route;
  }

  if (routes[item?.type]?.isDynamic) {
    route += "s";
  }

  return route;
};

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(API.postLogin, {
      email: user.email,
      password: user.password,
    });

    if (response.status == "failure") {
      return yield put(ACTIONS.apiError(response.message));
    }

    localStorage.setItem("authUser", JSON.stringify(response));
    localStorage.setItem("role", response.data.user.role);

    updateToken(response.data.Token);
    yield put(ACTIONS.loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* currentUser() {
  try {
    const response = yield call(API.getCurrentUser);

    if (response.status == "failure") {
      return yield put(ACTIONS.currentUserFail(response.message));
    }

    if (response?.data?.role === ROLES.admin) {
      yield put(getStarted());
    }

    yield put(ACTIONS.currentUserSuccess(response));
  } catch (error) {
    yield put(ACTIONS.currentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { history, role } }) {
  try {
    localStorage.removeItem("authUser");

    yield put(ACTIONS.logoutUserSuccess());

    if (role === ROLES.vendor) {
      history.push("/login");
      window.location.replace("/login");
    } else {
      history.push("/login");
    }
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* onGetLanguages({ payload }) {
  try {
    const response = yield call(API.getLanguages);

    if (response.status == "failure") {
      return yield put(ACTIONS.getLanguagesFail(response.message));
    }

    /* let _timezones = [moment.tz.guess()]

    response?.data?.map(language => {
      language?.timezones?.map(item => {
        if (!_timezones.includes(item)) {
          _timezones.push(item)
        }
      })
    })

    _timezones = _timezones?.sort()

    data.timezones = _timezones
      ?.map(item => {
        const zoneIndex = TIMEZONES.findIndex(zone => zone.value === item)

        if (zoneIndex !== -1) {
          return TIMEZONES[zoneIndex]
        }
      })
      ?.filter(item => !!item) */

    yield put(ACTIONS.getLanguagesSuccess(response?.data));
  } catch (error) {
    console.log("error ", error);
    yield put(ACTIONS.getLanguagesFail(error));
  }
}

function* onPostUesrWhite({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postUserWhiteList, data);
    if (response == "failure") {
      return yield put(ACTIONS.userWhiteListFail(response.message));
    }
    callback && callback(response);
    yield put(ACTIONS.userWhiteListSuccess(response));
  } catch (error) {
    console.log(error, "userWhiteList Error");
    yield put(ACTIONS.userWhiteListFail(error));
  }
}

function* authSaga() {
  yield takeEvery(TYPES.LOGIN_USER, loginUser);
  yield takeEvery(TYPES.GET_CURRENT_USER, currentUser);
  yield takeEvery(TYPES.LOGOUT_USER, logoutUser);
  yield takeEvery(TYPES.GET_LANGUAGES, onGetLanguages);
  yield takeEvery(TYPES.USER_WHITE_LIST, onPostUesrWhite);
}

export default authSaga;
