import React from "react"
import { Link } from "react-router-dom"
import { Badge, Button, Input, Label, UncontrolledTooltip } from "reactstrap"
import moment from "moment-timezone"
import { Eye } from "react-bootstrap-icons"

export const selectRow = props => ({
  mode: "checkbox",
  clickToSelect: false,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={input => {
          if (input) input.indeterminate = indeterminate
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
})

const ListColumns = (history, toggleConfirmModal, accesses, t,hanldeViewModal) => [
  {
    dataField: "name",
    text: t("name"),
  },
  {
    dataField: "email",
    text: t("email"),
  },
  {
    dataField: "subject",
    text: t("subject"),
  },
  {
    text: t("created_at"),
    dataField: "createdAt",
    sort: true,
    formatter: (_, row) => moment(row.date_created_utc).format("DD MMM YYYY"),
  },
  {
    text:t("action"),
    dataField:"",
    formatter:(_,row)=>{
      return(
         <Eye size={18}  onClick={()=>hanldeViewModal(row)}/>
      )
    }
  }
]

export default ListColumns
