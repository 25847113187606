import { combineReducers } from "redux";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Register from "./auth/register/reducer";

// Front
import Settings from "./Settings/reducer";
import customers from "./customers/reducer";
import collections from "./collection/reducer";
import items from "./items/reducer";
import Category from "./category/reducer";
import Activities from "./activity/reducer";
import File from "./File/reducer";
import faqs from "./faq/reducer";
import Layout from "./layout/reducer";
import Alert from "./Alert/reducer";
import gallery from "./gallery/reducer";
import Team from "./Teams/reducer";
import content from "./content/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Alert,
  Login,
  Activities,
  collections,
  Category,
  items,
  ForgetPassword,
  Profile,
  Register,
  Settings,
  customers,
  gallery,
  File,
  faqs,
  Team,
  content
});

export default rootReducer;
