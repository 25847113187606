import * as TYPES from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  billingProduct: {},
  collections: [],
  totalCollections: 0,
  collection: {},
  date: "",
  layers: [],
  deployLoading : false,
  addDisVillainLoading : false,
  removeDisVillainLoading : false
};

const Collections = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Collections */
    case TYPES.GET_COLLECTIONS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload.data || [],
        totalCollections: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_COLLECTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Collections END */

    /* Add Collection */
    case TYPES.ADD_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.ADD_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.ADD_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Collection END */

    /* Get Collection */
    case TYPES.GET_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
        collection: {},
      };

    case TYPES.GET_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        collection: {},
      };

    case TYPES.GET_COLLECTION_SUCCESS:
      let getDate = new Date(action.payload.date_created_utc);
      getDate = `${getDate.getDate()}-${getDate.getMonth()}-${getDate.getFullYear()}`;
      return {
        ...state,
        error: "",
        loading: false,
        collection: action.payload,
        date: getDate,
      };
    /* Add Collection END */

    /* Update Collection */
    case TYPES.PUT_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.PUT_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.PUT_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Collection END */

    /* Delete Collection */
    case TYPES.DELETE_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.DELETE_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Collection END */

    /* Update Collections Status */
    case TYPES.POST_DEPLOY_COLLECTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.POST_DEPLOY_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.POST_DEPLOY_COLLECTION_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Collections Status END */

    /* Collection Action */
    case TYPES.POST_COLLECTION_ACTION:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.POST_COLLECTION_ACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.POST_COLLECTION_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    /* Collection Action END */

    /* Post Layers */
    case TYPES.POST_LAYERS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_LAYERS_SUCCESS:
    case TYPES.POST_LAYERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    /* Post Layers END */

    /* Post Layers */
    case TYPES.GET_LAYERS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_LAYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        layers: action.payload,
      };

    case TYPES.GET_LAYERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    /* Post Layers END */

    /* Generate NFT */
    case TYPES.GENERATE_NFT:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GENERATE_NFT_SUCCESS:
    case TYPES.GENERATE_NFT_FAIL:
      return {
        ...state,
        loading: false,
      };
    /* Generate NFT END */

    case TYPES.COLLECTION_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
      case TYPES.AFTER_DEPLOY_COLLECTION_UPDATE_REQUEST:
        return {
          ...state,
          deployLoading: true,
        };
        case TYPES.AFTER_DEPLOY_COLLECTION_UPDATE_SUCCESS:
          return {
            ...state,
            deployLoading: false,
          };
       case TYPES.AFTER_DEPLOY_COLLECTION_UPDATE_FAIL:   
        return {
          ...state,
          deployLoading: false,
      };
      case TYPES.ADD_DISCORD_VILLAIN_REQUEST:
        return {
          ...state,
          addDisVillainLoading: true,
        };
        case TYPES.ADD_DISCORD_VILLAIN_SUCCESS:
          return {
            ...state,
            addDisVillainLoading: false,
            collection : action.payload.data
          };
       case TYPES.ADD_DISCORD_VILLAIN_FAIL:   
          return {
            ...state,
            addDisVillainLoading: false,
          };
      case TYPES.REMOVE_DISCORD_VILLAIN_REQUEST:
          return {
            ...state,
            removeDisVillainLoading: true,
          };
      case TYPES.REMOVE_DISCORD_VILLAIN_SUCCESS:
          return {
            ...state,
            removeDisVillainLoading: false,
            collection : action.payload.data
          };
      case TYPES.REMOVE_DISCORD_VILLAIN_FAIL:   
          return {
            ...state,
            removeDisVillainLoading: false,
          };
    default:
      return state;
  }
};

export default Collections;
