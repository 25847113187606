import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import Instructions from "./Instructions";
import { uploadFile as onUploadFile, getCollections } from "store/actions";
import { useDispatch } from "react-redux";

const RoadMap = (props) => {
  const { fields, setFields } = props;
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const removeHomeNfts = (index) => {
    setFields((prevState) => ({
      ...prevState,
      roadMapSetting: [
        ...prevState["roadMapSetting"].slice(0, index),
        ...prevState["roadMapSetting"].slice(index + 1),
      ],
    }));
  };

  const handleRoadMapChange = (index) => (name, data) => {
    setFields((prevState) => {
      return {
        ...prevState,
        roadMapSetting: prevState["roadMapSetting"].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [name]: data,
            };
          } else {
            return item;
          }
        }),
      };
    });
  };

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  const uploadFileSuccess =
    (fieldKey = "image") =>
    (index) =>
    (response) => {
      const { link } = response.data;

      setFields((prevState) => {
        return {
          ...prevState,
          roadMapSetting: prevState["roadMapSetting"].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [fieldKey]: link,
              };
            } else {
              return item;
            }
          }),
        };
      });
    };

  const changeHandler = (fieldKey) => (bannerIndex) => (event) => {
    const _files = event.target.files;

    // if (_files && _files[0] && _files[0].size > 100000000) {
    //   return toastr.error("Maximum upload file size: 100MB");
    // }

    dispatch(
      onUploadFile(
        { image: _files[0] },
        uploadFileSuccess(fieldKey)(bannerIndex)
      )
    );
  };

  console.log("fields", fields);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Instructions />
        </Col>
      </Row>
      <Row>
        {(fields || []).map((item, index) => (
          <Row className="align-items-center my-2 mx-2">
            {!item?.image && (
              <Col md={2} className="text-truncate">
                <input
                  type="file"
                  name="file"
                  onChange={changeHandler("image")(index)}
                />
              </Col>
            )}

            {!!item?.image && (
              <Col md={2}>
                {get_url_extension(item?.image) === "mp4" ? (
                  <video
                    className="rounded-new avatar-xl"
                    loop="true"
                    autoplay="autoplay"
                    height={110}
                    width={120}
                  >
                    <source src={item?.image} type="video/mp4"></source>
                  </video>
                ) : (
                  <img
                    className="rounded-new"
                    src={item?.image}
                    alt=""
                    height={110}
                    width={120}
                  />
                )}
              </Col>
            )}

            <Col md={3}>
              <FormGroup>
                <Label>Square background color</Label>
                <Input
                  type="text"
                  value={item?.topSquareBgColor}
                  placeholder="Please enter #colorcode"
                  onChange={(e) =>
                    handleRoadMapChange(index)(
                      "topSquareBgColor",
                      e.target.value
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Title</Label>
                <Input
                  type="text"
                  value={item?.title}
                  onChange={(e) =>
                    handleRoadMapChange(index)("title", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  value={item?.description}
                  onChange={(e) =>
                    handleRoadMapChange(index)("description", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            {!item?.topIcon && (
              <Col md={2} className="text-truncate">
                <input
                  type="file"
                  name="file"
                  onChange={changeHandler("topIcon")(index)}
                />
              </Col>
            )}

            {!!item?.topIcon && (
              <Col md={2}>
                <div
                  style={{
                    padding: 10,
                    borderRadius: 20,
                    backgroundColor: item.topSquareBgColor,
                    width: "fit-content",
                  }}
                >
                  <img
                    className="rounded-new"
                    src={item?.topIcon}
                    alt=""
                    height={40}
                    width={40}
                  />
                </div>
              </Col>
            )}
            <Col md={3}>
              <FormGroup>
                <Label>Timeline</Label>
                <Input
                  type="text"
                  value={item?.timeline}
                  onChange={(e) =>
                    handleRoadMapChange(index)("timeline", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            {/* <Col md={3}>
              <FormGroup>
                <Label>Link</Label>
                <Input
                  type="text"
                  value={item?.link}
                  onChange={(e) =>
                    handleRoadMapChange(index)("link", e.target.value)
                  }
                />
              </FormGroup>
            </Col> */}
            {/* <Col md={2} className="text-truncate">
              <FormGroup>
                <Label>Sort</Label>
                <Input
                  type="number"
                  value={item?.sort}
                  onChange={(e) =>
                    handleRoadMapChange(index)("sort", e.target.value)
                  }
                  min={0}
                  required
                />
              </FormGroup>
            </Col> */}

            <Col md={2}>
              <Button className="m-1" onClick={() => removeHomeNfts(index)}>
                Remove
              </Button>
            </Col>
            <hr />
          </Row>
        ))}
        <Col md={12}>
          <Button
            color="primary"
            className="buttoncolor mt-3"
            onClick={() => {
              setFields((prevState) => ({
                ...prevState,
                roadMapSetting: [
                  ...prevState.roadMapSetting,
                  {
                    image: "",
                    sort: 0,
                    link: "",
                    title: "",
                    description: "",
                  },
                ],
              }));
            }}
          >
            Add More
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

RoadMap.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoadMap)
);
