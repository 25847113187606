import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Select, { components } from "react-select"

import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Label,
  Input,
  UncontrolledTooltip,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"
import Instructions from "./Instructions"

const DEFAULT_NETWORK_URL = {
  url: "",
  type: "",
  apiKey: "",
  chainId: "",
  chainName: "",
  blockExplorerUrls: [],
  nativeCurrency: {
    name: "",
    symbol: "",
    decimals: 18,
  },
}

const NetworkUrl = (props) => {
  const { fields, handleChange } = props

  useEffect(() => {
    if (!fields.networkUrl) {
      let newNetworkUrl = [...fields.networkUrl, { ...DEFAULT_NETWORK_URL }]
      handleChange("networkUrl")({ target: { value: newNetworkUrl } })
    }
  }, [fields.networkUrl])

  const addNetworkUrl = (event) => {
    event.preventDefault()

    let newNetworkUrl = [...fields.networkUrl, { ...DEFAULT_NETWORK_URL }]
    handleChange("networkUrl")({ target: { value: newNetworkUrl } })
  }

  const removeNetworkUrl = (index) => (event) => {
    event.preventDefault()

    if (fields.networkUrl.index <= 1) return

    let newNetworkUrl = [
      ...fields.networkUrl.slice(0, index),
      ...fields.networkUrl.slice(index + 1),
    ]
    handleChange("networkUrl")({ target: { value: newNetworkUrl } })
  }

  const handleNetworkUrlChange = (index, name) => (event) => {
    let newNetworkUrl = [
      ...fields.networkUrl.slice(0, index),
      {
        ...fields.networkUrl[index],
        [name]:
          name === "blockExplorerUrls"
            ? [event.target.value]
            : event.target.value,
      },
      ...fields.networkUrl.slice(index + 1),
    ]
    handleChange("networkUrl")({ target: { value: newNetworkUrl } })
  }

  const handleNativeUrlChange = (index, name) => (event) => {
    let newNetworkUrl = [
      ...fields.networkUrl.slice(0, index),
      {
        ...fields.networkUrl[index],
        nativeCurrency: {
          ...fields.networkUrl[index].nativeCurrency,
          [name]: event.target.value,
        },
      },
      ...fields.networkUrl.slice(index + 1),
    ]
    handleChange("networkUrl")({ target: { value: newNetworkUrl } })
  }

  return (
    <Card className="mb-3">
      <CardBody className="px-0 pt-0 pb-2">
        {fields.networkUrl?.map((item, index) => (
          <React.Fragment key={"network-url-" + index}>
            <CardTitle className="text-capitalize">
              {item.type} Network Url{" "}
              {/* <Link to={`#`} onClick={addNetworkUrl} className="ml-3 text-primary">
                <i className="bx bx-plus font-size-22 mr-3" />
              </Link> */}
              <hr className="my-3" />
            </CardTitle>

            <Row className="mb-3 mb-sm-2">
              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">Url</Label>

                <Input
                  type="text"
                  className="form-control"
                  value={item.url || ""}
                  onChange={handleNetworkUrlChange(index, "url")}
                  required
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">Api Key</Label>

                <Input
                  type="text"
                  className="form-control"
                  value={item.apiKey || ""}
                  onChange={handleNetworkUrlChange(index, "apiKey")}
                  required
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">Chain Id</Label>

                <Input
                  type="text"
                  className="form-control"
                  value={item.chainId || ""}
                  onChange={handleNetworkUrlChange(index, "chainId")}
                  required
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">Chain Name</Label>

                <Input
                  type="text"
                  className="form-control"
                  value={item.chainName || ""}
                  onChange={handleNetworkUrlChange(index, "chainName")}
                  required
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">Explorer Url</Label>

                <Input
                  type="text"
                  className="form-control"
                  value={item.blockExplorerUrls || ""}
                  onChange={handleNetworkUrlChange(index, "blockExplorerUrls")}
                  required
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">Native Currency Name</Label>

                <Input
                  type="text"
                  className="form-control"
                  value={item.nativeCurrency?.name || ""}
                  onChange={handleNativeUrlChange(index, "name")}
                  required
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">
                  Native Currency Symbol
                </Label>

                <Input
                  type="text"
                  className="form-control"
                  value={item.nativeCurrency?.symbol || ""}
                  onChange={handleNativeUrlChange(index, "symbol")}
                  required
                />
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="pb-3">
                <Label className="text-capitalize">
                  Native Currency Decimals
                </Label>

                <Input
                  type="number"
                  className="form-control"
                  value={item.nativeCurrency?.decimals || ""}
                  onChange={handleNativeUrlChange(index, "decimals")}
                  min={0}
                  required
                />
              </Col>

              {/* <Col xs={6} sm={6} className="pb-3">
                <FormGroup>
                  <Label className="text-capitalize">Type</Label>

                  <div className="status-switch square-switch">
                    <input
                      className="switcher"
                      type="checkbox"
                      id={`network-url-${index}-type`}
                      switch="none"
                      checked={item.type === "mainnet"}
                      onChange={() => {
                        handleNetworkUrlChange(
                          index,
                          "status",
                        )({
                          target: {
                            value:
                              item.type === "mainnet" ? "testnet" : "mainnet",
                          },
                        })
                      }}
                    />
                    <label
                      htmlFor={`network-url-${index}-status`}
                      data-on-label="Mainnet"
                      data-off-label="Testnet"
                    />
                  </div>
                </FormGroup>
              </Col> */}

              {/* <Col xs={6} sm={6}>
                <Label className="">Actions</Label>

                <div className="d-flex align-items-center">
                  <Link
                    disabled={fields.blockchain?.length === 1}
                    to={`#`}
                    onClick={removeNetworkUrl(index)}
                    className={
                      fields.blockchain?.length === 1
                        ? "text-secondary"
                        : "text-danger"
                    }
                  >
                    <i
                      className="bx bx-trash font-size-22"
                      id="removeoptiontooltip"
                    />
                  </Link>
                </div>
              </Col> */}
            </Row>
          </React.Fragment>
        ))}
      </CardBody>
    </Card>
  )
}

NetworkUrl.propTypes = {
  fields: PropTypes.object,
  storeTypes: PropTypes.array,
  storeVendors: PropTypes.object,
  handleChange: PropTypes.func,
  handleStoreTypes: PropTypes.func,
  handleStoreVendors: PropTypes.func,
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NetworkUrl),
)
