import React, { useEffect, useState, useMemo } from "react";
import toastr from "toastr";

export const WalletContext = React.createContext();
const WalletProvider = ({ children }) => {
  const [state, setstate] = useState({
    isActive: false,
    account: "",
    loading: false,
  });

  const connectWallet = async () => {
    if (window.ethereum) {
      // Do something
      setstate((prev) => ({ ...prev, loading: true }));
      try {
        let account = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setstate((prev) => ({
          ...prev,
          loading: false,
          account: account[0],
          isActive: true,
        }));
      } catch (error) {
        setstate((prev) => ({ ...prev, loading: false }));
        toastr.error(error?.message);
      }
    } else {
      toastr.error("install metamask extension!!");
    }
  };
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        // Time to reload your interface with accounts[0]!
        setstate((prev) => ({
          ...prev,
          loading: false,
          account: accounts[0],
          isActive: accounts?.length ?  true : false,
        }));
      });
    }
  }, [window.ethereum]);

  async function switchChain(chainId) {
    try {
      // Request switching to the specified chain
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
  
      // Check if the chain switch was successful
      const currentChainId = await ethereum.request({ method: 'eth_chainId' });
      if (parseInt(currentChainId, 16) === chainId) {
        console.log(`Switched to chain ${chainId}`);
      } else {
        console.error('Failed to switch to the specified chain');
      }
    } catch (error) {
      console.error('Error switching chain:', error);
    }
  }


  const values = useMemo(
    () => ({
      connectWallet,
      switchChain,
      ...state,
    }),
    [state]
  );

  return (
    <>
      <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
    </>
  );
};

export default WalletProvider;
