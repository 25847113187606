import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import {
    Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import { getNftMetadataRequest,updateNftMetadataRequest,refreshNftsMetadataRequest, updateNftImageRequest } from "store/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import toastr from "toastr";

const NftMetadata = (props) => {
  const {
    accessLevel,
    history,
    nftMetadata,
    nftsMetadataList,
    getNftMetadataRequest,
    updateNftMetadataRequest,
    updateNftImageRequest,
    loading,
    updateNftMetaLoading,
    updateNftImageLoading
  } = props;
 const [fields,setFields] = useState(
    {
        name: "",
        description: "",
        image: "",
        attributes: [],
        isEditable : false
      }
 );
 const {_id} = useParams();

 useEffect(()=>{
   if(nftMetadata && _id){
      setFields({
         name : nftMetadata?.name,
         description : nftMetadata?.description,
         attributes : nftMetadata?.attributes || [],
         image : nftMetadata?.image,
         isEditable : nftMetadata?.isEditable
      });
   }
 },[nftMetadata,_id]);

 const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const onUpdateNftImage = async (file) =>{
   try{
    console.log("file",file)
    console.log("nftMetadata",nftMetadata)
      const tokenId = nftMetadata?.edition?.toString();
  if(!file){
      return toastr.error("File required!");
    }
  if(!tokenId){
    return toastr.error("TokenId is required!");
  }  

  var allowedTypes = ['image/png','image/jpeg','image/jpg'];

  if (!allowedTypes.includes(file.type)) {
    return toastr.error("Png type file is valid!");
  }

  const filebase64 =  await toBase64(file);
     //api call
     updateNftImageRequest({
      file : filebase64, 
      tokenId, 
      type : nftMetadata.type,
      nft_metadata_id : _id,
      isFullNfts:true
    },()=>{
      getNftMetadataRequest({_id,isFullNfts:true});
      window.location.reload();
     });
  }catch(error){
    toastr.error("Something went wrong!");
  }
    
}

 const onSubmit= (e) =>{
    e.preventDefault();
    if(!nftMetadata){
     return;
    }
    console.log("fields",fields)
    const payload = {...nftMetadata,...fields,_id,isFullNfts:true}
    updateNftMetadataRequest(payload,()=>{
      getNftMetadataRequest({_id,isFullNfts:true});
    });
 }

  useEffect(()=>{
    if(_id){
      getNftMetadataRequest({_id,isFullNfts:true});
    }
  },[_id]);


  const onHandleChange = (e)=>{
      const {value,name} = e.target;
        setFields((prev)=>({...prev, [name] : value}));
  }

  const onHandleaAttributeChange = (e) => (index) =>{
    const {value,name} = e.target;
    let allAttribues = fields?.attributes;
    let itemForpdate = fields?.attributes[index];
    let updatedData = {...itemForpdate, [name] : value};
    allAttribues.splice(index,1,updatedData)
  console.log("itemForpdate",itemForpdate)
    setFields(prev => ({...prev, attributes : allAttribues}));
  }

  const addNewAttribute = () =>{
    setFields((prev => (
      {...prev, attributes : [...prev.attributes,{ trait_type : "",value : ""}]}
    )))
  }

  const removeAttribute = (index) =>{
    
      setFields((prev => (
        {...prev, attributes : prev.attributes.filter((a,idx)=> idx !== index)}
      )));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Nft Metadata"
            breadcrumbItem={props.t("Nft Metadata")}
            breadcrumbItems={[{ title: props.t("Nft Metadata") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                    <Form onSubmit={onSubmit}>
                        <Row>
                        <Col lg={12}>
                        <FormGroup>
                            <Label>Nft Image</Label>
                            <p>
                            <div className="images_Wrap position-relative">
                            {fields?.image ?
                         
                                <img 
                                width={150}
                                height={150}
                                src={`${fields?.image}?timestamp=${new Date().getTime()}`}
                                />
                                : null}
                                <label for="nft-image">
                                   <input disabled={updateNftImageLoading} type="file" onChange={(e)=>onUpdateNftImage(e.target.files[0])} id="nft-image"  style={{display:"none"}}/>
                                      <div  className="img_upload">
                                      {updateNftImageLoading ? <Spinner size="sm" className="me-2" color="#fff"/> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 48 48"><g fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="4"><path d="M5.325 43.5h8.485l31.113-31.113l-8.486-8.485L5.325 35.015V43.5Z"/><path stroke-linecap="round" d="m27.952 12.387l8.485 8.485"/></g></svg>}
                                      </div>
                                </label>
                            </div>
                           
                            </p>
                        </FormGroup>
                        </Col>    
                            <Col lg={12}>
                              
                               <FormGroup>
                               <Label>Name</Label>
                                   <Input 
                                       type="text"
                                       name="name"
                                       onChange={onHandleChange}
                                       value={fields.name}
                                   />
                               </FormGroup>
                            </Col>
                            <Col lg={12}>
                               <FormGroup>
                               <Label>Description</Label>

                                   <Input 
                                       type="textarea"
                                       name="description"
                                       onChange={onHandleChange}
                                       value={fields.description}
                                   />
                               </FormGroup>
                            </Col>
                            <Col md={6}>
                        <FormGroup>
                          <Label>Editable</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.isEditable}
                              onChange={() => {
                                const value =
                                isEditable == true
                                    ? false
                                    : true

                                    onHandleChange({target : {name  : "isEditable", value: value}})                        
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("Yes")}
                              data-off-label={props.t("No")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                        </Row>
                        
                     
                        <h3>Attributes</h3> 
                          <Button 
                          className="my-2"
                            color="primary" 
                            onClick={()=>addNewAttribute()}>
                             Add trait
                          </Button>
                       
                       
                        {fields?.attributes?.map((item,index)=>(
                            <Row>
                                <Col lg={4} md={4}  sm={12} xs={12}>
                                <Label>Trait type</Label>

                                    <FormGroup>
                                        <Input 
                                            type="text"
                                            name="trait_type"
                                            onChange={(e) => onHandleaAttributeChange(e)(index)}
                                            value={item.trait_type}
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4}  sm={12} xs={12}>
                                    <FormGroup>
                                    <Label>Trait value</Label>

                                        <Input 
                                            type="text"
                                            name="value"
                                            onChange={(e) => onHandleaAttributeChange(e)(index)}
                                            value={item.value}
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col lg={4} md={4}  sm={12} xs={12}>
                                      <FormGroup>
                                      <Button 
                                      className="mt-4"
                                        color="primary" 
                                        onClick={()=>removeAttribute(index)}>
                                          Remove
                                      </Button>
                                      </FormGroup>
                                    </Col>
                                   
                            </Row>
                        ))}

                        <Row>
                            <Col lg={12}>
                                <FormGroup>
                                    <Button 
                                   disabled={updateNftMetaLoading || !nftMetadata}
                                    color="primary" type="submit">
                                        {updateNftMetaLoading && <Spinner size="sm" className="me-2"/>}
                                        Update</Button>
                                </FormGroup>
                            </Col> 
                        </Row>
                        
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ items }) => ({
  loading: items.loading,
  nftMetadata: items.nftMetadata,
  updateNftMetaLoading: items.updateNftMetaLoading,
  updateNftImageLoading : items.updateNftImageLoading
});

const mapDispatchToProps = (dispatch) => {
  return {
    getNftMetadataRequest:(data,callback) => dispatch(getNftMetadataRequest(data,callback)),
    updateNftMetadataRequest:(data,callback) => dispatch(updateNftMetadataRequest(data,callback)),
    updateNftImageRequest:(data,callback) => dispatch(updateNftImageRequest(data,callback))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NftMetadata))
);
