import * as TYPES from "./actionTypes";
const INIT_STATE = {
  faqs: [],
  totalFaqs: 0,
  error: "",
  loading: false,
  faq: {},
};

const Faqs = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Faqs */
    case TYPES.GET_FAQS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_FAQS_SUCCESS:
      console.log("action.payload", action.payload);
      return {
        ...state,
        faqs: action.payload.data || [],
        totalFaqs: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_FAQS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Faqs END */

    /* Add Faq */
    case TYPES.ADD_FAQ:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.ADD_FAQ_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.ADD_FAQ_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Faq END */

    /* Get Faq */
    case TYPES.GET_FAQ:
      return {
        ...state,
        error: "",
        loading: true,
        faq: {},
      };

    case TYPES.GET_FAQ_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        faq: {},
      };

    case TYPES.GET_FAQ_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        faq: action.payload,
      };
    /* Add Faq END */

    /* Update Faq */
    case TYPES.PUT_FAQ:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.PUT_FAQ_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.PUT_FAQ_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Faq END */

    /* Delete Faq */
    case TYPES.DELETE_FAQ:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.DELETE_FAQ_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.DELETE_FAQ_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Faq END */

    /* Update Faqs Status */
    case TYPES.PUT_FAQS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.PUT_FAQS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.PUT_FAQS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Faqs Status END */

    case TYPES.FAQ_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Faqs;
