import {
  CUSTOMER_LOADING,
  API_FAIL,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_SUCCESS,
  GET_ALL_PLANS_SUCCESS,
  GET_ALL_PLANS_FAIL,
  UPDATE_STORE_STATUS_SUCCESS,
  UPDATE_STORE_STATUS_FAIL,
  UPDATE_STORE_PLAN,
  UPDATE_STORE_PLAN_SUCCESS,
  UPDATE_STORE_PLAN_FAIL,
  GET_STORE_TRANSACTIONS_SUCCESS,
  GET_STORE_TRANSACTIONS_FAIL,
  POST_STORE_CHARGE_SUCCESS,
  POST_STORE_CHARGE_FAIL,
  POST_STORE_REFUND_SUCCESS,
  POST_STORE_REFUND_FAIL,
  STORE_USER_LOGIN_SUCCESS,
  PUT_STORE_PLAN_DISCOUNT,
  PUT_STORE_PLAN_DISCOUNT_SUCCESS,
  PUT_STORE_PLAN_DISCOUNT_FAIL,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
  PUT_STORE_KEYS,
  PUT_STORE_KEYS_SUCCESS,
  PUT_STORE_KEYS_FAIL,
  POST_CHECK_MAIL,
  POST_CHECK_MAIL_SUCCESS,
  POST_CHECK_MAIL_FAIL,
  POST_HIDE_THINGS,
  POST_HIDE_THINGS_SUCCESS,
  POST_HIDE_THINGS_FAIL,
  WHITELIST_ACCEPT_REJECT,
  WHITELIST_ACCEPT_REJECT_SUCCESS,
  WHITELIST_ACCEPT_REJECT_FAIL,
  GET_CONTACT_US_LIST_FAIL,
  GET_CONTACT_US_LIST_SUCCESS,
  GET_CONTACT_US_LIST_REQUEST,
} from "./actionTypes";

const INIT_STATE = {
  customers: [],
  totalCustomers: 0,
  error: "",
  keys_error: "",
  loading: false,
  keys_loading: false,
  customer: {},
  address: {},
  plans: [],
  transactions: {
    data: [],
    totalcount: 0,
  },
  hideThingsError: "",
  contactUsList : [],
  totalContactUs : 0
};

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    /** Loading */
    case CUSTOMER_LOADING:
      return {
        loading: true,
      };
    /* Get Customers */
    case GET_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload.data || [],
        totalCustomers: action.payload.totalCount || 0,
        loading: false,
      };

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Customers END */

    /* Get Customer */
    case GET_CUSTOMER:
      return {
        ...state,
        error: "",
        loading: true,
        customer: {},
      };

    case GET_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        customer: {},
      };

    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        customer: action.payload,
        transactions: [],
      };
    /* Add Customer END */

    /** Get Plans Start*/
    case GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        plans: [...action.payload],
      };
    case GET_ALL_PLANS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /** Get Plans End*/

    case UPDATE_STORE_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case UPDATE_STORE_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_STORE_PLAN_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case UPDATE_STORE_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_STORE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: { ...action.payload },
        error: "",
        loading: false,
      };
    case GET_STORE_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case POST_STORE_CHARGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case POST_STORE_CHARGE_FAIL:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case POST_STORE_REFUND_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case POST_STORE_REFUND_FAIL:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case STORE_USER_LOGIN_SUCCESS:
      return {
        ...state,
      };

    /* Give discount on store plan */
    case PUT_STORE_PLAN_DISCOUNT:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case PUT_STORE_PLAN_DISCOUNT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    case PUT_STORE_PLAN_DISCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Give discount on store plan END */

    /* Store Check mail */
    case POST_CHECK_MAIL:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case POST_CHECK_MAIL_SUCCESS: {
      return {
        ...state,
        error: "",
        loading: false,
      };
    }

    case POST_CHECK_MAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Store check mail END */

    /* Delete Store */
    case DELETE_STORE:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    case DELETE_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Delete Store END */

    /* PUT STORE KEYS */
    case PUT_STORE_KEYS:
      return {
        ...state,
        keys_error: "",
        keys_loading: true,
      };

    case PUT_STORE_KEYS_SUCCESS:
      return {
        ...state,
        keys_error: "",
        keys_loading: false,
      };

    case PUT_STORE_KEYS_FAIL:
      return {
        ...state,
        keys_error: action.payload,
        keys_loading: false,
      };
    /* PUT STORE KEYS END */

    /* POST Hide THings */
    case POST_HIDE_THINGS:
      return {
        ...state,
        loading: true,
        hideThingsError: "",
      };

    case POST_HIDE_THINGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case POST_HIDE_THINGS_FAIL:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    /* POST Hide Things END */

    case WHITELIST_ACCEPT_REJECT:
      return {
        ...state,
        loading: true,
      };

    case WHITELIST_ACCEPT_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case WHITELIST_ACCEPT_REJECT_FAIL:
      return {
        ...state,
        loading: false,
      };
       // Contact us 
    case GET_CONTACT_US_LIST_REQUEST:
      return {
      ...state,
      loading: true,
      }

    case GET_CONTACT_US_LIST_SUCCESS:
      return {
      ...state,
      loading: false,
      contactUsList : action.payload.data,
      totalContactUs : action.payload.totalCount,
      error: "",
      }

    case GET_CONTACT_US_LIST_FAIL:
      return {
      ...state,
      error: action.payload,
      loading: false,
      }                      
    /** */
    default:
      return state;
  }
};

export default Customers;
