import React, { useEffect, useState, memo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// store
import { getSettings } from "store/actions"

function SelectComp(props) {
  const dispatch = useDispatch()
  const { settings } = useSelector(state => state.Settings)

  const [options, setoptions] = useState([])

  useEffect(() => {
    if (settings && settings.blockchain) {
      setoptions(
        settings.blockchain.map(sett => ({
          label: sett.label,
          value: sett.type,
        }))
      )
    }
  }, [settings])

  useEffect(() => {
    dispatch(getSettings())
  }, [])

  return (
    <>
      <Select
        options={options}
        value={options.find(item => item.value === props.value)}
        onChange={props.handleChange}
      />
    </>
  )
}

SelectComp.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default memo(SelectComp)
