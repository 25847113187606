import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import Register from "./auth/register/saga";

// Settings
import Settings from "./Settings/saga";

// File
import File from "./File/saga";

import Alert from "./Alert/saga";
import Customers from "./customers/saga";
import Gallery from "./gallery/saga";
import Collection from "./collection/saga";
import Items from "./items/saga";
import CategoriesSaga from "./category/saga";
import ActivitiesSaga from "./activity/saga";
import faqs from "./faq/saga";
import TeamSaga from "./Teams/saga";
import content from "./content/saga"

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    Register(),
    LayoutSaga(),
    Settings(),
    fork(ActivitiesSaga),
    fork(Gallery),
    fork(Customers),
    fork(Collection),
    fork(Items),
    fork(CategoriesSaga),
    fork(File),
    fork(faqs),
    fork(TeamSaga),
    fork(content)
  ]);
}
