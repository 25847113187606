import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  Spinner,
  Input,
  Form,
  FormGroup,
  Col,
  Row,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";

// Actions
import {
  postDeployCollection,
  afterDeployCollectionUpdateRequest,
  uploadMetadataUpdateRequest,
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { downloadNftsMetadata } from "helpers/backend_helper";

const MetadataUploadModal = (props) => {
  const { isOpen, toggle } = props;
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    file: null,
    type: "og_mint",
  });

  const dispatch = useDispatch();
  const { uploadMetadataLoading } = useSelector(({ items }) => items);
  const nftTypeOptions = [
    {
      label: "Syndicate",
      value: "og_mint",
    },
    {
      label: "special weapons",
      value: "claim_weapon",
    },
    {
      label: "Legendary Villains",
      value: "claim_villain",
    },
    {
      label: "Origins",
      value: "claim_origin",
    },
  ];

  const hanldeChange = (e) => {
    const { name, value, files } = e.target;
    console.log("name", name);
    if (name === "type") {
      setFields((prev) => ({ ...prev, [name]: value }));
    } else {
      console.log("file.sizefile.size", files[0].size);
      const file = files[0];
      if (file) {
        const fileSizeInBytes = file.size;
        const fileSizeInMb = fileSizeInBytes / (1024 * 1024);

        console.log(`File size: ${fileSizeInBytes} bytes`);
        console.log(`File size: ${fileSizeInMb.toFixed(2)} MB`);
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop();
        console.log("extension", fileExtension);
        // Check if the file size is within the limit (5 MB in this case)
        if (fileSizeInMb > 5) {
          return toastr.error("Please make sure file size will be 5mb");
        }
        if (fileExtension.toLowerCase() !== "txt") {
          return toastr.error("Allowed txt file only!");
        }
        setFields((prev) => ({ ...prev, [name]: file }));
      }
    }
  };
  console.log("fields", fields);
  const onUpload = () => {
    console.log("fields", fields);
    if (!fields?.file) {
      return toastr.error("File is required!");
    }
    dispatch(
      uploadMetadataUpdateRequest(fields, () => {
        toastr.info(
          "Please wait for few minutes and reload page after few minutes.It will take some time.",
          { timeOut: 10000 }
        );
        toggle();
      })
    );
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-content remove-confirm spinner-content">
        {uploadMetadataLoading ? (
          <div className="spinner">
            <Spinner color="primary" />
          </div>
        ) : null}

        <ModalBody>
          <h4>{props.t("Update Metadata")}</h4>
          <Form>
            <Row>
              <Col lg={6} md={12}>
                <FormGroup>
                  <Input
                    type="select"
                    onChange={(e) =>
                      hanldeChange({
                        target: { name: "type", value: e.target.value },
                      })
                    }
                  >
                    {nftTypeOptions.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg={6} md={12}>
                <FormGroup>
                  <Input type="file" name="file" onChange={hanldeChange}>
                    Upload File
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>

          <div className="delete-btns">
            <Button type="button" outline color="secondary" onClick={toggle}>
              {props.t("Close")}
            </Button>

            <Button
              disabled={uploadMetadataLoading}
              type="button"
              color="primary"
              onClick={() => onUpload()}
            >
              {props.t("Upload")}
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default withTranslation()(MetadataUploadModal);
