import * as TYPES from "./actionType";

//  Add Teams Action
export const postTeam = (data, callback) => ({
  type: TYPES.POST_TEAM,
  payload: { data, callback },
});

export const postTeamFail = (error) => ({
  type: TYPES.POST_TEAM_FAIL,
  payload: error,
});

export const postTeamSuccess = () => ({
  type: TYPES.POST_TEAM_SUCCESS,
});

// get Teams Actions
export const getTeams = (data) => ({
  type: TYPES.GET_TEAMS,
  payload: data,
});

export const getTeamsSuccess = (data) => ({
  type: TYPES.GET_TEAMS_SUCCESS,
  payload: data,
});

export const getTeamsFail = (error) => ({
  type: TYPES.GET_TEAMS_FAIL,
  payload: error,
});

// get Team view
export const getTeam = (id) => ({
  type: TYPES.GET_TEAM,
  payload: id,
});

export const getTeamFail = (error) => ({
  type: TYPES.GET_TEAM_FAIL,
  payload: error,
});

export const getTeamSuccess = (data) => ({
  type: TYPES.GET_TEAM_SUCCESS,
  payload: data,
});

// update Team
export const putTeam = (data, callback) => ({
  type: TYPES.PUT_TEAM,
  payload: { data, callback },
});

export const putTeamFail = (error) => ({
  type: TYPES.PUT_TEAM_FAIL,
  payload: error,
});

export const putTeamSuccess = () => ({
  type: TYPES.PUT_TEAM_SUCCESS,
});

// delete Team
export const deleteTeam = (data, callback) => ({
  type: TYPES.DELETE_TEAM,
  payload: { data, callback },
});

export const deleteTeamFail = (error) => ({
  type: TYPES.DELETE_TEAM_FAIL,
  payload: error,
});

export const deleteTeamSuccess = () => ({
  type: TYPES.DELETE_TEAM_SUCCESS,
});
