import {
  ACTIVITY_API_FAIL,
  GET_ACTIVITIES,
  GET_ACTIVITIES_FAIL,
  GET_ACTIVITIES_SUCCESS,
  ADD_ACTIVITY,
  ADD_ACTIVITY_FAIL,
  ADD_ACTIVITY_SUCCESS,
  GET_ACTIVITY,
  GET_ACTIVITY_FAIL,
  GET_ACTIVITY_SUCCESS,
  PUT_ACTIVITY,
  PUT_ACTIVITY_FAIL,
  PUT_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_SUCCESS,
  PUT_ACTIVITIES_STATUS,
  PUT_ACTIVITIES_STATUS_FAIL,
  PUT_ACTIVITIES_STATUS_SUCCESS,
  GET_EQUIP_ACTIVITIES_REQUEST,
  GET_EQUIP_ACTIVITIES_SUCCESS,
  GET_EQUIP_ACTIVITIES_FAIL,
  ADD_EQUIP_ACTIVITY_REQUEST,
  ADD_EQUIP_ACTIVITY_SUCCESS,
  ADD_EQUIP_ACTIVITY_FAIL,
  REMOVE_EQUIP_ACTIVITY_REQUEST,
  REMOVE_EQUIP_ACTIVITY_SUCCESS,
  REMOVE_EQUIP_ACTIVITY_FAIL,
} from "./actionTypes";

export const apiFail = (error) => ({
  type: ACTIVITY_API_FAIL,
  payload: error,
});

/* Get items */
export const getActivities = (data) => ({
  type: GET_ACTIVITIES,
  payload: data,
});

export const getActivitiesSuccess = (items) => ({
  type: GET_ACTIVITIES_SUCCESS,
  payload: items,
});

export const getActivitiesFail = (error) => ({
  type: GET_ACTIVITIES_FAIL,
  payload: error,
});
/* Get Activities END */

/* Add Activity */
export const addActivity = (item, history) => ({
  type: ADD_ACTIVITY,
  payload: { item, history },
});

export const addActivityFail = (error) => ({
  type: ADD_ACTIVITY_FAIL,
  payload: error,
});

export const addActivitySuccess = () => ({
  type: ADD_ACTIVITY_SUCCESS,
});
/* Add Activity END */

/* Get Activity */
export const getActivity = (id) => ({
  type: GET_ACTIVITY,
  payload: id,
});

export const getActivityFail = (error) => ({
  type: GET_ACTIVITY_FAIL,
  payload: error,
});

export const getActivitySuccess = (item) => ({
  type: GET_ACTIVITY_SUCCESS,
  payload: item,
});
/* Get Activity END */

/* Update Activity */
export const putActivity = (data, history) => ({
  type: PUT_ACTIVITY,
  payload: { data, history },
});

export const putActivityFail = (error) => ({
  type: PUT_ACTIVITY_FAIL,
  payload: error,
});

export const putActivitySuccess = () => ({
  type: PUT_ACTIVITY_SUCCESS,
});
/* Update Activity END */

/* Delete Activity */
export const deleteActivity = (data, callback) => ({
  type: DELETE_ACTIVITY,
  payload: { data, callback },
});

export const deleteActivityFail = (error) => ({
  type: DELETE_ACTIVITY_FAIL,
  payload: error,
});

export const deleteActivitySuccess = () => ({
  type: DELETE_ACTIVITY_SUCCESS,
});
/* Delete Faz END */

/* Update Multi Activities Status */
export const putActivitiesStatus = (data, callback) => ({
  type: PUT_ACTIVITIES_STATUS,
  payload: { data, callback },
});

export const putActivitiesStatusFail = (error) => ({
  type: PUT_ACTIVITIES_STATUS_FAIL,
  payload: error,
});

export const putActivitiesStatusSuccess = () => ({
  type: PUT_ACTIVITIES_STATUS_SUCCESS,
});
/* Update Multi Activities Status END */



/* Get equipment activities  */
export const getEquipmentActivitiesRequest = (data) => ({
  type: GET_EQUIP_ACTIVITIES_REQUEST,
  payload: data,
});

export const getEquipmentActivitiesSuccess = (items) => ({
  type: GET_EQUIP_ACTIVITIES_SUCCESS,
  payload: items,
});

export const getEquipmentActivitiesFail = (error) => ({
  type: GET_EQUIP_ACTIVITIES_FAIL,
  payload: error,
});
/* Get equipment END */

/* Add equipment Activity */
export const addEquipActivityRequest = (item, callback) => ({
  type: ADD_EQUIP_ACTIVITY_REQUEST,
  payload: { item, callback },
});

export const addEquipActivitySuccess = (data) => ({
  type: ADD_EQUIP_ACTIVITY_SUCCESS,
  payload : data
});

export const addEquipActivityFail = (error) => ({
  type: ADD_EQUIP_ACTIVITY_FAIL,
  payload: error,
});
/* Add equipment Activity END */


/* Remove equipment Activity */
export const removeEquipActivityRequest = (item, callback) => ({
  type: REMOVE_EQUIP_ACTIVITY_REQUEST,
  payload: { item, callback },
});

export const removeEquipActivitySuccess = (data) => ({
  type: REMOVE_EQUIP_ACTIVITY_SUCCESS,
  payload : data
});

export const removeEquipActivityFail = (error) => ({
  type: REMOVE_EQUIP_ACTIVITY_FAIL,
  payload: error,
});
/* Remove equipment Activity END */