import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import { Card, CardBody, Col, Container, Spinner } from "reactstrap";
import { Row, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import toastr from "toastr";
// Images
import avatar4 from "assets/images/logo-placeholder.png";

// i18n  // Components
import { withTranslation } from "react-i18next";
import FormButton from "components/Common/FormButtons";
import Breadcrumbs from "components/Common/Breadcrumb2";
import CKEditor from "react-ckeditor-component";

// Store
import { getTeam, postTeam, putTeam,uploadFile } from "store/actions";

const AddCustomer = ({
  accessLevel,
  history,
  error,
  onUploadFile,
  onPostTeam,
  onGetTeam,
  onPutTeam,
  team,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);

  const [selectedFiles, setselectedFiles] = useState({});
  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    name: "",
    title: "",
    description: "",
    image: "",
    status: "active",
  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetTeam(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && team) {
      setFields((prevState) => ({
        ...prevState,
        ...team,
      }));
    }
  }, [team]);

  function handleAcceptedFiles(name, _files) {

    const files = _files?.filter((file) => file.size < 5e6);

    if (files.length < _files.length) {
      return toastr.error(props.t("Max. upload file size: 5MB"));
    }


    const uploadFileSuccess   = (name) =>{

    }
    onUploadFile({ image: files[0] }, uploadFileSuccess(name));

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setselectedFiles((prevState) => ({ ...prevState, [name]: files[0] }));
  }

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (id) {
      if (!accesses.canEdit) return;
      onPutTeam({ _id: team._id, ...fields }, ()=>{
          history.push("/team")
      });
    } else {
      if (!accesses.canAdd) return;
      onPostTeam({ ...fields }, ()=>{
        history.push("/team")
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="team"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) + " " + props.t("Team")
            }
            breadcrumbItems={[
              { title: props.t("Team"), link: "/team" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("name")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.name}
                            onChange={handleChange("name")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Title")}
                          </Label>
                          <Input
                            type="text"
                            value={fields.title}
                            onChange={handleChange("title")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <Label>{props.t("description")}</Label>
                          <CKEditor
                            content={fields.description}
                            events={{
                              change: (evt) => {
                                const data = evt?.editor?.getData();

                                handleChange("description")({
                                  target: { value: data },
                                });
                              },
                            }}
                            config={{
                              height: 250,
                              toolbar: [
                                [
                                  "Undo",
                                  "Redo",
                                  "Font",
                                  "FontSize",
                                  "Styles",
                                  "Format",
                                  "-",
                                  "Maximize",
                                  "-",
                                  "Source",
                                ],
                                [
                                  "Bold",
                                  "Italic",
                                  "Underline",
                                  "Strike",
                                  "-",
                                  "RemoveFormat",
                                  "-",
                                  "NumberedList",
                                  "BulletedList",
                                ],
                                [
                                  "Link",
                                  "Unlink",
                                  "-",
                                  "JustifyLeft",
                                  "JustifyCenter",
                                  "JustifyRight",
                                  "JustifyBlock",
                                  "-",
                                  "Outdent",
                                  "Indent",
                                  "-",
                                  "TextColor",
                                  "BGColor",
                                ],
                                [
                                  "Image",
                                  "Table",
                                  "HorizontalRule",
                                  "SpecialChar",
                                  "-",
                                  "Blockquote",
                                ],
                              ],
                            }}
                            scriptUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
                          />
                        </FormGroup>
                      </Col>

                      {/* <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Profile Image ")}
                          </Label>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles("image", acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles.image;

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded avatar-xl"
                                    alt={
                                      !!imageFile && imageFile.name
                                        ? imageFile.name
                                        : "hlc"
                                    }
                                    src={
                                      !!imageFile ? imageFile.preview : avatar4
                                    }
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </FormGroup>
                      </Col> */}

                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  team: PropTypes.object,
  onPostTeam: PropTypes.func,
  onGetTeam: PropTypes.func,
  onPutTeam: PropTypes.func,
};

const mapStateToProps = ({ Team }) => ({
  error: Team.error,
  loading: Team.loading,
  team: Team.team,
});

const mapDispatchToProps = (dispatch) => ({
  onPostTeam: (data, callback) => dispatch(postTeam(data, callback)),
  onGetTeam: (id) => dispatch(getTeam(id)),
  onPutTeam: (data, callback) => dispatch(putTeam(data, callback)),
  onUploadFile:(data,callback) => dispatch(uploadFile(data,callback))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);
