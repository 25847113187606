import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Select, { components } from "react-select";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { isObject } from "lodash";
import { getSettings, putSettings } from "store/actions"


import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Button,
  FormText,
  Label,
  Input,
  UncontrolledTooltip,
  Container,
  Card,
  CardBody,
  Spinner,
  Alert,
} from "reactstrap";
import Instructions from "./Instructions";

// Actions
import { uploadFile } from "store/actions";

// Images
import logo_placeholder from "assets/images/logo-placeholder.png";

import Breadcrumbs from "components/Common/Breadcrumb";

const WalletSettings = (props) => {


  const { onGetSettings, onPutSettings, loading, error } = props;

  const [fields, setFields] = useState({
    publicKey: "",
    privateKey: "",
  });

  useEffect(() => {
    onGetSettings()
  }, [])

  useEffect(() => {
    if (props.settings && props.settings.walletAddress) {
      setFields((prevState) => ({
        ...prevState,
        publicKey: props.settings.walletAddress.publicKey,
        privateKey: props.settings.walletAddress.privateKey,
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target

    setFields((prevFields) => ({ ...prevFields, [name]: value }))
  }

  function submit() {
    try{
      onPutSettings({walletAddress: {publicKey: fields.publicKey, privateKey: fields.privateKey}, _id: props.settings._id });
    }
    catch {
      throw new Error("Some Error Occurred!!");
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Wallet Settings" />
          <Row>
            <Card>
              <CardBody className="spinner-content">
                <Col md={12}>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Public Key</Label>

                          <Input
                            type="text"
                            value={fields.publicKey}
                            onChange={(e) => {
                              handleChange("publicKey")(e);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col md={12}>
                        <FormGroup>
                          <Label>Private Key</Label>

                          <Input
                            type="text"
                            value={fields.privateKey}
                            onChange={(e) => {
                              handleChange("privateKey")(e);
                            }}
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                  </Form>
                </Col>
                <hr className="my-3" />

                <Row>
                  <Col></Col>

                  <Col className="d-flex justify-content-end">
                    {loading && <Spinner color="primary" className="mr-2" />}
                    <Button color="primary" className="buttoncolor" disabled={loading} onClick={submit}>
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

WalletSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
})

const mapDispatchToProps = (dispatch) => ({
  onUploadFile: (data, callback) => dispatch(uploadFile(data, callback)),
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WalletSettings)
);
